import React, { useState } from 'react';
import { Card } from '@arterial/card';
import { Checkbox } from '@arterial/checkbox';
import Code from '../components/code';
import { Demo } from '../components/demo';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsTable, PropsHeadline } from '../components/props';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
} from '../components/variant';
import { props, types } from '../constants';

export default function Checkboxes() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Checkboxes</PageHeadline>
          <PageDescription>
            Checkboxes allow the user to select one or more items from a set.
            Checkboxes can be used to turn an option on or off.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/checkbox</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[
                    {
                      name: 'checkbox',
                      value: '@material/checkbox/index.scss',
                    },
                  ]}
                  mixins={[{ name: 'checkbox', values: ['core-styles'] }]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>import "@material/checkbox/dist/mdc.checkbox.css";</Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { Checkbox } from '@arterial/checkbox';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="checked-checkbox">
              <VariantHeader>
                <VariantHeadline>Checked Checkbox</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Checked />
                  </div>
                </Demo>
                <Code>
                  {`function Checked() {
  const [checked, setChecked] = useState(true);
  return (
    <Checkbox
      checked={checked}
      id="checkbox-checked"
      onChange={(e) => setChecked(e.target.checked)}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="unchecked-checkbox">
              <VariantHeader>
                <VariantHeadline>Unchecked Checkbox</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Unchecked />
                  </div>
                </Demo>
                <Code>
                  {`function Unchecked() {
  const [checked, setChecked] = useState(false);
  return (
    <Checkbox
      checked={checked}
      id="checkbox-unchecked"
      onChange={(e) => setChecked(e.target.checked)}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="indeterminate-checkbox">
              <VariantHeader>
                <VariantHeadline>Indeterminate Checkbox</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Indeterminate />
                  </div>
                </Demo>
                <Code>
                  {`function Indeterminate() {
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(true);
  return (
    <Checkbox
      checked={checked}
      id="checkbox-indeterminate"
      indeterminate={indeterminate}
      onChange={(e) => {
        setChecked(e.target.checked);
        setIndeterminate(e.target.indeterminate);
      }}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Other Variants</PageSubtitle>
            <Variant id="label">
              <VariantHeader>
                <VariantHeadline>Label</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Label />
                  </div>
                </Demo>
                <Code>
                  {`function Label() {
  const [checked, setChecked] = useState(true);
  return (
    <Checkbox
      checked={checked}
      id="checkbox-label"
      label="Checkbox"
      onChange={(e) => setChecked(e.target.checked)}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="align-end">
              <VariantHeader>
                <VariantHeadline>Align End</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <AlignEnd />
                  </div>
                </Demo>
                <Code>
                  {`function AlignEnd() {
  const [checked, setChecked] = useState(true);
  return (
    <Checkbox
      alignEnd
      checked={checked}
      id="checkbox-align-end"
      label="Checkbox"
      onChange={(e) => setChecked(e.target.checked)}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="disabled">
              <VariantHeader>
                <VariantHeadline>Disabled</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Checkbox
                      disabled
                      id="checkbox-disabled"
                      label="Checkbox"
                      onChange={() => {}}
                    />
                  </div>
                </Demo>
                <Code>
                  {`<Checkbox
  disabled
  id="checkbox-disabled"
  label="Checkbox"
  onChange={() => {}}
/>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>Checkbox</PropsHeadline>
              <PropsTable
                body={[
                  props.ALIGN_END,
                  props.CHECKED,
                  props.CLASS_NAME,
                  props.DISABLED,
                  props.ID,
                  [
                    'indeterminate',
                    types.BOOLE,
                    '	Indicates whether the checkbox is indeterminate.',
                  ],
                  props.LABEL_NEXT,
                  props.ON_CHANGE,
                  props.RIPPLE,
                  props.STYLE,
                  props.VALUE,
                ]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}

function Checked() {
  const [checked, setChecked] = useState(true);
  return (
    <Checkbox
      checked={checked}
      id="checkbox-checked"
      onChange={(e) => setChecked(e.target.checked)}
    />
  );
}

function Unchecked() {
  const [checked, setChecked] = useState(false);
  return (
    <Checkbox
      checked={checked}
      id="checkbox-unchecked"
      onChange={(e) => setChecked(e.target.checked)}
    />
  );
}

function Indeterminate() {
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(true);
  return (
    <Checkbox
      checked={checked}
      id="checkbox-indeterminate"
      indeterminate={indeterminate}
      onChange={(e) => {
        setChecked(e.target.checked);
        setIndeterminate(e.target.indeterminate);
      }}
    />
  );
}

function Label() {
  const [checked, setChecked] = useState(true);
  return (
    <Checkbox
      checked={checked}
      id="checkbox-label"
      label="Checkbox"
      onChange={(e) => setChecked(e.target.checked)}
    />
  );
}

function AlignEnd() {
  const [checked, setChecked] = useState(true);
  return (
    <Checkbox
      alignEnd
      checked={checked}
      id="checkbox-align-end"
      label="Checkbox"
      onChange={(e) => setChecked(e.target.checked)}
    />
  );
}
