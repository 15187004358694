import React from 'react';
import { Button } from '@arterial/button';
import {
  Card,
  CardPrimaryAction,
  CardMedia,
  CardMediaContent,
} from '@arterial/card';
import { Checkbox } from '@arterial/checkbox';
import { Chip } from '@arterial/chips';
import { CircularProgress } from '@arterial/circular-progress';
import {
  DataTable,
  DataTableHeader,
  DataTableRow,
  DataTableContent,
  DataTableCell,
  DataTableHeaderCell,
} from '@arterial/data-table';
import { Fab } from '@arterial/fab';
import Frame from 'react-frame-component';
import { Grid, GridRow, GridCell } from '@arterial/layout-grid';
import { Hero, HeroContent } from '../../components/hero';
import { IconButton } from '@arterial/icon-button';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@arterial/list';
import {
  Page,
  PageContent,
  PageSection,
  PageSubtitle,
} from '../../components/page';
import { Radio } from '@arterial/radio';
import { Switch } from '@arterial/switch';
import { Tab, TabBar } from '@arterial/tabs';
import classNames from 'classnames';
import './home.scss';

export default function Home({ desktop }) {
  return (
    <div className="home">
      <Page>
        <PageContent>
          <PageSection>
            <Hero>
              <HeroContent>
                <div className="logo"></div>
                <div className="mdc-typography--headline1">Arterial</div>
                <div className="mdc-typography--headline6">
                  <span className="hero__highlight">A</span>nother{' '}
                  <span className="hero__highlight">R</span>eact Ma
                  <span className="hero__highlight">terial</span> Components
                  Library
                </div>
                <div>
                  <Button
                    className="hero__button"
                    label="Get Started"
                    raised
                    tag={Link}
                    to="/buttons"
                  />
                </div>
              </HeroContent>
            </Hero>
          </PageSection>
          <PageSection>
            <PageSubtitle>Components</PageSubtitle>
            <Grid className="grid" fixedColumnWidth={desktop}>
              <GridRow>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/buttons">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <Button label="Button" unelevated />
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Buttons</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/cards">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <Card>
                            <div className="primary-title">
                              <div className="title">Card title</div>
                              <div className="subtitle">Secondary text</div>
                            </div>
                          </Card>
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Cards</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/checkboxes">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <Checkbox
                            checked
                            id="checkbox-checked"
                            onChange={() => {}}
                          />
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Checkboxes</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/chips">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <Chip id="chip" key="chip" text="Chip" />
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Chips</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/circular-progress">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <CircularProgress progress={0.75} />
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Circular Progress</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/data-tables">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <StaticDataTable />
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Data Tables</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/dialogs">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <Iframe>
                            <Alert />
                          </Iframe>
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Dialogs</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/fabs">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <Fab icon="favorite" />
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Fabs</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/icon-buttons">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <IconButton icon="favorite" />
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Icon Buttons</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/layout-grids">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <Grid className="grid" fixedColumnWidth>
                            <GridRow>
                              <GridCell
                                className="grid-cell"
                                span={6}
                              ></GridCell>
                              <GridCell
                                className="grid-cell"
                                span={6}
                              ></GridCell>
                            </GridRow>
                          </Grid>
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Layout Grids</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/lists">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <List>
                            <ListItem>
                              <ListItemText>Item 1</ListItemText>
                            </ListItem>
                            <ListItem>
                              <ListItemText>Item 2</ListItemText>
                            </ListItem>
                          </List>
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Lists</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/menus">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <StaticMenu />
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Menus</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/radios">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <Radio
                            checked
                            id="radio-yes"
                            name="radios"
                            value="yes"
                            onChange={() => {}}
                          />
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Radios</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/selects">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <StaticSelect />
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Selects</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/snackbars">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <Iframe>
                            <StaticSnackbar desktop={desktop} />
                          </Iframe>
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Snackbars</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/switches">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <Switch
                            checked
                            className="switch"
                            id="switch-checked"
                            onChange={() => {}}
                          />
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Switches</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/tabs">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <TabBar
                            activeIndex={0}
                            handleActiveIndexUpdate={() => {}}
                            style={{ width: 'initial' }}
                          >
                            <Tab id="home" label="Home" minWidth />
                            <Tab id="about-us" label="About Us" minWidth />
                          </TabBar>
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Tabs</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
                <GridCell span={4}>
                  <Card className="home__card" outlined>
                    <CardPrimaryAction tag={Link} to="/text-fields">
                      <CardMedia sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <StaticTextField />
                        </CardMediaContent>
                      </CardMedia>
                      <div className="primary-title">
                        <div className="title">Text Fields</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </GridCell>
              </GridRow>
            </Grid>
          </PageSection>
        </PageContent>
      </Page>
    </div>
  );
}

function Iframe({ children }) {
  return (
    <Frame
      initialContent={`<!DOCTYPE html><html><head>${document.head.innerHTML}</head><body><div></div></body></html>`}
      style={{
        border: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        {children}
      </div>
    </Frame>
  );
}
function Alert() {
  return (
    <div className="mdc-dialog mdc-dialog--open">
      <div className="mdc-dialog__container">
        <div
          className="mdc-dialog__surface"
          role="alertdialog"
          aria-modal="true"
          aria-labelledby="my-dialog-title"
          aria-describedby="my-dialog-content"
        >
          <div className="mdc-dialog__content" id="my-dialog-content">
            Discard draft?
          </div>
          <div className="mdc-dialog__actions">
            <button
              type="button"
              className="mdc-button mdc-dialog__button"
              data-mdc-dialog-action="cancel"
            >
              <div className="mdc-button__ripple"></div>
              <span className="mdc-button__label">Cancel</span>
            </button>
            <button
              type="button"
              className="mdc-button mdc-dialog__button"
              data-mdc-dialog-action="discard"
            >
              <div className="mdc-button__ripple"></div>
              <span className="mdc-button__label">Discard</span>
            </button>
          </div>
        </div>
      </div>
      <div className="mdc-dialog__scrim"></div>
    </div>
  );
}

const numeric = [false, true];
const header = ['Header 1', 'Header 2'];
const content = [['Data 1', '159']];
function StaticDataTable() {
  return (
    <DataTable style={{ width: '240px' }}>
      <DataTableHeader>
        <DataTableRow>
          {header.map((cellData, cellIndex) => (
            <DataTableHeaderCell
              numeric={numeric[cellIndex]}
              key={`baseline-header-row-${cellData}-cell`}
            >
              {cellData}
            </DataTableHeaderCell>
          ))}
        </DataTableRow>
      </DataTableHeader>
      <DataTableContent>
        {content.map((rowData, rowIndex) => (
          <DataTableRow key={`baseline-${rowIndex}-row`}>
            {rowData.map((cellData, cellIndex) => (
              <DataTableCell
                numeric={numeric[cellIndex]}
                key={`baseline-${rowIndex}-row-${header[cellIndex]}-cell-${cellData}`}
              >
                {cellData}
              </DataTableCell>
            ))}
          </DataTableRow>
        ))}
      </DataTableContent>
    </DataTable>
  );
}

function StaticMenu() {
  return (
    <div
      className="mdc-menu-surface menu mdc-menu-surface--open"
      style={{ pointerEvents: 'none', zIndex: 0 }}
    >
      <ul className="mdc-list">
        <li className="mdc-list-item">
          <span className="mdc-list-item__ripple"></span>
          <span className="mdc-list-item__text">Item 1</span>
        </li>
        <li className="mdc-list-item">
          <span className="mdc-list-item__ripple"></span>
          <span className="mdc-list-item__text">Item 2</span>
        </li>
      </ul>
    </div>
  );
}

function StaticSelect() {
  return (
    <div>
      <div className="mdc-select mdc-select--activated mdc-select--focused">
        <div
          role="button"
          aria-haspopup="listbox"
          aria-labelledby="filled-menu-width-label filled-menu-width"
          className="mdc-select__anchor"
          style={{ width: 'inherit' }}
          tabIndex="0"
          title=""
        >
          <span className="mdc-select__ripple"></span>
          <input
            className="mdc-select__selected-text"
            disabled=""
            id="static-select"
            readOnly=""
            value=""
            onChange={() => {}}
          />
          <i
            className="mdc-select__dropdown-icon"
            id="static-select-dropdown-icon"
          ></i>
          <span
            className="mdc-floating-label mdc-floating-label--float-above"
            id="static-select-label"
          >
            Filled
          </span>
          <span className="mdc-line-ripple mdc-line-ripple--active"></span>
        </div>
      </div>
      <div
        className="mdc-menu-surface menu mdc-menu-surface--open"
        style={{ minWidth: '200px', pointerEvents: 'none', zIndex: 0 }}
      >
        <ul className="mdc-list">
          <li className="mdc-list-item">
            <span className="mdc-list-item__ripple"></span>
            <span className="mdc-list-item__text">Item 1</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

function StaticSnackbar({ desktop }) {
  return (
    <div
      className={classNames('mdc-snackbar', 'mdc-snackbar--open', {
        'mdc-snackbar--stacked': !desktop,
      })}
      style={{ bottom: 0, top: 0 }}
    >
      <div className="mdc-snackbar__surface">
        <div className="mdc-snackbar__label" role="status" aria-live="polite">
          Information successfully saved.
        </div>
        <div className="mdc-snackbar__actions">
          <button type="button" className="mdc-button mdc-snackbar__action">
            <div className="mdc-button__ripple"></div>
            <span className="mdc-button__label">Dismiss</span>
          </button>
        </div>
      </div>
    </div>
  );
}

function StaticTextField() {
  return (
    <label className="mdc-text-field mdc-text-field--filled mdc-text-field--focused mdc-text-field--label-floating">
      <span className="mdc-text-field__ripple"></span>
      <input
        className="mdc-text-field__input"
        id="static-text-field"
        aria-labelledby="static-text-field-label"
        value="Example"
        onChange={() => {}}
      />
      <span
        className="mdc-floating-label mdc-floating-label--float-above"
        id="static-text-field-label"
      >
        Filled
      </span>
      <span className="mdc-line-ripple mdc-line-ripple--active"></span>
    </label>
  );
}
