import React from 'react';
import classNames from 'classnames';
import './variant.scss';

export function Variant({ children, className, ...otherProps }) {
  return (
    <div className={classNames('variant', className)} {...otherProps}>
      {children}
    </div>
  );
}

export function VariantHeader({ children, className, ...otherProps }) {
  return (
    <div className={classNames('variant__header', className)} {...otherProps}>
      {children}
    </div>
  );
}

export function VariantHeadline({ children, className, ...otherProps }) {
  return (
    <div className={classNames('variant__headline', className)} {...otherProps}>
      {children}
    </div>
  );
}

export function VariantDescription({ children, className, ...otherProps }) {
  return (
    <div
      className={classNames('variant__description', className)}
      {...otherProps}
    >
      {children}
    </div>
  );
}

export function VariantContent({ children, className, ...otherProps }) {
  return (
    <div className={classNames('variant__content', className)} {...otherProps}>
      {children}
    </div>
  );
}
