import React, { useState } from 'react';
import { Card } from '@arterial/card';
import Code from '../components/code';
import {
  DataTable,
  DataTableCell,
  DataTableContent,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow,
} from '@arterial/data-table';
import { Demo } from '../components/demo';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsTable, PropsHeadline } from '../components/props';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
} from '../components/variant';
import { props, types } from '../constants';

export default function DataTables() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Data Tables</PageHeadline>
          <PageDescription>
            Data tables display sets of data across rows and columns.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/data-table</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[
                    {
                      name: 'data-table',
                      value: '@material/data-table/index.scss',
                    },
                  ]}
                  mixins={[
                    {
                      name: 'data-table',
                      values: ['core-styles', 'theme-baseline'],
                    },
                  ]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>
                  import "@material/data-table/dist/mdc.data-table.css";
                </Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { DataTable, DataTableCell, DataTableContent, ... } from '@arterial/data-table';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="baseline-data-table">
              <VariantHeader>
                <VariantHeadline>Baseline Data Table</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Baseline />
                  </div>
                </Demo>
                <Code>
                  {`const numeric = [false, true, true, true, true];
const header = ['Dessert', 'Calories', 'Fat', 'Carbs', 'Protein (g)'];
const content = [
  ['Frozen yogurt', '159', '6', '24', '4'],
  ['Ice cream sandwich', '237', '9', '37', '4.3'],
  ['Eclair', '262', '16', '24', '6'],
];
function Baseline() {
  return (
    <DataTable>
      <DataTableHeader>
        <DataTableRow>
          {header.map((cellData, cellIndex) => (
            <DataTableHeaderCell
              numeric={numeric[cellIndex]}
              key={\`baseline-header-row-\${cellData}-cell\`}
            >
              {cellData}
            </DataTableHeaderCell>
          ))}
        </DataTableRow>
      </DataTableHeader>
      <DataTableContent>
        {content.map((rowData, rowIndex) => (
          <DataTableRow key={\`baseline-\${rowIndex}-row\`}>
            {rowData.map((cellData, cellIndex) => (
              <DataTableCell
                numeric={numeric[cellIndex]}
                key={\`baseline-\${rowIndex}-row-\${header[cellIndex]}-cell-\${cellData}\`}
              >
                {cellData}
              </DataTableCell>
            ))}
          </DataTableRow>
        ))}
      </DataTableContent>
    </DataTable>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="checkbox-data-table">
              <VariantHeader>
                <VariantHeadline>Checkbox Data Table</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <CheckboxTable />
                  </div>
                </Demo>
                <Code>
                  {`const numeric = [false, true, true, true, true];
const header = ['Dessert', 'Calories', 'Fat', 'Carbs', 'Protein (g)'];
const content = [
  ['Frozen yogurt', '159', '6', '24', '4'],
  ['Ice cream sandwich', '237', '9', '37', '4.3'],
  ['Eclair', '262', '16', '24', '6'],
];
function initChecked() {
  const checked = {};
  content.forEach((_rowData, rowIndex) => {
    checked[rowIndex] = false;
  });
  return checked;
}
function CheckboxTable() {
  const [headerChecked, setHeaderChecked] = useState('');
  const [bodyChecked, setBodyChecked] = useState(initChecked());
  return (
    <DataTable>
      <DataTableHeader>
        <DataTableRow>
          {header.map((cellData, cellIndex) => (
            <React.Fragment key={\`checkbox-header-row-\${cellData}-cell\`}>
              {cellIndex === 0 && (
                <DataTableHeaderCell
                  checkbox
                  checked={headerChecked === 'checked'}
                  id={\`\${cellData}-checkbox\`}
                  indeterminate={headerChecked === 'indeterminate'}
                  onChange={(data) => {
                    Object.keys(bodyChecked).forEach((key) => {
                      bodyChecked[key] = data.checked;
                    });
                    setHeaderChecked(data.checked ? 'checked' : '');
                    setBodyChecked({ ...bodyChecked });
                  }}
                />
              )}
              <DataTableHeaderCell numeric={numeric[cellIndex]}>
                {cellData}
              </DataTableHeaderCell>
            </React.Fragment>
          ))}
        </DataTableRow>
      </DataTableHeader>
      <DataTableContent>
        {content.map((rowData, rowIndex) => (
          <DataTableRow
            selected={bodyChecked[rowIndex]}
            key={\`checkbox-\${rowIndex}-row\`}
          >
            {rowData.map((cellData, cellIndex) => (
              <React.Fragment
                key={\`checkbox-\${rowIndex}-row-\${header[cellIndex]}-cell-\${cellData}\`}
              >
                {cellIndex === 0 && (
                  <DataTableCell
                    checkbox
                    checked={bodyChecked[rowIndex] === true}
                    onChange={(data) => {
                      bodyChecked[rowIndex] = data.checked;
                      const values = Object.values(bodyChecked);
                      const checked = values.find((checked) => checked);
                      const noneChecked = checked
                        ? false
                        : values.every((checked) => !checked);
                      const unchecked = values.find((checked) => !checked);
                      const allChecked = unchecked
                        ? false
                        : values.every((checked) => checked);
                      let header;
                      if (allChecked) {
                        header = 'checked';
                      } else if (noneChecked) {
                        header = '';
                      } else {
                        header = 'indeterminate';
                      }
                      setHeaderChecked(header);
                      setBodyChecked({ ...bodyChecked });
                    }}
                  />
                )}
                <DataTableCell numeric={numeric[cellIndex]}>
                  {cellData}
                </DataTableCell>
              </React.Fragment>
            ))}
          </DataTableRow>
        ))}
      </DataTableContent>
    </DataTable>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>DataTable</PropsHeadline>
              <PropsTable
                body={[props.CHILDREN, props.CLASS_NAME, props.LABEL]}
              />
            </Props>
            <Props>
              <PropsHeadline>DataTableHeader</PropsHeadline>
              <PropsTable body={[props.CHILDREN, props.CLASS_NAME]} />
            </Props>
            <Props>
              <PropsHeadline>DataTableContent</PropsHeadline>
              <PropsTable body={[props.CHILDREN, props.CLASS_NAME]} />
            </Props>
            <Props>
              <PropsHeadline>DataTableRow</PropsHeadline>
              <PropsTable
                body={[props.CHILDREN, props.CLASS_NAME, props.SELECTED]}
              />
            </Props>
            <Props>
              <PropsHeadline>DataTableHeaderCell</PropsHeadline>
              <PropsTable
                body={[
                  [
                    'checkbox',
                    types.BOOLEAN,
                    'Enables checkbox to be displayed within root element.',
                  ],
                  ['checkboxId', types.STRING, 'Id of the checkbox.'],
                  props.CHILDREN,
                  props.CLASS_NAME,
                  ['numeric', types.BOOLEAN, 'Enables a numeric variant.'],
                ]}
              />
            </Props>
            <Props>
              <PropsHeadline>DataTableCell</PropsHeadline>
              <PropsTable
                body={[
                  [
                    'checkbox',
                    types.BOOLEAN,
                    'Enables checkbox to be displayed within root element.',
                  ],
                  ['checkboxId', types.STRING, 'Id of the checkbox.'],
                  props.CHILDREN,
                  props.CLASS_NAME,
                  ['numeric', types.BOOLEAN, 'Enables a numeric variant.'],
                ]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}

const numeric = [false, true, true, true, true];
const header = ['Dessert', 'Calories', 'Fat', 'Carbs', 'Protein (g)'];
const content = [
  ['Frozen yogurt', '159', '6', '24', '4'],
  ['Ice cream sandwich', '237', '9', '37', '4.3'],
  ['Eclair', '262', '16', '24', '6'],
];
function Baseline() {
  return (
    <DataTable>
      <DataTableHeader>
        <DataTableRow>
          {header.map((cellData, cellIndex) => (
            <DataTableHeaderCell
              numeric={numeric[cellIndex]}
              key={`baseline-header-row-${cellData}-cell`}
            >
              {cellData}
            </DataTableHeaderCell>
          ))}
        </DataTableRow>
      </DataTableHeader>
      <DataTableContent>
        {content.map((rowData, rowIndex) => (
          <DataTableRow key={`baseline-${rowIndex}-row`}>
            {rowData.map((cellData, cellIndex) => (
              <DataTableCell
                numeric={numeric[cellIndex]}
                key={`baseline-${rowIndex}-row-${header[cellIndex]}-cell-${cellData}`}
              >
                {cellData}
              </DataTableCell>
            ))}
          </DataTableRow>
        ))}
      </DataTableContent>
    </DataTable>
  );
}

function initChecked() {
  const checked = {};
  content.forEach((_rowData, rowIndex) => {
    checked[rowIndex] = false;
  });
  return checked;
}

function CheckboxTable() {
  const [headerChecked, setHeaderChecked] = useState('');
  const [bodyChecked, setBodyChecked] = useState(initChecked());
  return (
    <DataTable>
      <DataTableHeader>
        <DataTableRow>
          {header.map((cellData, cellIndex) => (
            <React.Fragment key={`checkbox-header-row-${cellData}-cell`}>
              {cellIndex === 0 && (
                <DataTableHeaderCell
                  checkbox
                  checkboxId={`${cellData}-checkbox`}
                  checked={headerChecked === 'checked'}
                  indeterminate={headerChecked === 'indeterminate'}
                  onChange={(data) => {
                    Object.keys(bodyChecked).forEach((key) => {
                      bodyChecked[key] = data.checked;
                    });
                    setHeaderChecked(data.checked ? 'checked' : '');
                    setBodyChecked({ ...bodyChecked });
                  }}
                />
              )}
              <DataTableHeaderCell numeric={numeric[cellIndex]}>
                {cellData}
              </DataTableHeaderCell>
            </React.Fragment>
          ))}
        </DataTableRow>
      </DataTableHeader>
      <DataTableContent>
        {content.map((rowData, rowIndex) => (
          <DataTableRow
            selected={bodyChecked[rowIndex]}
            key={`checkbox-${rowIndex}-row`}
          >
            {rowData.map((cellData, cellIndex) => (
              <React.Fragment
                key={`checkbox-${rowIndex}-row-${header[cellIndex]}-cell-${cellData}`}
              >
                {cellIndex === 0 && (
                  <DataTableCell
                    checkbox
                    checked={bodyChecked[rowIndex] === true}
                    onChange={(data) => {
                      bodyChecked[rowIndex] = data.checked;
                      const values = Object.values(bodyChecked);
                      const checked = values.find((checked) => checked);
                      const noneChecked = checked
                        ? false
                        : values.every((checked) => !checked);
                      const unchecked = values.find((checked) => !checked);
                      const allChecked = unchecked
                        ? false
                        : values.every((checked) => checked);
                      let header;
                      if (allChecked) {
                        header = 'checked';
                      } else if (noneChecked) {
                        header = '';
                      } else {
                        header = 'indeterminate';
                      }
                      setHeaderChecked(header);
                      setBodyChecked({ ...bodyChecked });
                    }}
                  />
                )}
                <DataTableCell numeric={numeric[cellIndex]}>
                  {cellData}
                </DataTableCell>
              </React.Fragment>
            ))}
          </DataTableRow>
        ))}
      </DataTableContent>
    </DataTable>
  );
}
