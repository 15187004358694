import React, { useState } from 'react';
import { Card } from '@arterial/card';
import Code from '../components/code';
import { Demo } from '../components/demo';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsTable, PropsHeadline } from '../components/props';
import { Switch } from '@arterial/switch';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
} from '../components/variant';
import { props } from '../constants';

export default function Switches() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Switches</PageHeadline>
          <PageDescription>
            Switches toggle the state of a single item on or off. They are the
            preferred way to adjust settings on mobile.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/switch</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[
                    { name: 'switch', value: '@material/switch/index.scss' },
                  ]}
                  mixins={[{ name: 'switch', values: ['core-styles'] }]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>import "@material/switch/dist/mdc.switch.css";</Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { Switch } from '@arterial/switch';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="checked-switch">
              <VariantHeader>
                <VariantHeadline>Checked Switch</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--switch">
                    <Checked />
                  </div>
                </Demo>
                <Code>
                  {`function Checked() {
  const [checked, setChecked] = useState(true);
  return (
    <Switch
      checked={checked}
      id="switch-checked"
      onChange={() => setChecked(!checked)}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="unchecked-switch">
              <VariantHeader>
                <VariantHeadline>Unchecked Switch</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--switch">
                    <Unchecked />
                  </div>
                </Demo>
                <Code>
                  {`function Unchecked() {
  const [checked, setChecked] = useState(false);
  return (
    <Switch
      checked={checked}
      id="switch-unchecked"
      onChange={() => setChecked(!checked)}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Other Variants</PageSubtitle>
            <Variant id="label">
              <VariantHeader>
                <VariantHeadline>Label</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--switch">
                    <Label />
                  </div>
                </Demo>
                <Code>
                  {`function Label() {
  const [checked, setChecked] = useState(false);
  return (
    <Switch
      checked={checked}
      id="switch-label"
      label="Switch"
      onChange={() => setChecked(!checked)}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="align-end">
              <VariantHeader>
                <VariantHeadline>Align End</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--switch">
                    <AlignEnd />
                  </div>
                </Demo>
                <Code>
                  {`function AlignEnd() {
  const [checked, setChecked] = useState(true);
  return (
    <Switch
      alignEnd
      checked={checked}
      id="switch-align-end"
      label="Switch"
      onChange={() => setChecked(!checked)}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="disabled">
              <VariantHeader>
                <VariantHeadline>Disabled</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--switch">
                    <Disabled />
                  </div>
                </Demo>
                <Code>
                  {`function Disabled() {
  const [checked, setChecked] = useState(true);
  return (
    <Switch
      checked={checked}
      disabled
      id="switch-disabled"
      label="Switch"
      onChange={() => setChecked(!checked)}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>Switch</PropsHeadline>
              <PropsTable
                body={[
                  props.ALIGN_END,
                  props.CHECKED,
                  props.CLASS_NAME,
                  props.DISABLED,
                  props.ID,
                  props.LABEL_NEXT,
                  props.ON_CHANGE,
                  props.STYLE,
                  props.VALUE,
                ]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}

function Checked() {
  const [checked, setChecked] = useState(true);
  return (
    <Switch
      checked={checked}
      id="switch-checked"
      onChange={() => setChecked(!checked)}
    />
  );
}

function Unchecked() {
  const [checked, setChecked] = useState(false);
  return (
    <Switch
      checked={checked}
      id="switch-unchecked"
      onChange={() => setChecked(!checked)}
    />
  );
}

function Label() {
  const [checked, setChecked] = useState(false);
  return (
    <Switch
      checked={checked}
      id="switch-label"
      label="Switch"
      onChange={() => setChecked(!checked)}
    />
  );
}

function AlignEnd() {
  const [checked, setChecked] = useState(true);
  return (
    <Switch
      alignEnd
      checked={checked}
      id="switch-align-end"
      label="Switch"
      onChange={() => setChecked(!checked)}
    />
  );
}

function Disabled() {
  const [checked, setChecked] = useState(true);
  return (
    <Switch
      checked={checked}
      disabled
      id="switch-disabled"
      label="Switch"
      onChange={() => setChecked(!checked)}
    />
  );
}
