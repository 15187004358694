import React from 'react';
import { Button } from '@arterial/button';
import { Card } from '@arterial/card';
import { Chip, ChipSet } from '@arterial/chips';
import { CircularProgress } from '@arterial/circular-progress';
import Code from '../components/code';
import { Demo } from '../components/demo';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsTable, PropsHeadline } from '../components/props';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
  VariantDescription,
} from '../components/variant';
import { props, types } from '../constants';

export default function CircularProgressIndicators() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Circular Progress</PageHeadline>
          <PageDescription>
            Circular progress indicators express an unspecified wait time or
            display the length of a process.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/circular-progress</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[
                    {
                      name: 'circular-progress',
                      value: '@material/circular-progress/index.scss',
                    },
                  ]}
                  mixins={[
                    { name: 'circular-progress', values: ['core-styles'] },
                  ]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>
                  import
                  "@material/circular-progress/dist/mdc.circular-progress.css";
                </Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { CircularProgress } from '@arterial/circular-progress';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="determinate-progress">
              <VariantHeader>
                <VariantHeadline>Determinate Progress</VariantHeadline>
                <VariantDescription>
                  Determinate circular indicators fill the invisible, circular
                  track with color, as the indicator moves from 0 to 360
                  degrees.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <CircularProgress progress={0.25} />
                    <CircularProgress progress={0.5} />
                    <CircularProgress progress={0.75} />
                    <CircularProgress progress={1} />
                  </div>
                </Demo>
                <Code>
                  {`<CircularProgress progress={0.25} />
<CircularProgress progress={0.5} />
<CircularProgress progress={0.75} />
<CircularProgress progress={1} />`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="indeterminate-progress">
              <VariantHeader>
                <VariantHeadline>Indeterminate Progress</VariantHeadline>
                <VariantDescription>
                  Indeterminate circular indicators grow and shrink in size
                  while moving along the invisible track.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <CircularProgress />
                    <CircularProgress medium />
                    <CircularProgress small />
                  </div>
                </Demo>
                <Code>
                  {`<CircularProgress />
<CircularProgress medium />
<CircularProgress small />`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Other Variants</PageSubtitle>
            <Variant id="four-colors">
              <VariantHeader>
                <VariantHeadline>Four Colors</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <CircularProgress fourColors />
                    <CircularProgress fourColors medium />
                    <CircularProgress fourColors small />
                  </div>
                </Demo>
                <Code>
                  {`<CircularProgress fourColors />
<CircularProgress fourColors medium />
<CircularProgress fourColors small />`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="custom-four-colors">
              <VariantHeader>
                <VariantHeadline>Custom Four Colors</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <CircularProgress fourColors />
                    <CircularProgress fourColors medium />
                    <CircularProgress fourColors small />
                  </div>
                </Demo>
                <Code>
                  {`// scss 
// @use '@material/circular-progress/mixins' as circular-progress-mixins;
// $colors: (purple, pink, teal, gray);
// .mdc-circular-progress {
//   @include circular-progress-mixins.indeterminate-colors($colors);
// }

// jsx
<CircularProgress fourColors />
<CircularProgress fourColors medium />
<CircularProgress fourColors small />`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="buttons">
              <VariantHeader>
                <VariantHeadline>Buttons</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div>
                    <Button
                      className="demo-item"
                      icon={<CircularProgress small />}
                      label="Text"
                    />
                    <Button
                      className="demo-item"
                      icon={<CircularProgress small />}
                      outlined
                      label="Outlined"
                    />
                    <Button
                      className="demo-item"
                      icon={<CircularProgress small />}
                      unelevated
                      label="Unelevated"
                    />
                    <Button
                      className="demo-item"
                      icon={<CircularProgress small />}
                      raised
                      label="Raised"
                    />
                  </div>
                </Demo>
                <Code>
                  {`import { Button } from '@arterial/button'

<Button
  icon={<CircularProgress small />}
  label="Text"
/>
<Button
  icon={<CircularProgress small />}
  outlined
  label="Outlined"
/>
<Button
  icon={<CircularProgress small />}
  unelevated
  label="Unelevated"
/>
<Button
  icon={<CircularProgress small />}
  raised
  label="Raised"
/>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="chips">
              <VariantHeader>
                <VariantHeadline>Chips</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <ChipSet choice>
                      <Chip icon={<CircularProgress small />} text="Alfa" />
                      <Chip
                        icon={<CircularProgress small />}
                        selected
                        text="Bravo"
                      />
                    </ChipSet>
                  </div>
                </Demo>
                <Code>
                  {`import { Chip, ChipSet } from '@arterial/chip';

<ChipSet choice>
  <Chip icon={<CircularProgress small />} text="Alfa" />
  <Chip icon={<CircularProgress small />} selected text="Bravo" />
</ChipSet>;`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>CircularProgress</PropsHeadline>
              <PropsTable
                body={[
                  props.CHILDREN,
                  props.CLASS_NAME,
                  [
                    'closed',
                    types.BOOLEAN,
                    'Hides the circular progress indicator.',
                  ],
                  [
                    'fourColors',
                    types.BOOLEAN,
                    'Enables a four colors variant.',
                  ],
                  [
                    'label',
                    types.BOOLEAN,
                    'Sets the aria label of the element. Defaults to Progress Bar.',
                  ],
                  [
                    'medium',
                    types.BOOLEAN,
                    'Sets the stroke and container sizes for the medium-sized variant.',
                  ],
                  ['progress', types.NUMBER, 'Sets the progress bar length.'],
                  [
                    'small',
                    types.BOOLEAN,
                    'Sets the stroke and container sizes for the small variant.',
                  ],
                ]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}
