import React, { useState } from 'react';
import { Card } from '@arterial/card';
import Code from '../components/code';
import { Demo } from '../components/demo';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsTable, PropsHeadline } from '../components/props';
import { Tab, TabBar } from '@arterial/tabs';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
} from '../components/variant';
import { props, types } from '../constants';

export default function Tabs() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Tabs</PageHeadline>
          <PageDescription>
            Tabs organize content across different screens, data sets, and other
            interactions.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/tabs</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[
                    { name: 'tab-bar', value: '@material/tab-bar/index.scss' },
                    {
                      name: 'tab-indicator',
                      value: '@material/tab-indicator/index.scss',
                    },
                    {
                      name: 'tab-scroller',
                      value: '@material/tab-scroller/index.scss',
                    },
                    { name: 'tab', value: '@material/tab/index.scss' },
                  ]}
                  mixins={[
                    { name: 'tab-bar', values: ['core-styles'] },
                    { name: 'tab-indicator', values: ['core-styles'] },
                    { name: 'tab-scroller', values: ['core-styles'] },
                    { name: 'tab', values: ['core-styles'] },
                  ]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>{`import "@material/tab-bar/dist/mdc.tab-bar.css";
import "@material/tab-indicator/dist/mdc.tab-indicator.css";
import "@material/tab-scroller/dist/mdc.tab-scroller.css";
import "@material/tab/dist/mdc.tab.css";`}</Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { Tab, TabBar } from '@arterial/tabs';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="regular-tabs">
              <VariantHeader>
                <VariantHeadline>Regular Tabs</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--tabs demo-item--fullwidth">
                    <Regular />
                  </div>
                </Demo>
                <Code>
                  {`function Regular() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabBar
      activeIndex={activeIndex}
      handleActiveIndexUpdate={(index) => setActiveIndex(index)}
    >
      <Tab id="home" label="Home" />
      <Tab id="merchandise" label="Merchandise" />
      <Tab id="about-us" label="About Us" />
    </TabBar>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle id="other-variants">Other Variants</PageSubtitle>
            <Variant id="min-width">
              <VariantHeader>
                <VariantHeadline>Min Width</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--tabs demo-item--fullwidth">
                    <MinWidth />
                  </div>
                </Demo>
                <Code>
                  {`function MinWidth() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabBar
      activeIndex={activeIndex}
      handleActiveIndexUpdate={(index) => setActiveIndex(index)}
    >
      <Tab id="home" label="Home" minWidth />
      <Tab id="merchandise" label="Merchandise" minWidth />
      <Tab id="about-us" label="About Us" minWidth />
    </TabBar>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="icon">
              <VariantHeader>
                <VariantHeadline>Icon</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--tabs demo-item--fullwidth">
                    <Icon />
                  </div>
                </Demo>
                <Code>
                  {`function Icon() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabBar
      activeIndex={activeIndex}
      handleActiveIndexUpdate={(index) => setActiveIndex(index)}
    >
      <Tab id="recents" label="Recents" icon="watch_later" />
      <Tab id="nearby" label="Nearby" icon="near_me" />
      <Tab id="favorites" label="Favorites" icon="favorite" />
    </TabBar>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="stacked">
              <VariantHeader>
                <VariantHeadline>Stacked</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--tabs demo-item--fullwidth">
                    <Stacked />
                  </div>
                </Demo>
                <Code>
                  {`function Stacked() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabBar
      activeIndex={activeIndex}
      handleActiveIndexUpdate={(index) => setActiveIndex(index)}
    >
      <Tab id="recents" label="Recents" icon="watch_later" stacked />
      <Tab id="nearby" label="Nearby" icon="near_me" stacked />
      <Tab id="favorites" label="Favorites" icon="favorite" stacked />
    </TabBar>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="stacked-restricted">
              <VariantHeader>
                <VariantHeadline>Stacked and Restricted</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--tabs demo-item--fullwidth">
                    <StackedRestricted />
                  </div>
                </Demo>
                <Code>
                  {`function StackedRestricted() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabBar
      activeIndex={activeIndex}
      handleActiveIndexUpdate={(index) => setActiveIndex(index)}
    >
      <Tab id="recents" label="Recents" icon="watch_later" restricted stacked />
      <Tab id="nearby" label="Nearby" icon="near_me" restricted stacked />
      <Tab
        id="favorites"
        label="Favorites"
        icon="favorite"
        restricted
        stacked
      />
    </TabBar>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="indicator-fade">
              <VariantHeader>
                <VariantHeadline>Indicator Fade</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--tabs demo-item--fullwidth">
                    <IndicatorFade />
                  </div>
                </Demo>
                <Code>
                  {`function IndicatorFade() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabBar
      activeIndex={activeIndex}
      handleActiveIndexUpdate={(index) => setActiveIndex(index)}
    >
      <Tab id="recents" label="Recents" indicatorFade />
      <Tab id="nearby" label="Nearby" indicatorFade />
      <Tab id="favorites" label="Favorites" indicatorFade />
    </TabBar>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="indicator-icon">
              <VariantHeader>
                <VariantHeadline>Indicator Icon</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--tabs demo-item--fullwidth">
                    <IndicatorIcon />
                  </div>
                </Demo>
                <Code>
                  {`function IndicatorIcon() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabBar
      activeIndex={activeIndex}
      handleActiveIndexUpdate={(index) => setActiveIndex(index)}
    >
      <Tab id="recents" label="Recents" indicatorIcon="watch_later" />
      <Tab id="nearby" label="Nearby" indicatorIcon="near_me" />
      <Tab id="favorites" label="Favorites" indicatorIcon="favorite" />
    </TabBar>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="scrolling">
              <VariantHeader>
                <VariantHeadline>Scrolling</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--tabs demo-item--fullwidth">
                    <Scrolling />
                  </div>
                </Demo>
                <Code>
                  {`function getTabs() {
  const tabs = [];
  for (let i = 0; i < 36; i++) {
    tabs.push(\`Tab \${i + 1}\`);
  }
  return tabs;
}
function Scrolling() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabBar
      activeIndex={activeIndex}
      handleActiveIndexUpdate={(index) => setActiveIndex(index)}
      scroll
    >
      {getTabs().map((label) => (
        <Tab id={label} key={label} label={label} />
      ))}
    </TabBar>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>Tab</PropsHeadline>
              <PropsTable
                body={[
                  [
                    'active',
                    types.BOOLEAN,
                    'Indicates whether the element is active.',
                  ],
                  props.CHILDREN,
                  props.CLASS_NAME,
                  [
                    'focused',
                    types.BOOLEAN,
                    'Indicates whether the element is focused.',
                  ],
                  props.ID,
                  [
                    'indicatorFade',
                    types.BOOLEAN,
                    'Enables indicator element fade animation.',
                  ],
                  [
                    'indicatorIcon',
                    types.BOOLEAN,
                    'Icon to render within as indicator element.',
                  ],
                  ['indicatorId', types.BOOLEAN, 'ID of indicator element.'],
                  props.LABEL,
                  ['minWidth', types.BOOLEAN, 'Enables a min width variant.'],
                  [
                    'previousIndicatorClientRect',
                    types.OBJECT,
                    "Conains previous indicator element's client rect.",
                  ],
                  [
                    'restricted',
                    types.BOOLEAN,
                    'Enables a restricted variant.',
                  ],
                  props.RIPPLE,
                  ['stacked', types.BOOLEAN, 'Enables a stacked variant.'],
                  props.TAG_BUTTON,
                ]}
              />
            </Props>
            <Props>
              <PropsHeadline>TabBar</PropsHeadline>
              <PropsTable
                body={[
                  [
                    'activeIndex',
                    types.BOOLEAN,
                    'Indicates the tab at that index is active.',
                  ],
                  props.CHILDREN,
                  props.CLASS_NAME,
                  [
                    'dir',
                    'ltr | rtl',
                    "Indicates the directionality of the element's text. Defaults to auto.",
                  ],
                  [
                    'handleActiveIndexUpdate',
                    types.FUNCTION,
                    'Active index update event handler.',
                  ],
                  ['onKeyDown', types.FUNCTION, 'Keydown event handler.'],
                  props.TAG_DIV,
                ]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}

function Regular() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabBar
      activeIndex={activeIndex}
      handleActiveIndexUpdate={(index) => setActiveIndex(index)}
    >
      <Tab id="home" label="Home" />
      <Tab id="merchandise" label="Merchandise" />
      <Tab id="about-us" label="About Us" />
    </TabBar>
  );
}

function MinWidth() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabBar
      activeIndex={activeIndex}
      handleActiveIndexUpdate={(index) => setActiveIndex(index)}
    >
      <Tab id="home" label="Home" minWidth />
      <Tab id="merchandise" label="Merchandise" minWidth />
      <Tab id="about-us" label="About Us" minWidth />
    </TabBar>
  );
}

function Icon() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabBar
      activeIndex={activeIndex}
      handleActiveIndexUpdate={(index) => setActiveIndex(index)}
    >
      <Tab id="recents" label="Recents" icon="watch_later" />
      <Tab id="nearby" label="Nearby" icon="near_me" />
      <Tab id="favorites" label="Favorites" icon="favorite" />
    </TabBar>
  );
}

function Stacked() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabBar
      activeIndex={activeIndex}
      handleActiveIndexUpdate={(index) => setActiveIndex(index)}
    >
      <Tab id="recents" label="Recents" icon="watch_later" stacked />
      <Tab id="nearby" label="Nearby" icon="near_me" stacked />
      <Tab id="favorites" label="Favorites" icon="favorite" stacked />
    </TabBar>
  );
}

function StackedRestricted() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabBar
      activeIndex={activeIndex}
      handleActiveIndexUpdate={(index) => setActiveIndex(index)}
    >
      <Tab id="recents" label="Recents" icon="watch_later" restricted stacked />
      <Tab id="nearby" label="Nearby" icon="near_me" restricted stacked />
      <Tab
        id="favorites"
        label="Favorites"
        icon="favorite"
        restricted
        stacked
      />
    </TabBar>
  );
}

function IndicatorFade() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabBar
      activeIndex={activeIndex}
      handleActiveIndexUpdate={(index) => setActiveIndex(index)}
    >
      <Tab id="recents" label="Recents" indicatorFade />
      <Tab id="nearby" label="Nearby" indicatorFade />
      <Tab id="favorites" label="Favorites" indicatorFade />
    </TabBar>
  );
}

function IndicatorIcon() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabBar
      activeIndex={activeIndex}
      handleActiveIndexUpdate={(index) => setActiveIndex(index)}
    >
      <Tab id="recents" label="Recents" indicatorIcon="watch_later" />
      <Tab id="nearby" label="Nearby" indicatorIcon="near_me" />
      <Tab id="favorites" label="Favorites" indicatorIcon="favorite" />
    </TabBar>
  );
}

function getTabs() {
  const tabs = [];
  for (let i = 0; i < 36; i++) {
    tabs.push(`Tab ${i + 1}`);
  }
  return tabs;
}
function Scrolling() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabBar
      activeIndex={activeIndex}
      handleActiveIndexUpdate={(index) => setActiveIndex(index)}
      scroll
    >
      {getTabs().map((label) => (
        <Tab id={label} key={label} label={label} />
      ))}
    </TabBar>
  );
}
