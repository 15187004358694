import React from 'react';
import { Card } from '@arterial/card';
import Code from '../components/code';
import { Demo } from '../components/demo';
import { Grid, GridCell, GridRow } from '@arterial/layout-grid';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsTable, PropsHeadline } from '../components/props';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
} from '../components/variant';
import { props, types } from '../constants';

export default function LayoutGrids() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Layout Grids</PageHeadline>
          <PageDescription>
            Column-variate grid layout. It has 12 columns on desktop, 8 columns
            on tablet and 4 columns on phone.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/layout-grid</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[
                    { value: '@material/layout-grid/mdc-layout-grid.scss' },
                  ]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>
                  import "@material/layout-grid/dist/mdc.layout-grid.css";
                </Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { Grid, GridRow, GridCell } from '@arterial/layout-grid';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="regular-layout-grid">
              <VariantHeader>
                <VariantHeadline>Regular Layout Grid</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--grid demo-item--fullwidth">
                    <Grid className="grid grid--regular">
                      <GridRow>
                        <GridCell className="grid-cell"></GridCell>
                        <GridCell className="grid-cell"></GridCell>
                        <GridCell className="grid-cell"></GridCell>
                      </GridRow>
                    </Grid>
                  </div>
                </Demo>
                <Code>{`<Grid className="grid grid--regular">
  <GridRow>
    <GridCell className="grid-cell"></GridCell>
    <GridCell className="grid-cell"></GridCell>
    <GridCell className="grid-cell"></GridCell>
  </GridRow>
</Grid>`}</Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle id="other-variants">Other Variants</PageSubtitle>
            <Variant id="left-aligned">
              <VariantHeader>
                <VariantHeadline>Left Aligned</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--grid demo-item--fullwidth">
                    <Grid className="grid grid--regular" align="left">
                      <GridRow>
                        <GridCell className="grid-cell"></GridCell>
                        <GridCell className="grid-cell"></GridCell>
                        <GridCell className="grid-cell"></GridCell>
                      </GridRow>
                    </Grid>
                  </div>
                </Demo>
                <Code>
                  {`<Grid className="grid grid--regular" align="left">
  <GridRow>
    <GridCell className="grid-cell"></GridCell>
    <GridCell className="grid-cell"></GridCell>
    <GridCell className="grid-cell"></GridCell>
  </GridRow>
</Grid>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="right-aligned">
              <VariantHeader>
                <VariantHeadline>Right Aligned</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--grid demo-item--fullwidth">
                    <Grid className="grid grid--regular" align="right">
                      <GridRow>
                        <GridCell className="grid-cell"></GridCell>
                        <GridCell className="grid-cell"></GridCell>
                        <GridCell className="grid-cell"></GridCell>
                      </GridRow>
                    </Grid>
                  </div>
                </Demo>
                <Code>
                  {`<Grid className="grid grid--regular" align="right">
  <GridRow>
    <GridCell className="grid-cell"></GridCell>
    <GridCell className="grid-cell"></GridCell>
    <GridCell className="grid-cell"></GridCell>
  </GridRow>
</Grid>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="columns">
              <VariantHeader>
                <VariantHeadline>Columns</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--grid demo-item--fullwidth">
                    <Grid className="grid">
                      <GridRow>
                        <GridCell className="grid-cell" span={6}></GridCell>
                        <GridCell className="grid-cell" span={3}></GridCell>
                        <GridCell className="grid-cell" span={2}></GridCell>
                        <GridCell className="grid-cell" span={1}></GridCell>
                        <GridCell className="grid-cell" span={3}></GridCell>
                        <GridCell className="grid-cell" span={1}></GridCell>
                        <GridCell className="grid-cell" span={8}></GridCell>
                      </GridRow>
                    </Grid>
                  </div>
                </Demo>
                <Code>
                  {`<Grid className="grid">
  <GridRow>
    <GridCell className="grid-cell" span={6}></GridCell>
    <GridCell className="grid-cell" span={3}></GridCell>
    <GridCell className="grid-cell" span={2}></GridCell>
    <GridCell className="grid-cell" span={1}></GridCell>
    <GridCell className="grid-cell" span={3}></GridCell>
    <GridCell className="grid-cell" span={1}></GridCell>
    <GridCell className="grid-cell" span={8}></GridCell>
  </GridRow>
</Grid>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="cell-alignment">
              <VariantHeader>
                <VariantHeadline>Cell Alignment</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--grid demo-item--fullwidth">
                    <Grid className="grid grid--cell-alignment">
                      <GridRow className="grid-inner">
                        <GridCell
                          className="grid-cell grid-cell--alignment"
                          align="top"
                        ></GridCell>
                        <GridCell
                          className="grid-cell grid-cell--alignment"
                          align="middle"
                        ></GridCell>
                        <GridCell
                          className="grid-cell grid-cell--alignment"
                          align="bottom"
                        ></GridCell>
                      </GridRow>
                    </Grid>
                  </div>
                </Demo>
                <Code>{`<Grid className="grid grid--cell-alignment">
  <GridRow className="grid-inner">
    <GridCell className="grid-cell grid-cell--alignment" align="top"></GridCell>
    <GridCell
      className="grid-cell grid-cell--alignment"
      align="middle"
    ></GridCell>
    <GridCell
      className="grid-cell grid-cell--alignment"
      align="bottom"
    ></GridCell>
  </GridRow>
</Grid>`}</Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>Grid</PropsHeadline>
              <PropsTable
                body={[
                  [
                    'align',
                    'left | right',
                    'Specifies alignment of the whole grid. Defaults to center.',
                  ],
                  props.CHILDREN,
                  props.CLASS_NAME,
                  [
                    'fixedColumnWidth',
                    types.BOOLEAN,
                    'Enables a fixed width column variant.',
                  ],
                  props.TAG_DIV,
                ]}
              />
            </Props>
            <Props>
              <PropsHeadline>GridRow</PropsHeadline>
              <PropsTable
                body={[props.CHILDREN, props.CLASS_NAME, props.TAG_DIV]}
              />
            </Props>
            <Props>
              <PropsHeadline>GridCell</PropsHeadline>
              <PropsTable
                body={[
                  [
                    'align',
                    'top | middle | bottom',
                    'Specifies alignment of the cell. Defaults to stretch.',
                  ],
                  props.CHILDREN,
                  props.CLASS_NAME,
                  ['order', types.NUMBER, 'Specifies order of the cell.'],
                  [
                    'span',
                    types.NUMBER,
                    'Specifies the number of columns the cell spans.',
                  ],
                  [
                    'spanDesktop',
                    types.NUMBER,
                    'Specifies the number of columns the cell spans on a desktop.',
                  ],
                  [
                    'spanPhone',
                    types.NUMBER,
                    'Specifies the number of columns the cell spans on a phone.',
                  ],
                  [
                    'spanTablet',
                    types.NUMBER,
                    'Specifies the number of columns the cell spans on a tablet.',
                  ],
                  props.TAG_DIV,
                ]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}
