import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@arterial/button';
import { Card } from '@arterial/card';
import Code from '../components/code';
import { Demo } from '../components/demo';
import { List, ListItem, ListItemText } from '@arterial/list';
import { MenuSurface, MenuSurfaceAnchor, Corner } from '@arterial/menu-surface';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsTable, PropsHeadline } from '../components/props';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
} from '../components/variant';
import { props, types } from '../constants';

export default function Menus() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Menus</PageHeadline>
          <PageDescription>
            Menus display a list of choices on temporary surfaces.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/menu-surface</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[
                    {
                      name: 'menu-surface',
                      value: '@material/menu-surface/index.scss',
                    },
                    { name: 'menu', value: '@material/menu/index.scss' },
                  ]}
                  mixins={[
                    { name: 'menu-surface', values: ['core-styles'] },
                    { name: 'menu', values: ['core-styles'] },
                  ]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>
                  {`import "@material/menu-surface/dist/mdc.menu-surface.css";
import "@material/menu/dist/mdc.menu.css";`}
                </Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { MenuSurface, MenuSurfaceAnchor, Corner } from '@arterial/menu-surface';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="regular-menu">
              <VariantHeader>
                <VariantHeadline>Regular Menu</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--menu">
                    <Regular />
                  </div>
                </Demo>
                <Code>
                  {`function Regular() {
  const anchorRef = useRef();
  const arterialRef = useRef();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    function handleBodyClick(e) {
      const { arterial } = e.target.dataset;
      if (!arterial) {
        setOpen(false);
      }
    }
    function handleWindowKeyDown(e) {
      const { arterial } = e.target.dataset;
      const isEscape = e.key === 'Escape' || e.keyCode === 27;
      const isTab = e.key === 'Tab' || e.keyCode === 9;
      if ((isEscape || isTab) && arterial === arterialRef.current) {
        setOpen(false);
      }
    }
    document.body.addEventListener('click', handleBodyClick);
    window.addEventListener('keydown', handleWindowKeyDown);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
      window.removeEventListener('keydown', handleWindowKeyDown);
    };
  }, []);
  return (
    <MenuSurfaceAnchor ref={anchorRef}>
      <Button label="Open Menu" onClick={() => setOpen(!open)} />
      <MenuSurface anchorRef={anchorRef} data-arterial="regular" open={open}>
        <List data-arterial="regular">
          <ListItem>
            <ListItemText>Menu Item 1</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Menu Item 2</ListItemText>
          </ListItem>
        </List>
      </MenuSurface>
    </MenuSurfaceAnchor>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle id="other-variants">Other Variants</PageSubtitle>
            <Variant id="fixed">
              <VariantHeader>
                <VariantHeadline>Fixed</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--menu">
                    <Fixed />
                  </div>
                </Demo>
                <Code>
                  {`function Fixed() {
  const anchorRef = useRef();
  const arterialRef = useRef();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    function handleBodyClick(e) {
      const { arterial } = e.target.dataset;
      if (!arterial) {
        setOpen(false);
      }
    }
    function handleWindowKeyDown(e) {
      const { arterial } = e.target.dataset;
      const isEscape = e.key === 'Escape' || e.keyCode === 27;
      const isTab = e.key === 'Tab' || e.keyCode === 9;
      if ((isEscape || isTab) && arterial === arterialRef.current) {
        setOpen(false);
      }
    }
    document.body.addEventListener('click', handleBodyClick);
    window.addEventListener('keydown', handleWindowKeyDown);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
      window.removeEventListener('keydown', handleWindowKeyDown);
    };
  }, []);
  return (
    <MenuSurfaceAnchor ref={anchorRef}>
      <Button label="Open Menu" onClick={() => setOpen(!open)} />
      <MenuSurface
        anchorRef={anchorRef}
        data-arterial="fixed"
        fixed
        open={open}
      >
        <List data-arterial="fixed">
          <ListItem>
            <ListItemText>Menu Item 1</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Menu Item 2</ListItemText>
          </ListItem>
        </List>
      </MenuSurface>
    </MenuSurfaceAnchor>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="quick-open">
              <VariantHeader>
                <VariantHeadline>Quick Open</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--menu">
                    <QuickOpen />
                  </div>
                </Demo>
                <Code>
                  {`function QuickOpen() {
  const anchorRef = useRef();
  const arterialRef = useRef('quickOpen');
  const [open, setOpen] = useState(false);
  useEffect(() => {
    function handleBodyClick(e) {
      const { arterial } = e.target.dataset;
      if (!arterial) {
        setOpen(false);
      }
    }
    function handleWindowKeyDown(e) {
      const { arterial } = e.target.dataset;
      const isEscape = e.key === 'Escape' || e.keyCode === 27;
      const isTab = e.key === 'Tab' || e.keyCode === 9;
      if ((isEscape || isTab) && arterial === arterialRef.current) {
        setOpen(false);
      }
    }
    document.body.addEventListener('click', handleBodyClick);
    window.addEventListener('keydown', handleWindowKeyDown);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
      window.removeEventListener('keydown', handleWindowKeyDown);
    };
  }, []);
  return (
    <MenuSurfaceAnchor ref={anchorRef}>
      <Button
        data-arterial="quickOpen"
        label="Open Menu"
        onClick={() => setOpen(!open)}
        unelevated
      />
      <MenuSurface
        anchorRef={anchorRef}
        data-arterial="quickOpen"
        open={open}
        quickOpen
      >
        <List data-arterial="quickOpen">
          <ListItem>
            <ListItemText>Menu Item 1</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Menu Item 2</ListItemText>
          </ListItem>
        </List>
      </MenuSurface>
    </MenuSurfaceAnchor>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="right-click">
              <VariantHeader>
                <VariantHeadline>Right Click</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div
                    id="right-click-demo"
                    className="demo-item demo-item--menu demo-item--right-click"
                  >
                    Right click within borders to open menu.
                    <RightClick />
                  </div>
                </Demo>
                <Code>
                  {`function RightClick() {
  const anchorRef = useRef();
  const arterialRef = useRef('rightClick');
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    function handleBodyClick(e) {
      const { arterial } = e.target.dataset;
      if (!arterial) {
        setOpen(false);
      }
    }
    function handleWindowKeyDown(e) {
      const { arterial } = e.target.dataset;
      const isEscape = e.key === 'Escape' || e.keyCode === 27;
      const isTab = e.key === 'Tab' || e.keyCode === 9;
      if ((isEscape || isTab) && arterial === arterialRef.current) {
        setOpen(false);
      }
    }
    function handleRightClick(e) {
      e.preventDefault();
      setPosition({ x: e.clientX, y: e.clientY });
      setOpen(true);
    }
    document.body.addEventListener('click', handleBodyClick);
    window.addEventListener('keydown', handleWindowKeyDown);
    document
      .getElementById('right-click-demo')
      .addEventListener('contextmenu', handleRightClick);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
      window.removeEventListener('keydown', handleWindowKeyDown);
      document
        .getElementById('right-click-demo')
        .removeEventListener('contextmenu', handleRightClick);
    };
  }, []);
  return (
    <MenuSurface
      anchorRef={anchorRef}
      data-arterial="rightClick"
      open={open}
      position={position}
    >
      <List data-arterial="rightClick">
        <ListItem>
          <ListItemText>Menu Item 1</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Menu Item 2</ListItemText>
        </ListItem>
      </List>
    </MenuSurface>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="bottom-left">
              <VariantHeader>
                <VariantHeadline>Bottom Left</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--menu demo-item--bottom">
                    <BottomLeft />
                  </div>
                </Demo>
                <Code>
                  {`function BottomLeft() {
  const anchorRef = useRef();
  const arterialRef = useRef('bottomLeft');
  const [open, setOpen] = useState(false);
  useEffect(() => {
    function handleBodyClick(e) {
      const { arterial } = e.target.dataset;
      if (!arterial) {
        setOpen(false);
      }
    }
    function handleWindowKeyDown(e) {
      const { arterial } = e.target.dataset;
      const isEscape = e.key === 'Escape' || e.keyCode === 27;
      const isTab = e.key === 'Tab' || e.keyCode === 9;
      if ((isEscape || isTab) && arterial === arterialRef.current) {
        setOpen(false);
      }
    }
    document.body.addEventListener('click', handleBodyClick);
    window.addEventListener('keydown', handleWindowKeyDown);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
      window.removeEventListener('keydown', handleWindowKeyDown);
    };
  }, []);
  return (
    <MenuSurfaceAnchor ref={anchorRef}>
      <Button
        data-arterial="bottomLeft"
        label="Open Menu"
        onClick={() => setOpen(!open)}
        unelevated
      />
      <MenuSurface
        anchorCorner={Corner.BOTTOM_LEFT}
        anchorRef={anchorRef}
        data-arterial="bottomLeft"
        open={open}
      >
        <List data-arterial="bottomLeft">
          <ListItem>
            <ListItemText>Menu Item 1</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Menu Item 2</ListItemText>
          </ListItem>
        </List>
      </MenuSurface>
    </MenuSurfaceAnchor>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="top-right">
              <VariantHeader>
                <VariantHeadline>Top Right</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--menu demo-item--right">
                    <TopRight />
                  </div>
                </Demo>
                <Code>
                  {`function TopRight() {
  const anchorRef = useRef();
  const arterialRef = useRef('topRight');
  const [open, setOpen] = useState(false);
  useEffect(() => {
    function handleBodyClick(e) {
      const { arterial } = e.target.dataset;
      if (!arterial) {
        setOpen(false);
      }
    }
    function handleWindowKeyDown(e) {
      const { arterial } = e.target.dataset;
      const isEscape = e.key === 'Escape' || e.keyCode === 27;
      const isTab = e.key === 'Tab' || e.keyCode === 9;
      if ((isEscape || isTab) && arterial === arterialRef.current) {
        setOpen(false);
      }
    }
    document.body.addEventListener('click', handleBodyClick);
    window.addEventListener('keydown', handleWindowKeyDown);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
      window.removeEventListener('keydown', handleWindowKeyDown);
    };
  }, []);
  return (
    <MenuSurfaceAnchor ref={anchorRef}>
      <Button
        data-arterial="topRight"
        label="Open Menu"
        onClick={() => setOpen(!open)}
        unelevated
      />
      <MenuSurface
        anchorCorner={Corner.TOP_RIGHT}
        anchorRef={anchorRef}
        data-arterial="topRight"
        open={open}
      >
        <List data-arterial="topRight">
          <ListItem>
            <ListItemText>Menu Item 1</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Menu Item 2</ListItemText>
          </ListItem>
        </List>
      </MenuSurface>
    </MenuSurfaceAnchor>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="bottom-right">
              <VariantHeader>
                <VariantHeadline>Bottom Right</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--menu demo-item--bottom">
                    <BottomRight />
                  </div>
                </Demo>
                <Code>
                  {`function BottomRight() {
  const anchorRef = useRef();
  const arterialRef = useRef('bottomRight');
  const [open, setOpen] = useState(false);
  useEffect(() => {
    function handleBodyClick(e) {
      const { arterial } = e.target.dataset;
      console.log(arterial);
      if (!arterial) {
        setOpen(false);
      }
    }
    function handleWindowKeyDown(e) {
      const { arterial } = e.target.dataset;
      const isEscape = e.key === 'Escape' || e.keyCode === 27;
      const isTab = e.key === 'Tab' || e.keyCode === 9;
      if ((isEscape || isTab) && arterial === arterialRef.current) {
        setOpen(false);
      }
    }
    document.body.addEventListener('click', handleBodyClick);
    window.addEventListener('keydown', handleWindowKeyDown);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
      window.removeEventListener('keydown', handleWindowKeyDown);
    };
  }, []);
  return (
    <MenuSurfaceAnchor ref={anchorRef} style={{ width: 'max-content' }}>
      <Button
        data-arterial="bottomRight"
        label="Open Menu"
        onClick={() => setOpen(!open)}
        unelevated
      />
      <MenuSurface
        anchorCorner={Corner.BOTTOM_RIGHT}
        anchorRef={anchorRef}
        data-arterial="bottomRight"
        open={open}
      >
        <List data-arterial="bottomRight">
          <ListItem>
            <ListItemText>Menu Item 1</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Menu Item 2</ListItemText>
          </ListItem>
        </List>
      </MenuSurface>
    </MenuSurfaceAnchor>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>MenuSurface</PropsHeadline>
              <PropsTable
                body={[
                  [
                    'anchorCorner',
                    'Corner',
                    'Corner of the menu surface to which menu surface is attached to anchor.',
                  ],
                  [
                    'anchorMargin',
                    '{ top, right, bottom, left }',
                    'Sets the margin between the menu surface and the anchor.',
                  ],
                  ['anchorRef', 'Ref', 'A reference to the anchor element.'],
                  props.CHILDREN,
                  props.CLASS_NAME,
                  [
                    'dir',
                    'ltr | rtl',
                    "Indicates the directionality of the element's text. Defaults to auto.",
                  ],
                  ['fixed', types.BOOLEAN, 'Enables the fixed variant.'],
                  props.OPEN,
                  [
                    'originCorner',
                    'Corner',
                    'Corner of the menu surface to attach to the anchor.',
                  ],
                  [
                    'position',
                    '{ x, y }',
                    'Sets the anchors absolute position.',
                  ],
                  [
                    'quickOpen',
                    types.BOOLEAN,
                    'Disables the open/close animation of the menu surface.',
                  ],
                  props.STYLE,
                  props.TAG_DIV,
                ]}
              />
            </Props>
            <Props>
              <PropsHeadline>MenuSurfaceAnchor</PropsHeadline>
              <PropsTable
                body={[props.CHILDREN, props.CLASS_NAME, props.TAG_DIV]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}

function Regular() {
  const anchorRef = useRef();
  const arterialRef = useRef('regular');
  const [open, setOpen] = useState(false);
  useEffect(() => {
    function handleBodyClick(e) {
      const { arterial } = e.target.dataset;
      if (!arterial) {
        setOpen(false);
      }
    }
    function handleWindowKeyDown(e) {
      const { arterial } = e.target.dataset;
      const isEscape = e.key === 'Escape' || e.keyCode === 27;
      const isTab = e.key === 'Tab' || e.keyCode === 9;
      if ((isEscape || isTab) && arterial === arterialRef.current) {
        setOpen(false);
      }
    }
    document.body.addEventListener('click', handleBodyClick);
    window.addEventListener('keydown', handleWindowKeyDown);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
      window.removeEventListener('keydown', handleWindowKeyDown);
    };
  }, []);
  return (
    <MenuSurfaceAnchor ref={anchorRef}>
      <Button
        data-arterial="regular"
        label="Open Menu"
        onClick={() => setOpen(!open)}
        unelevated
      />
      <MenuSurface anchorRef={anchorRef} data-arterial="regular" open={open}>
        <List data-arterial="regular">
          <ListItem>
            <ListItemText>Menu Item 1</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Menu Item 2</ListItemText>
          </ListItem>
        </List>
      </MenuSurface>
    </MenuSurfaceAnchor>
  );
}

function Fixed() {
  const anchorRef = useRef();
  const arterialRef = useRef('fixed');
  const [open, setOpen] = useState(false);
  useEffect(() => {
    function handleBodyClick(e) {
      const { arterial } = e.target.dataset;
      if (!arterial) {
        setOpen(false);
      }
    }
    function handleWindowKeyDown(e) {
      const { arterial } = e.target.dataset;
      const isEscape = e.key === 'Escape' || e.keyCode === 27;
      const isTab = e.key === 'Tab' || e.keyCode === 9;
      if ((isEscape || isTab) && arterial === arterialRef.current) {
        setOpen(false);
      }
    }
    document.body.addEventListener('click', handleBodyClick);
    window.addEventListener('keydown', handleWindowKeyDown);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
      window.removeEventListener('keydown', handleWindowKeyDown);
    };
  }, []);
  return (
    <MenuSurfaceAnchor ref={anchorRef}>
      <Button
        data-arterial="fixed"
        label="Open Menu"
        onClick={() => setOpen(!open)}
        unelevated
      />
      <MenuSurface
        anchorRef={anchorRef}
        data-arterial="fixed"
        fixed
        open={open}
      >
        <List data-arterial="fixed">
          <ListItem>
            <ListItemText>Menu Item 1</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Menu Item 2</ListItemText>
          </ListItem>
        </List>
      </MenuSurface>
    </MenuSurfaceAnchor>
  );
}

function QuickOpen() {
  const anchorRef = useRef();
  const arterialRef = useRef('quickOpen');
  const [open, setOpen] = useState(false);
  useEffect(() => {
    function handleBodyClick(e) {
      const { arterial } = e.target.dataset;
      if (!arterial) {
        setOpen(false);
      }
    }
    function handleWindowKeyDown(e) {
      const { arterial } = e.target.dataset;
      const isEscape = e.key === 'Escape' || e.keyCode === 27;
      const isTab = e.key === 'Tab' || e.keyCode === 9;
      if ((isEscape || isTab) && arterial === arterialRef.current) {
        setOpen(false);
      }
    }
    document.body.addEventListener('click', handleBodyClick);
    window.addEventListener('keydown', handleWindowKeyDown);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
      window.removeEventListener('keydown', handleWindowKeyDown);
    };
  }, []);
  return (
    <MenuSurfaceAnchor ref={anchorRef}>
      <Button
        data-arterial="quickOpen"
        label="Open Menu"
        onClick={() => setOpen(!open)}
        unelevated
      />
      <MenuSurface
        anchorRef={anchorRef}
        data-arterial="quickOpen"
        open={open}
        quickOpen
      >
        <List data-arterial="quickOpen">
          <ListItem>
            <ListItemText>Menu Item 1</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Menu Item 2</ListItemText>
          </ListItem>
        </List>
      </MenuSurface>
    </MenuSurfaceAnchor>
  );
}

function RightClick() {
  const anchorRef = useRef();
  const arterialRef = useRef('rightClick');
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    function handleBodyClick(e) {
      const { arterial } = e.target.dataset;
      if (!arterial) {
        setOpen(false);
      }
    }
    function handleWindowKeyDown(e) {
      const { arterial } = e.target.dataset;
      const isEscape = e.key === 'Escape' || e.keyCode === 27;
      const isTab = e.key === 'Tab' || e.keyCode === 9;
      if ((isEscape || isTab) && arterial === arterialRef.current) {
        setOpen(false);
      }
    }
    function handleRightClick(e) {
      e.preventDefault();
      setPosition({ x: e.clientX, y: e.clientY });
      setOpen(true);
    }
    document.body.addEventListener('click', handleBodyClick);
    window.addEventListener('keydown', handleWindowKeyDown);
    document
      .getElementById('right-click-demo')
      .addEventListener('contextmenu', handleRightClick);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
      window.removeEventListener('keydown', handleWindowKeyDown);
      document
        .getElementById('right-click-demo')
        .removeEventListener('contextmenu', handleRightClick);
    };
  }, []);
  return (
    <MenuSurface
      anchorRef={anchorRef}
      data-arterial="rightClick"
      open={open}
      position={position}
    >
      <List data-arterial="rightClick">
        <ListItem>
          <ListItemText>Menu Item 1</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Menu Item 2</ListItemText>
        </ListItem>
      </List>
    </MenuSurface>
  );
}

function BottomLeft() {
  const anchorRef = useRef();
  const arterialRef = useRef('bottomLeft');
  const [open, setOpen] = useState(false);
  useEffect(() => {
    function handleBodyClick(e) {
      const { arterial } = e.target.dataset;
      if (!arterial) {
        setOpen(false);
      }
    }
    function handleWindowKeyDown(e) {
      const { arterial } = e.target.dataset;
      const isEscape = e.key === 'Escape' || e.keyCode === 27;
      const isTab = e.key === 'Tab' || e.keyCode === 9;
      if ((isEscape || isTab) && arterial === arterialRef.current) {
        setOpen(false);
      }
    }
    document.body.addEventListener('click', handleBodyClick);
    window.addEventListener('keydown', handleWindowKeyDown);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
      window.removeEventListener('keydown', handleWindowKeyDown);
    };
  }, []);
  return (
    <MenuSurfaceAnchor ref={anchorRef}>
      <Button
        data-arterial="bottomLeft"
        label="Open Menu"
        onClick={() => setOpen(!open)}
        unelevated
      />
      <MenuSurface
        anchorCorner={Corner.BOTTOM_LEFT}
        anchorRef={anchorRef}
        data-arterial="bottomLeft"
        open={open}
      >
        <List data-arterial="bottomLeft">
          <ListItem>
            <ListItemText>Menu Item 1</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Menu Item 2</ListItemText>
          </ListItem>
        </List>
      </MenuSurface>
    </MenuSurfaceAnchor>
  );
}

function TopRight() {
  const anchorRef = useRef();
  const arterialRef = useRef('topRight');
  const [open, setOpen] = useState(false);
  useEffect(() => {
    function handleBodyClick(e) {
      const { arterial } = e.target.dataset;
      if (!arterial) {
        setOpen(false);
      }
    }
    function handleWindowKeyDown(e) {
      const { arterial } = e.target.dataset;
      const isEscape = e.key === 'Escape' || e.keyCode === 27;
      const isTab = e.key === 'Tab' || e.keyCode === 9;
      if ((isEscape || isTab) && arterial === arterialRef.current) {
        setOpen(false);
      }
    }
    document.body.addEventListener('click', handleBodyClick);
    window.addEventListener('keydown', handleWindowKeyDown);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
      window.removeEventListener('keydown', handleWindowKeyDown);
    };
  }, []);
  return (
    <MenuSurfaceAnchor ref={anchorRef}>
      <Button
        data-arterial="topRight"
        label="Open Menu"
        onClick={() => setOpen(!open)}
        unelevated
      />
      <MenuSurface
        anchorCorner={Corner.TOP_RIGHT}
        anchorRef={anchorRef}
        data-arterial="topRight"
        open={open}
      >
        <List data-arterial="topRight">
          <ListItem>
            <ListItemText>Menu Item 1</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Menu Item 2</ListItemText>
          </ListItem>
        </List>
      </MenuSurface>
    </MenuSurfaceAnchor>
  );
}

function BottomRight() {
  const anchorRef = useRef();
  const arterialRef = useRef('bottomRight');
  const [open, setOpen] = useState(false);
  useEffect(() => {
    function handleBodyClick(e) {
      const { arterial } = e.target.dataset;
      if (!arterial) {
        setOpen(false);
      }
    }
    function handleWindowKeyDown(e) {
      const { arterial } = e.target.dataset;
      const isEscape = e.key === 'Escape' || e.keyCode === 27;
      const isTab = e.key === 'Tab' || e.keyCode === 9;
      if ((isEscape || isTab) && arterial === arterialRef.current) {
        setOpen(false);
      }
    }
    document.body.addEventListener('click', handleBodyClick);
    window.addEventListener('keydown', handleWindowKeyDown);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
      window.removeEventListener('keydown', handleWindowKeyDown);
    };
  }, []);
  return (
    <MenuSurfaceAnchor ref={anchorRef}>
      <Button
        data-arterial="bottomRight"
        label="Open Menu"
        onClick={() => setOpen(!open)}
        unelevated
      />
      <MenuSurface
        anchorCorner={Corner.BOTTOM_RIGHT}
        anchorRef={anchorRef}
        data-arterial="bottomRight"
        open={open}
      >
        <List data-arterial="bottomRight">
          <ListItem>
            <ListItemText>Menu Item 1</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Menu Item 2</ListItemText>
          </ListItem>
        </List>
      </MenuSurface>
    </MenuSurfaceAnchor>
  );
}
