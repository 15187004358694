import React from 'react';
export default function ChromeSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="1 1 176 176"
      {...props}
    >
      <defs>
        <circle id="a" cy="96" cx="96" r="88" />
      </defs>
      <clipPath id="b">
        <use width="100%" overflow="visible" xlinkHref="#a" height="100%" />
      </clipPath>
      <g transform="translate(-7 -7)" clipPath="url(#b)">
        <path d="m21.97 8v108h39.39l34.64-60h88v-48z" fill="#db4437" />
        <linearGradient
          id="c"
          y2="44.354"
          gradientUnits="userSpaceOnUse"
          y1="75.021"
          x2="81.837"
          x1="29.337"
        >
          <stop stopColor="#A52714" stopOpacity=".6" offset="0" />
          <stop stopColor="#A52714" stopOpacity="0" offset=".66" />
        </linearGradient>
        <path d="m21.97 8v108h39.39l34.64-60h88v-48z" fill="url(#c)" />
      </g>
      <path
        clipPath="url(#b)"
        fillOpacity=".15"
        d="m62.31 115.65l-39.83-68.31-0.58 1 39.54 67.8z"
        transform="translate(-7 -7)"
        fill="#3e2723"
      />
      <g transform="translate(-7 -7)" clipPath="url(#b)">
        <path
          d="m8 184h83.77l38.88-38.88v-29.12h-69.29l-53.36-91.52z"
          fill="#0f9d58"
        />
        <linearGradient
          id="d"
          y2="130.33"
          gradientUnits="userSpaceOnUse"
          y1="164.5"
          x2="52.538"
          x1="110.87"
        >
          <stop stopColor="#055524" stopOpacity=".4" offset="0" />
          <stop stopColor="#055524" stopOpacity="0" offset=".33" />
        </linearGradient>
        <path
          d="m8 184h83.77l38.88-38.88v-29.12h-69.29l-53.36-91.52z"
          fill="url(#d)"
        />
      </g>
      <path
        clipPath="url(#b)"
        fillOpacity=".15"
        d="m129.84 117.33l-0.83-0.48-38.39 67.15h1.15l38.1-66.64z"
        transform="translate(-7 -7)"
        fill="#263238"
      />
      <g transform="translate(-7 -7)" clipPath="url(#b)">
        <defs>
          <path
            id="e"
            d="m8 184h83.77l38.88-38.88v-29.12h-69.29l-53.36-91.52z"
          />
        </defs>
        <clipPath id="f">
          <use width="100%" overflow="visible" xlinkHref="#e" height="100%" />
        </clipPath>
        <g clipPath="url(#f)">
          <path d="m96 56l34.65 60-38.88 68h92.23v-128z" fill="#ffcd40" />
          <linearGradient
            id="g"
            y2="114.13"
            gradientUnits="userSpaceOnUse"
            y1="49.804"
            x2="136.55"
            x1="121.86"
          >
            <stop stopColor="#EA6100" stopOpacity=".3" offset="0" />
            <stop stopColor="#EA6100" stopOpacity="0" offset=".66" />
          </linearGradient>
          <path d="m96 56l34.65 60-38.88 68h92.23v-128z" fill="url(#g)" />
        </g>
      </g>
      <g transform="translate(-7 -7)" clipPath="url(#b)">
        <path d="m96 56l34.65 60-38.88 68h92.23v-128z" fill="#ffcd40" />
        <path d="m96 56l34.65 60-38.88 68h92.23v-128z" fill="url(#g)" />
      </g>
      <g transform="translate(-7 -7)" clipPath="url(#b)">
        <defs>
          <path id="i" d="m96 56l34.65 60-38.88 68h92.23v-128z" />
        </defs>
        <clipPath id="j">
          <use width="100%" overflow="visible" xlinkHref="#i" height="100%" />
        </clipPath>
        <g clipPath="url(#j)">
          <path d="m21.97 8v108h39.39l34.64-60h88v-48z" fill="#db4437" />
          <path d="m21.97 8v108h39.39l34.64-60h88v-48z" fill="url(#c)" />
        </g>
      </g>
      <radialGradient
        id="l"
        gradientUnits="userSpaceOnUse"
        cy="55.948"
        cx="668.18"
        gradientTransform="translate(-576)"
        r="84.078"
      >
        <stop stopColor="#3E2723" stopOpacity=".2" offset="0" />
        <stop stopColor="#3E2723" stopOpacity="0" offset="1" />
      </radialGradient>
      <path
        clipPath="url(#b)"
        d="m96 56v20.95l78.4-20.95z"
        transform="translate(-7 -7)"
        fill="url(#l)"
      />
      <g transform="translate(-7 -7)" clipPath="url(#b)">
        <defs>
          <path id="m" d="m21.97 8v40.34l39.39 67.66 34.64-60h88v-48z" />
        </defs>
        <clipPath id="n">
          <use width="100%" overflow="visible" xlinkHref="#m" height="100%" />
        </clipPath>
        <g clipPath="url(#n)">
          <path
            d="m8 184h83.77l38.88-38.88v-29.12h-69.29l-53.36-91.52z"
            fill="#0f9d58"
          />
          <path
            d="m8 184h83.77l38.88-38.88v-29.12h-69.29l-53.36-91.52z"
            fill="url(#d)"
          />
        </g>
      </g>
      <radialGradient
        id="p"
        gradientUnits="userSpaceOnUse"
        cy="48.52"
        cx="597.88"
        gradientTransform="translate(-576)"
        r="78.044"
      >
        <stop stopColor="#3E2723" stopOpacity=".2" offset="0" />
        <stop stopColor="#3E2723" stopOpacity="0" offset="1" />
      </radialGradient>
      <path
        clipPath="url(#b)"
        d="m21.97 48.45l57.25 57.24-17.86 10.31z"
        transform="translate(-7 -7)"
        fill="url(#p)"
      />
      <radialGradient
        id="q"
        gradientUnits="userSpaceOnUse"
        cy="96.138"
        cx="671.84"
        gradientTransform="translate(-576)"
        r="87.87"
      >
        <stop stopColor="#263238" stopOpacity=".2" offset="0" />
        <stop stopColor="#263238" stopOpacity="0" offset="1" />
      </radialGradient>
      <path
        clipPath="url(#b)"
        d="m91.83 183.89l20.96-78.2 17.86 10.31z"
        transform="translate(-7 -7)"
        fill="url(#q)"
      />
      <g transform="translate(-7 -7)" clipPath="url(#b)">
        <circle cy="96" cx="96" r="40" fill="#f1f1f1" />
        <circle cy="96" cx="96" r="32" fill="#4285f4" />
      </g>
      <g transform="translate(-7 -7)" clipPath="url(#b)">
        <path
          fillOpacity=".2"
          d="m96 55c-22.09 0-40 17.91-40 40v1c0-22.09 17.91-40 40-40h88v-1h-88z"
          fill="#3e2723"
        />
        <path
          fillOpacity=".1"
          d="m130.6 116c-6.92 11.94-19.81 20-34.6 20-14.8 0-27.69-8.06-34.61-20h-0.04l-53.35-91.52v1l53.36 91.52h0.04c6.92 11.94 19.81 20 34.61 20 14.79 0 27.68-8.05 34.6-20h0.05v-1h-0.06z"
          fill="#fff"
        />
        <path
          opacity=".1"
          d="m97 56c-0.17 0-0.33 0.02-0.5 0.03 21.86 0.27 39.5 18.05 39.5 39.97s-17.64 39.7-39.5 39.97c0.17 0 0.33 0.03 0.5 0.03 22.09 0 40-17.91 40-40s-17.91-40-40-40z"
          fill="#3e2723"
        />
        <path
          fillOpacity=".2"
          d="m131 117.33c3.4-5.88 5.37-12.68 5.37-19.96 0-4.22-0.66-8.28-1.87-12.09 0.95 3.42 1.5 7.01 1.5 10.73 0 7.28-1.97 14.08-5.37 19.96l0.02 0.04-38.88 68h1.16l38.09-66.64-0.02-0.04z"
          fill="#fff"
        />
      </g>
      <g transform="translate(-7 -7)" clipPath="url(#b)">
        <path
          fillOpacity=".2"
          d="m96 9c48.43 0 87.72 39.13 87.99 87.5 0-0.17 0.01-0.33 0.01-0.5 0-48.6-39.4-88-88-88s-88 39.4-88 88c0 0.17 0.01 0.33 0.01 0.5 0.27-48.37 39.56-87.5 87.99-87.5z"
          fill="#fff"
        />
        <path
          fillOpacity=".15"
          d="m96 183c48.43 0 87.72-39.13 87.99-87.5 0 0.17 0.01 0.33 0.01 0.5 0 48.6-39.4 88-88 88s-88-39.4-88-88c0-0.17 0.01-0.33 0.01-0.5 0.27 48.37 39.56 87.5 87.99 87.5z"
          fill="#3e2723"
        />
      </g>
      <radialGradient
        id="r"
        gradientUnits="userSpaceOnUse"
        cy="32.014"
        cx="34.286"
        gradientTransform="translate(-7 -7)"
        r="176.75"
      >
        <stop stopColor="#fff" stopOpacity=".1" offset="0" />
        <stop stopColor="#fff" stopOpacity="0" offset="1" />
      </radialGradient>
      <circle cy="89" cx="89" r="88" fill="url(#r)" />
    </svg>
  );
}
