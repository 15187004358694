import React from 'react';
import { Button } from '@arterial/button';
import { Card } from '@arterial/card';
import { CircularProgress } from '@arterial/circular-progress';
import Code from '../components/code';
import { Demo } from '../components/demo';
import { Link } from 'react-router-dom';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsTable, PropsHeadline } from '../components/props';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
  VariantDescription,
} from '../components/variant';
import { props, strings, types } from '../constants';

export default function Buttons() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Buttons</PageHeadline>
          <PageDescription>
            Buttons allow users to take actions, and make choices, with a single
            tap.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/button</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[
                    { name: 'button', value: '@material/button/index.scss' },
                  ]}
                  mixins={[{ name: 'button', values: ['core-styles'] }]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>import "@material/button/dist/mdc.button.css";</Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { Button } from '@arterial/button';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="text-button">
              <VariantHeader>
                <VariantHeadline>Text Button</VariantHeadline>
                <VariantDescription>
                  Text buttons are typically used for less-pronounced actions,
                  including those located:
                  <ul>
                    <li>In dialogs</li>
                    <li>In cards</li>
                  </ul>
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Button className="demo-item" label="Text" />
                </Demo>
                <Code>{'<Button label="Text" />'}</Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="outlined-button">
              <VariantHeader>
                <VariantHeadline>Outlined Button</VariantHeadline>
                <VariantDescription>
                  Outlined buttons are medium-emphasis buttons. They contain
                  actions that are important, but aren’t the primary action in
                  an app.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Button className="demo-item" label="Outlined" outlined />
                </Demo>
                <Code>{'<Button label="Outlined" outlined />'}</Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="contained-button">
              <VariantHeader>
                <VariantHeadline>Contained Button</VariantHeadline>
                <VariantDescription>
                  Contained buttons are high-emphasis, distinguished by their
                  use of elevation and fill. They contain actions that are
                  primary to your app.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Button className="demo-item" label="Unelevated" unelevated />
                  <Button className="demo-item" label="Raised" raised />
                </Demo>
                <Code>
                  {`<Button label="Unelevated" unelevated />
<Button label="Raised" raised />`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle id="other-variants">Other Variants</PageSubtitle>
            <Variant id="icon">
              <VariantHeader>
                <VariantHeadline>Icon</VariantHeadline>
                <VariantDescription>
                  Buttons can place icons next to text labels to both clarify an
                  action and call attention to a button.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Button className="demo-item" label="Text" icon="favorite" />
                  <Button
                    className="demo-item"
                    label="Outlined"
                    outlined
                    icon="favorite"
                  />
                  <Button
                    className="demo-item"
                    label="Unelevated"
                    unelevated
                    icon="favorite"
                  />
                  <Button
                    className="demo-item"
                    label="Raised"
                    raised
                    icon="favorite"
                  />
                </Demo>
                <Code>
                  {`<Button label="Text" icon="favorite" />
<Button label="Outlined" outlined icon="favorite" />
<Button label="Unelevated" unelevated icon="favorite" />
<Button label="Raised" raised icon="favorite" />`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="trailing-icon">
              <VariantHeader>
                <VariantHeadline>Trailing Icon</VariantHeadline>
                <VariantDescription>
                  Certain icons make more sense to appear after the button's
                  text label rather than before.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Button
                    className="demo-item"
                    label="Text"
                    trailingIcon="keyboard_arrow_right"
                  />
                  <Button
                    className="demo-item"
                    label="Outlined"
                    outlined
                    trailingIcon="keyboard_arrow_right"
                  />
                  <Button
                    className="demo-item"
                    label="Unelevated"
                    unelevated
                    trailingIcon="keyboard_arrow_right"
                  />
                  <Button
                    className="demo-item"
                    label="Raised"
                    raised
                    trailingIcon="keyboard_arrow_right"
                  />
                </Demo>
                <Code>
                  {`<Button label="Text" trailingIcon="keyboard_arrow_right" />
<Button label="Outlined" outlined trailingIcon="keyboard_arrow_right" />
<Button label="Unelevated" unelevated trailingIcon="keyboard_arrow_right" />
<Button label="Raised" raised trailingIcon="keyboard_arrow_right" />`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="disabled">
              <VariantHeader>
                <VariantHeadline>Disabled</VariantHeadline>
                <VariantDescription>
                  Disabled buttons cannot be interacted with and have no visual
                  interaction effect.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Button className="demo-item" label="Text" disabled />
                  <Button
                    className="demo-item"
                    label="Outlined"
                    outlined
                    disabled
                  />
                  <Button
                    className="demo-item"
                    label="Unelevated"
                    unelevated
                    disabled
                  />
                  <Button
                    className="demo-item"
                    label="Raised"
                    raised
                    disabled
                  />
                </Demo>
                <Code>
                  {`<Button label="Text" disabled />
<Button label="Outlined" outlined disabled />
<Button label="Unelevated" unelevated disabled />
<Button label="Raised" raised disabled />`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="loader">
              <VariantHeader>
                <VariantHeadline>Loader</VariantHeadline>
                <VariantDescription></VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Button
                    className="demo-item"
                    label="Text"
                    icon={<CircularProgress small />}
                  />
                  <Button
                    className="demo-item"
                    label="Outlined"
                    outlined
                    icon={<CircularProgress small />}
                  />
                  <Button
                    className="demo-item"
                    label="Unelevated"
                    unelevated
                    icon={<CircularProgress small />}
                  />
                  <Button
                    className="demo-item"
                    label="Raised"
                    raised
                    icon={<CircularProgress small />}
                  />
                </Demo>
                <Code>
                  {`import { CircularProgress } from '@arterial/circular-progress';

<Button label="Text" icon={<CircularProgress small />} />
<Button label="Outlined" outlined icon={<CircularProgress small />} />
<Button label="Unelevated" unelevated icon={<CircularProgress small />} />
<Button label="Raised" raised icon={<CircularProgress small />} />`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="loader">
              <VariantHeader>
                <VariantHeadline>Anchor</VariantHeadline>
                <VariantDescription></VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Button
                    className="demo-item"
                    label="Text"
                    tag="a"
                    href={strings.GITHUB_URL}
                  />
                  <Button
                    className="demo-item"
                    label="Outlined"
                    outlined
                    tag="a"
                    href={strings.GITHUB_URL}
                  />
                  <Button
                    className="demo-item"
                    label="Unelevated"
                    unelevated
                    tag="a"
                    href={strings.GITHUB_URL}
                  />
                  <Button
                    className="demo-item"
                    label="Raised"
                    raised
                    tag="a"
                    href={strings.GITHUB_URL}
                  />
                </Demo>
                <Code>
                  {`<Button label="Text" tag="a" href="${strings.GITHUB_URL}" />
<Button label="Outlined" outlined tag="a" href="${strings.GITHUB_URL}" />
<Button label="Unelevated" unelevated tag="a" href="${strings.GITHUB_URL}" />
<Button label="Raised" raised tag="a" href="${strings.GITHUB_URL}" />`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="react-router-link">
              <VariantHeader>
                <VariantHeadline>React Router Link</VariantHeadline>
                <VariantDescription></VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Button
                    className="demo-item"
                    label="Text"
                    tag={Link}
                    to="/cards"
                  />
                  <Button
                    className="demo-item"
                    label="Outlined"
                    outlined
                    tag={Link}
                    to="/cards"
                  />
                  <Button
                    className="demo-item"
                    label="Unelevated"
                    unelevated
                    tag={Link}
                    to="/cards"
                  />
                  <Button
                    className="demo-item"
                    label="Raised"
                    raised
                    tag={Link}
                    to="/cards"
                  />
                </Demo>
                <Code>
                  {`import { Link } from 'react-router-dom';

<Button label="Text" tag={Link} to="/cards" />
<Button label="Outlined" outlined tag={Link} to="/cards" />
<Button label="Unelevated" unelevated tag={Link} to="/cards" />
<Button label="Raised" raised tag={Link} to="/cards" />`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>Button</PropsHeadline>
              <PropsTable
                body={[
                  props.CLASS_NAME,
                  props.ICON,
                  props.LABEL,
                  props.OUTLINED,
                  ['raised', types.BOOLEAN, 'Enables a raised variant.'],
                  props.RIPPLE,
                  props.STYLE,
                  props.TAG_BUTTON,
                  props.TRAILING_ICON,
                  [
                    'unelevated',
                    types.BOOLEAN,
                    'Enables an unelevated variant.',
                  ],
                ]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}
