import React from 'react';
import classNames from 'classnames';
import './usage.scss';

export function Usage({ children, className, ...otherProps }) {
  return (
    <div className={classNames('usage', className)} {...otherProps}>
      {children}
    </div>
  );
}

export function UsageTitle({ children, className, ...otherProps }) {
  return (
    <div className={classNames('usage__title', className)} {...otherProps}>
      {children}
    </div>
  );
}

export function UsageSubtitle({ children, className, ...otherProps }) {
  return (
    <div className={classNames('usage__subtitle', className)} {...otherProps}>
      {children}
    </div>
  );
}
