import React, { useState } from 'react';
import { Card } from '@arterial/card';
import ChromeSvg from '../components/svgs/ChromeSvg';
import Code from '../components/code';
import { Demo } from '../components/demo';
import { IconButton } from '@arterial/icon-button';
import FirefoxSvg from '../components/svgs/FirefoxSvg';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsTable, PropsHeadline } from '../components/props';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
  VariantDescription,
} from '../components/variant';
import { props, types } from '../constants';

export default function IconButtons() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Icon Buttons</PageHeadline>
          <PageDescription>
            Icon buttons allow users to take actions, and make choices, with a
            single tap.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/icon-button</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[
                    {
                      name: 'icon-button',
                      value: '@material/icon-button/index.scss',
                    },
                  ]}
                  mixins={[{ name: 'icon-button', values: ['core-styles'] }]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>
                  import "@material/icon-button/dist/mdc.icon-button.css";
                </Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { IconButton } from '@arterial/icon-button';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="regular-icon-button">
              <VariantHeader>
                <VariantHeadline>Regular Icon Button</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <IconButton icon="favorite" />
                  </div>
                </Demo>
                <Code>{`<IconButton icon="favorite" />`}</Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="toggle-button">
              <VariantHeader>
                <VariantHeadline>Toggle Button</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Toggle />
                  </div>
                </Demo>
                <Code>
                  {`function Toggle() {
  const [on, setOn] = useState(false);
  return (
    <IconButton
      icon="favorite_border"
      onIcon="favorite"
      on={on}
      onClick={() => setOn(!on)}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Other Variants</PageSubtitle>
            <Variant id="image">
              <VariantHeader>
                <VariantHeadline>Image</VariantHeadline>
                <VariantDescription>
                  Icon buttons can be used with img tags.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <IconButton
                    icon={
                      <img
                        src="https://instagram-brand.com/wp-content/uploads/2016/11/Instagram_AppIcon_Aug2017.png"
                        alt="instagram"
                      />
                    }
                  />
                </Demo>
                <Code>
                  {`<IconButton
  icon={
    <img
      src="https://instagram-brand.com/wp-content/uploads/2016/11/Instagram_AppIcon_Aug2017.png"
      alt="instagram"
    />
  }
/>;`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="toggle-image">
              <VariantHeader>
                <VariantHeadline>Toggle Image</VariantHeadline>
                <VariantDescription>
                  Icon button toggles can be used with img tags.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <ToggleImage />
                </Demo>
                <Code>
                  {`function ToggleImage() {
  const [on, setOn] = useState(false);
  return (
    <IconButton
      icon={
        <img
          src="https://en.facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png"
          alt="facebook"
        />
      }
      onIcon={
        <img
          src="https://instagram-brand.com/wp-content/uploads/2016/11/Instagram_AppIcon_Aug2017.png"
          alt="instagram"
        />
      }
      on={on}
      onClick={() => setOn(!on)}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="svg">
              <VariantHeader>
                <VariantHeadline>SVG</VariantHeadline>
                <VariantDescription>
                  Icon buttons can be used with svgs.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <IconButton icon={<FirefoxSvg />} />
                </Demo>
                <Code>{`<IconButton icon={<FirefoxSvg />} />`}</Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="toggle-svg">
              <VariantHeader>
                <VariantHeadline>Toggle SVG</VariantHeadline>
                <VariantDescription>
                  Icon button toggles can be used with svgs.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <ToggleSvg />
                </Demo>
                <Code>
                  {`function ToggleSvg() {
  const [on, setOn] = useState(false);
  return (
    <IconButton
      icon={<ChromeSvg />}
      onIcon={<FirefoxSvg />}
      on={on}
      onClick={() => setOn(!on)}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>IconButton</PropsHeadline>
              <PropsTable
                body={[
                  props.CLASS_NAME,
                  [
                    props.ICON[0],
                    props.ICON[1],
                    'Icon to render within root element. Displays when toggle is "off".',
                  ],
                  [
                    props.LABEL[0],
                    props.LABEL[1],
                    'Sets the aria-label on root element.',
                  ],
                  ['on', types.BOOLEAN, 'Indicates the toggle is on.'],
                  [
                    'onIcon',
                    props.ICON[1],
                    'Icon to render within root element. Displays when toggle is "on".',
                  ],
                  props.STYLE,
                  props.TAG_BUTTON,
                ]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}

function Toggle() {
  const [on, setOn] = useState(false);
  return (
    <IconButton
      icon="favorite_border"
      onIcon="favorite"
      on={on}
      onClick={() => setOn(!on)}
    />
  );
}

function ToggleImage() {
  const [on, setOn] = useState(false);
  return (
    <IconButton
      icon={
        <img
          src="https://en.facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png"
          alt="facebook"
        />
      }
      onIcon={
        <img
          src="https://instagram-brand.com/wp-content/uploads/2016/11/Instagram_AppIcon_Aug2017.png"
          alt="instagram"
        />
      }
      on={on}
      onClick={() => setOn(!on)}
    />
  );
}

function ToggleSvg() {
  const [on, setOn] = useState(false);
  return (
    <IconButton
      icon={<ChromeSvg />}
      onIcon={<FirefoxSvg />}
      on={on}
      onClick={() => setOn(!on)}
    />
  );
}
