import React, { useState } from 'react';
import { Card } from '@arterial/card';
import { Chip, ChipSet } from '@arterial/chips';
import { CircularProgress } from '@arterial/circular-progress';
import Code from '../components/code';
import { Demo } from '../components/demo';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsTable, PropsHeadline } from '../components/props';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
  VariantDescription,
} from '../components/variant';
import { props, types } from '../constants';

export default function Chips() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Chips</PageHeadline>
          <PageDescription>
            Chips are compact elements that represent an input, attribute, or
            action.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/chips</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[{name: 'chips', value: '@material/chips/index.scss'}]}
                  mixins={[{name: 'chips', values: ['core-styles', 'set-core-styles']}]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>import "@material/chips/dist/mdc.chips.css";</Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { Chip, ChipSet } from '@arterial/chips';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="input-chips">
              <VariantHeader>
                <VariantHeadline>Input Chips</VariantHeadline>
                <VariantDescription>
                  Input chips represent a complex piece of information in
                  compact form, such as an entity (person, place, or thing) or
                  text.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--chips demo-item--fullwidth">
                    <Input />
                  </div>
                </Demo>
                <Code>
                  {`const CHIPS = ['Alfa', 'Bravo', 'Charlie', 'Delta'];
function Input() {
  const [chips, setChips] = useState(CHIPS);
  function handleKeyDown(e) {
    const text = e.target.value;
    const isEnter = e.key === 'Enter' || e.keyCode === 13;
    if (!!text && isEnter) {
      const id = text;
      const newChips = [...chips]; // triggers re-render
      if (newChips.some((c) => c.text === id)) {
        alert('There is already a chip with that name.');
      } else {
        newChips.push({ text, id });
        setChips(newChips);
        e.target.value = '';
      }
    }
  }
  function handleTrailingIconSelect(id) {
    const newChips = chips.filter((c) => c.text !== id);
    setChips(newChips);
  }
  return (
    <div className="input-chips">
      <span className="input-chips__label">Input:</span>
      <ChipSet className="input-chips__chip-set" input>
        <div>
          {chips.map((chip) => (
            <Chip
              id={chip.text}
              key={chip.text}
              text={chip.text}
              trailingIcon="cancel"
              onTrailingIconSelect={handleTrailingIconSelect}
            />
          ))}
        </div>
        <input className="input-chips__input" onKeyDown={handleKeyDown} />
      </ChipSet>
    </div>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="choice-chips">
              <VariantHeader>
                <VariantHeadline>Choice Chips</VariantHeadline>
                <VariantDescription>
                  Choice chips allow selection of a single chip from a set of
                  options.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--chips demo-item--fullwidth">
                    <Choice />
                  </div>
                </Demo>
                <Code>
                  {`const CHIPS = ['Alfa', 'Bravo', 'Charlie', 'Delta'];
function Choice() {
  const [selected, setSelected] = useState('');
  return (
    <ChipSet choice>
      {CHIPS.map((chip) => (
        <Chip
          id={chip.text}
          key={chip.text}
          selected={chip.text === selected}
          text={chip.text}
          onSelect={() => setSelected(chip.text)}
        />
      ))}
    </ChipSet>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="Filter Chips">
              <VariantHeader>
                <VariantHeadline>Filter Chips</VariantHeadline>
                <VariantDescription>
                  Filter chips use tags or descriptive words to filter content.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--chips demo-item--fullwidth">
                    <Filter />
                  </div>
                </Demo>
                <Code>
                  {`const CHIPS = ['Alfa', 'Bravo', 'Charlie', 'Delta'];
function Filter() {
  const [selected, setSelected] = useState(new Set());
  function handleSelect(value) {
    const sel = new Set(selected);
    if (sel.has(value)) sel.delete(value);
    else sel.add(value);
    setSelected(sel);
  }
  return (
    <ChipSet filter>
      {CHIPS.map((chip) => (
        <Chip
          checkmark
          id={chip.text}
          key={chip.text}
          selected={selected.has(chip.text)}
          text={chip.text}
          onSelect={() => handleSelect(chip.text)}
        />
      ))}
    </ChipSet>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="action-chips">
              <VariantHeader>
                <VariantHeadline>Action Chips</VariantHeadline>
                <VariantDescription>
                  Action chips offer actions related to primary content. They
                  should appear dynamically and contextually in a UI.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--chips demo-item--fullwidth">
                    <Action />
                  </div>
                </Demo>
                <Code>
                  {`import { CircularProgress } from '@arterial/circular-progress';

const CHIPS = ['Alfa', 'Bravo', 'Charlie', 'Delta'];
function Action() {
  const [selected, setSelected] = useState(false);
  const [saving, setSaving] = useState(false);
  function icon() {
    if (saving) return <CircularProgress small />;
    if (selected) return 'favorite';
    return 'favorite_outlined';
  }
  function text() {
    if (selected && saving) return 'Removing from favorites';
    else if (saving) return 'Saving to favorites';
    else if (selected) return 'Saved to favorites';
    else return 'Save to favorites';
  }
  function handleSelect() {
    setSaving(true);
    setTimeout(() => {
      setSaving(false);
      setSelected(!selected);
    }, 3000);
  }
  return (
    <Chip
      icon={icon()}
      id="action"
      key="action"
      text={text()}
      onSelect={handleSelect}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Other Variants</PageSubtitle>
            <Variant id="icon">
              <VariantHeader>
                <VariantHeadline>Icon</VariantHeadline>
                <VariantDescription>
                  Choice chips with icons.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--chips demo-item--fullwidth">
                    <Icon />
                  </div>
                </Demo>
                <Code>
                  {`const CHIPS = ['Alfa', 'Bravo', 'Charlie', 'Delta'];
function Icon() {
  const [selected, setSelected] = useState('');
  return (
    <ChipSet choice>
      {CHIPS.map((chip) => (
        <Chip
          icon="face"
          id={chip.text}
          key={chip.text}
          selected={chip.text === selected}
          text={chip.text}
          onSelect={() => setSelected(chip.text)}
        />
      ))}
    </ChipSet>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="choice-filter">
              <VariantHeader>
                <VariantHeadline>Choice and Filter</VariantHeadline>
                <VariantDescription>
                  Choice and filter chips with icons.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--chips demo-item--fullwidth">
                    <ChoiceFilter />
                  </div>
                </Demo>
                <Code>
                  {`const CHIPS = ['Alfa', 'Bravo', 'Charlie', 'Delta'];
function ChoiceFilter() {
  const [selected, setSelected] = useState(new Set());
  function handleSelect(value) {
    const sel = new Set(selected);
    if (sel.has(value)) {
      sel.delete(value);
    } else {
      sel.add(value);
    }
    setSelected(sel);
  }
  return (
    <ChipSet choice filter>
      {CHIPS.map((chip) => (
        <Chip
          checkmark
          icon="face"
          id={chip.text}
          key={chip.text}
          selected={selected.has(chip.text)}
          text={chip.text}
          onSelect={() => handleSelect(chip.text)}
        />
      ))}
    </ChipSet>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>Chip</PropsHeadline>
              <PropsTable
                body={[
                  [
                    'checkmark',
                    types.BOOLEAN,
                    'Enables checkmark to be displayed within root element when element is selected.',
                  ],
                  props.CLASS_NAME,
                  props.ICON,
                  props.ID,
                  props.ON_SELECT,
                  [
                    'onTrailingIconSelect',
                    types.FUNCTION,
                    'Trailing icon select event handler.',
                  ],
                  props.RIPPLE,
                  props.SELECTED,
                  [
                    'text',
                    types.STRING,
                    'Text to be displayed within root element.',
                  ],
                  props.TRAILING_ICON,
                  props.TAG_DIV,
                ]}
              />
            </Props>
            <Props>
              <PropsHeadline>ChipSet</PropsHeadline>
              <PropsTable
                body={[
                  props.CHILDREN,
                  props.CLASS_NAME,
                  ['choice', types.BOOLEAN, 'Enables a choice variant.'],
                  ['filter', types.BOOLEAN, 'Enables a filter variant.'],
                  ['input', types.BOOLEAN, 'Enables an input variant.'],
                  props.TAG_DIV,
                ]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}

const CHIPS = ['Alfa', 'Bravo', 'Charlie', 'Delta'];

function Input() {
  const [chips, setChips] = useState(CHIPS);
  function handleKeyDown(e) {
    const text = e.target.value;
    const isEnter = e.key === 'Enter' || e.keyCode === 13;
    if (!!text && isEnter) {
      const newChips = [...chips]; // triggers re-render
      if (newChips.some((chip) => chip === text)) {
        alert('There is already a chip with that name.');
      } else {
        newChips.push(text);
        setChips(newChips);
        e.target.value = '';
      }
    }
  }
  function handleTrailingIconSelect(id) {
    const newChips = chips.filter((chip) => chip !== id);
    setChips(newChips);
  }
  return (
    <div className="input-chips">
      <span className="input-chips__label">Input:</span>
      <ChipSet className="input-chips__chip-set" input>
        <div>
          {chips.map((chip) => (
            <Chip
              id={chip}
              key={chip}
              text={chip}
              trailingIcon="cancel"
              onTrailingIconSelect={handleTrailingIconSelect}
            />
          ))}
        </div>
        <input className="input-chips__input" onKeyDown={handleKeyDown} />
      </ChipSet>
    </div>
  );
}

function Choice() {
  const [selected, setSelected] = useState('');
  return (
    <ChipSet choice>
      {CHIPS.map((chip) => (
        <Chip
          id={chip}
          key={chip}
          selected={chip === selected}
          text={chip}
          onSelect={() => setSelected(chip)}
        />
      ))}
    </ChipSet>
  );
}

function Filter() {
  const [selected, setSelected] = useState(new Set());
  function handleSelect(value) {
    const sel = new Set(selected);
    if (sel.has(value)) sel.delete(value);
    else sel.add(value);
    setSelected(sel);
  }
  return (
    <ChipSet filter>
      {CHIPS.map((chip) => (
        <Chip
          checkmark
          id={chip}
          key={chip}
          selected={selected.has(chip)}
          text={chip}
          onSelect={() => handleSelect(chip)}
        />
      ))}
    </ChipSet>
  );
}

function Action() {
  const [selected, setSelected] = useState(false);
  const [saving, setSaving] = useState(false);
  function icon() {
    if (saving) return <CircularProgress small />;
    if (selected) return 'favorite';
    return 'favorite_outlined';
  }
  function text() {
    if (selected && saving) return 'Removing from favorites';
    else if (saving) return 'Saving to favorites';
    else if (selected) return 'Saved to favorites';
    else return 'Save to favorites';
  }
  function handleSelect() {
    setSaving(true);
    setTimeout(() => {
      setSaving(false);
      setSelected(!selected);
    }, 3000);
  }
  return (
    <Chip
      icon={icon()}
      id="action"
      key="action"
      text={text()}
      onSelect={handleSelect}
    />
  );
}

function Icon() {
  const [selected, setSelected] = useState('');
  return (
    <ChipSet choice>
      {CHIPS.map((chip) => (
        <Chip
          icon="face"
          id={chip}
          key={chip}
          selected={chip === selected}
          text={chip}
          onSelect={() => setSelected(chip)}
        />
      ))}
    </ChipSet>
  );
}

function ChoiceFilter() {
  const [selected, setSelected] = useState(new Set());
  function handleSelect(value) {
    const sel = new Set(selected);
    if (sel.has(value)) {
      sel.delete(value);
    } else {
      sel.add(value);
    }
    setSelected(sel);
  }
  return (
    <ChipSet choice filter>
      {CHIPS.map((chip) => (
        <Chip
          checkmark
          icon="face"
          id={chip}
          key={chip}
          selected={selected.has(chip)}
          text={chip}
          onSelect={() => handleSelect(chip)}
        />
      ))}
    </ChipSet>
  );
}
