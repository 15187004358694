import React from 'react';
import classNames from 'classnames';
import './page.scss';

export function Page({ children, className, ...otherProps }) {
  return (
    <div className={classNames('page', className)} {...otherProps}>
      {children}
    </div>
  );
}

export function PageHeader({ children, className, ...otherProps }) {
  return (
    <div className={classNames('page__header', className)} {...otherProps}>
      {children}
    </div>
  );
}

export function PageHeadline({ children, className, ...otherProps }) {
  return (
    <div className={classNames('page__headline', className)} {...otherProps}>
      {children}
    </div>
  );
}

export function PageDescription({ children, className, ...otherProps }) {
  return (
    <div className={classNames('page__description', className)} {...otherProps}>
      {children}
    </div>
  );
}

export function PageContent({ children, className, ...otherProps }) {
  return (
    <div className={classNames('page__content', className)} {...otherProps}>
      {children}
    </div>
  );
}

export function PageSection({ children, className, ...otherProps }) {
  return (
    <div className={classNames('page__section', className)} {...otherProps}>
      {children}
    </div>
  );
}

export function PageSubtitle({ children, className, ...otherProps }) {
  return (
    <div
      className={classNames(
        'page__subtitle',
        'mdc-typography--headline4',
        className
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
}
