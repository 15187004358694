import React, { Fragment } from 'react';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash';
import classNames from 'classnames';
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx';
import './code.scss';

SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('jsx', jsx);

export default function Code({ bash, children, files, mixins, sass }) {
  if (sass) {
    return (
      <div className="code lang-scss">
        <pre className="hljs">
          <code>
            {Array.isArray(files) &&
              files.map(({ name, value }, index) => (
                <Fragment key={value}>
                  {index > 0 && <br />}
                  <span className="token keyword">@use </span>
                  <span className="token string">"{value}"</span>
                  {name && (
                    <>
                      <span className="token keyword"> as</span>
                      <span className="token variable"> {name}</span>
                    </>
                  )}
                  <span className="token punctuation">;</span>
                </Fragment>
              ))}
            {Array.isArray(mixins) &&
              mixins.map(({ name, values }) => (
                <Fragment key={name}>
                  {values.map((mixin) => (
                    <Fragment key={name + mixin}>
                      <br />
                      <span className="token keyword">@include</span>{' '}
                      <span className="token variable">{name}</span>
                      <span className="token punctuation">.</span>
                      <span className="token mixin">{mixin}</span>
                      <span className="token punctuation">;</span>
                    </Fragment>
                  ))}
                </Fragment>
              ))}
          </code>
        </pre>
      </div>
    );
  }

  const language = bash ? 'bash' : 'jsx';
  return (
    <div className={classNames('code', `lang-${language}`)}>
      <SyntaxHighlighter
        language={language}
        useInlineStyles={false}
        PreTag={PreTag}
      >
        {children}
      </SyntaxHighlighter>
    </div>
  );
}

function PreTag({ children, className }) {
  return <pre className={className}>{children}</pre>;
}
