import React, { useEffect, useState } from 'react';
import Buttons from './pages/Buttons';
import Cards from './pages/Cards';
import Checkboxes from './pages/Checkboxes';
import Chips from './pages/Chips';
import CircularProgress from './pages/CircularProgress';
import DataTables from './pages/DataTables';
import Dialogs from './pages/Dialogs';
import Fabs from './pages/Fabs';
import Home from './pages/home/Home';
import IconButtons from './pages/IconButtons';
import LayoutGrids from './pages/LayoutGrids';
import Lists from './pages/Lists';
import Menus from './pages/Menus';
import Radios from './pages/Radios';
import Snackbars from './pages/Snackbars';
import Selects from './pages/Selects';
import Switches from './pages/Switches';
import Tabs from './pages/Tabs';
import TextFields from './pages/TextFields';
import {
  Drawer,
  DrawerAppContent,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from '@arterial/drawer';
import GitHubSvg from './components/svgs/GitHubSvg';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@arterial/list';
import {
  TopAppBar,
  TopAppBarIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from '@arterial/top-app-bar';
import classNames from 'classnames';
import { strings } from './constants';
import './app.scss';

const routes = [
  'Buttons',
  'Cards',
  'Checkboxes',
  'Chips',
  'Circular Progress',
  'Data Tables',
  'Dialogs',
  'Fabs',
  'Icon Buttons',
  'Layout Grids',
  'Lists',
  'Menus',
  'Radios',
  'Selects',
  'Snackbars',
  'Switches',
  'Tabs',
  'Text Fields',
];

function App() {
  const [desktop, setDesktop] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    function handleResize() {
      const body = document.body.getBoundingClientRect();
      setDesktop(body.width >= 840);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="app">
      <TopAppBar fixed>
        <TopAppBarRow>
          <TopAppBarSection>
            {!desktop && (
              <TopAppBarIcon nav icon="menu" onClick={() => setOpen(!open)} />
            )}
            <TopAppBarTitle className="app-bar__title" tag={Link} to="/">
              Arterial
            </TopAppBarTitle>
          </TopAppBarSection>
          <TopAppBarSection alignEnd>
            <TopAppBarIcon
              action
              icon={GitHubSvg}
              tag="a"
              target="_blank"
              href={strings.GITHUB_URL}
            />
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>
      {(location.pathname !== '/' || !desktop) && (
        <Drawer
          className="drawer"
          modal={!desktop}
          open={desktop ? null : open}
          onClose={() => setOpen(false)}
        >
          <DrawerHeader className="drawer__header">
            <DrawerTitle className="drawer__title">Arterial</DrawerTitle>
          </DrawerHeader>
          <DrawerContent>
            <List>
              {routes.map((route) => {
                const path = `/${route.toLowerCase().replace(/ /g, '-')}`;
                return (
                  <ListItem
                    activated={path === location.pathname}
                    key={route}
                    tag={Link}
                    to={path}
                  >
                    <ListItemText>{route}</ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </DrawerContent>
        </Drawer>
      )}
      <DrawerAppContent
        className={classNames({
          'drawer-app-content': location.pathname !== '/' || !desktop,
        })}
      >
        <div className="mdc-top-app-bar--fixed-adjust"></div>
        <Switch>
          <Route exact path="/buttons" children={<Buttons />} />
          <Route exact path="/cards" children={<Cards />} />
          <Route exact path="/checkboxes" children={<Checkboxes />} />
          <Route exact path="/chips" children={<Chips />} />
          <Route
            exact
            path="/circular-progress"
            children={<CircularProgress />}
          />
          <Route exact path="/data-tables" children={<DataTables />} />
          <Route exact path="/dialogs" children={<Dialogs />} />
          <Route exact path="/fabs" children={<Fabs />} />
          <Route exact path="/icon-buttons" children={<IconButtons />} />
          <Route exact path="/layout-grids" children={<LayoutGrids />} />
          <Route exact path="/lists" children={<Lists />} />
          <Route exact path="/menus" children={<Menus />} />
          <Route exact path="/radios" children={<Radios />} />
          <Route exact path="/selects" children={<Selects />} />
          <Route exact path="/snackbars" children={<Snackbars />} />
          <Route exact path="/switches" children={<Switches />} />
          <Route exact path="/tabs" children={<Tabs />} />
          <Route exact path="/text-fields" children={<TextFields />} />
          <Route exact path="/" children={<Home desktop={desktop} />} />
        </Switch>
      </DrawerAppContent>
    </div>
  );
}

export default App;
