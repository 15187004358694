export const strings = {
  CHECKBOX: 'checkbox',
  CHECKED: 'checked',
  DISABLED: 'disabled',
  GITHUB_URL: 'https://github.com/jdc2000/arterial-components',
  LABEL: 'label',
  OPEN: 'open',
  OUTLINED: 'outlined',
  RADIO: 'radio',
  SELECTED: 'selected',
  SWITCH: 'switch',
};

export const types = {
  BOOLEAN: 'boolean',
  FUNCTION: 'function',
  NODE: 'node',
  NUMBER: 'number',
  OBJECT: 'object',
  STRING: 'string',
  STRING_OR_NODE: 'string | node',
  STRING_OR_OBJECT: 'string | object',
};

function enables(name) {
  const aOrAn = ['a', 'e', 'i', 'o', 'u'].includes(name.toLowerCase().charAt(0))
    ? 'an'
    : 'a';
  return `Enables ${aOrAn} ${name} variant.`;
}

function indicates(name) {
  return `Indicates whether the element is ${name}.`;
}

function label(value) {
  let description = 'Text to be displayed ';
  if (value === 'next') {
    description = `${description} next to the root element.`;
  } else {
    description = `${description} within the root element.`;
  }
  return ['label', types.STRING, description];
}

function tag(value) {
  let description = 'HTML tag to be applied to the root element.';
  if (value) {
    description = `${description} Defaults to ${value}.`;
  }
  return ['tag', types.STRING_OR_OBJECT, description];
}

export const props = {
  ALIGN_END: [
    'alignEnd',
    types.BOOLEAN,
    'Aligns root element on the right side of the label.',
  ],
  CHECKED: [strings.CHECKED, types.BOOLEAN, indicates(strings.CHECKED)],
  CHILDREN: [
    'children',
    types.NODE,
    'Elements to be displayed within root element.',
  ],
  CLASS_NAME: [
    'className',
    types.STRING,
    'Classes to be applied to the root element.',
  ],
  DISABLED: [strings.DISABLED, types.BOOLEAN, indicates(strings.DISABLED)],
  ICON: ['icon', types.STRING_OR_NODE, 'Icon to render within root element.'],
  ID: ['id', types.STRING, 'Id of the element.'],
  LABEL: label(),
  LABEL_NEXT: label('next'),
  ON_CHANGE: ['onChange', types.FUNCTION, 'Change event handler.'],
  ON_CLOSE: ['onClose', types.FUNCTION, 'Close event handler.'],
  ON_SELECT: ['onSelect', types.FUNCTION, 'Select event handler.'],
  OPEN: ['open', types.BOOLEAN, indicates(strings.OPEN)],
  OUTLINED: [strings.OUTLINED, types.BOOLEAN, enables(strings.OUTLINED)],
  RIPPLE: [
    'ripple',
    types.BOOLEAN,
    'Enables ripple within root element. Defaults to true.',
  ],
  SELECTED: ['selected', types.BOOLEAN, indicates(strings.SELECTED)],
  STYLE: ['style', types.OBJECT, 'Styles to be applied to the root element.'],
  TAG_BUTTON: tag('button'),
  TAG_DIV: tag('div'),
  TAG_LI: tag('li'),
  TAG_SPAN: tag('span'),
  TRAILING_ICON: [
    'trailingIcon',
    types.STRING_OR_NODE,
    'Icon to render on the right side of the root element.',
  ],
  VALUE: ['value', types.STRING, 'Value of input.'],
};
