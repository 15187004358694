import React from 'react';
import {
  Card,
  CardMedia,
  CardPrimaryAction,
  CardActions,
  CardActionButtons,
  CardActionButton,
  CardActionIcons,
  CardActionIcon,
  CardMediaContent,
} from '@arterial/card';
import Code from '../components/code';
import { Demo } from '../components/demo';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsHeadline, PropsTable } from '../components/props';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
  VariantDescription,
} from '../components/variant';
import { props, types } from '../constants';

export default function Cards() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Cards</PageHeadline>
          <PageDescription>
            Cards contain content and actions about a single subject.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/card</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[{ name: 'card', value: '@material/card/index.scss' }]}
                  mixins={[{ name: 'card', values: ['core-styles'] }]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>import "@material/card/dist/mdc.card.css";</Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { Card, CardPrimaryAction, CardActions, ... } from '@arterial/card';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="elevated-card">
              <VariantHeader>
                <VariantHeadline>Elevated Card</VariantHeadline>
                <VariantDescription>
                  A shadow helps indicate a card.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Card className="demo-item demo-item--card demo-item--fullwidth">
                    <div className="primary-title">
                      <div className="title">Card title</div>
                      <div className="subtitle">Secondary text</div>
                    </div>
                  </Card>
                </Demo>
                <Code>
                  {`<Card>
  <div className="primary-title">
    <div className="title">Card title</div>
    <div className="subtitle">Secondary text</div>
  </div>
</Card>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="outlined-card">
              <VariantHeader>
                <VariantHeadline>Outlined Card</VariantHeadline>
                <VariantDescription>
                  An outline helps indicate a card.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Card className="demo-item demo-item--card demo-item--fullwidth" outlined>
                    <div className="primary-title">
                      <div className="title">Card title</div>
                      <div className="subtitle">Secondary text</div>
                    </div>
                  </Card>
                </Demo>
                <Code>
                  {`<Card outlined>
  <div className="primary-title">
    <div className="title">Card title</div>
    <div className="subtitle">Secondary text</div>
  </div>
</Card>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle id="other-variants">Other Variants</PageSubtitle>
            <Variant id="primary-action">
              <VariantHeader>
                <VariantHeadline>Primary Action</VariantHeadline>
                <VariantDescription>
                  The primary action area of a card is typically the card
                  itself. Often cards are one large touch target to a detail
                  screen on a subject.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Card className="demo-item demo-item--card demo-item--fullwidth">
                    <CardPrimaryAction>
                      <div className="primary-title">
                        <div className="title">Card title</div>
                        <div className="subtitle">Secondary text</div>
                      </div>
                    </CardPrimaryAction>
                  </Card>
                </Demo>
                <Code>
                  {`<Card>
  <CardPrimaryAction>
    <div className="primary-title">
      <div className="title">Card title</div>
      <div className="subtitle">Secondary text</div>
    </div>
  </CardPrimaryAction>
</Card>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="supplemental-actions">
              <VariantHeader>
                <VariantHeadline>Supplemental Actions</VariantHeadline>
                <VariantDescription>
                  Supplemental actions are represented by icons, text, and UI
                  controls on cards. They are typically placed at the bottom of
                  the card.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Card className="demo-item demo-item--card demo-item--fullwidth">
                    <CardPrimaryAction>
                      <div className="primary-title">
                        <div className="title">Card title</div>
                        <div className="subtitle">Secondary text</div>
                      </div>
                    </CardPrimaryAction>
                    <CardActions>
                      <CardActionButtons>
                        <CardActionButton label="Action 1" />
                        <CardActionButton label="Action 2" />
                      </CardActionButtons>
                      <CardActionIcons>
                        <CardActionIcon icon="favorite" />
                        <CardActionIcon icon="share" />
                      </CardActionIcons>
                    </CardActions>
                  </Card>
                </Demo>
                <Code>
                  {`<Card>
  <CardPrimaryAction>
    <CardMedia className="rich-media" sixteenByNine />
    <div className="primary-title">
      <div className="title">Card title</div>
      <div className="subtitle">Secondary text</div>
    </div>
  </CardPrimaryAction>
  <CardActions>
    <CardActionButtons>
      <CardActionButton label="Action 1" />
      <CardActionButton label="Action 2" />
    </CardActionButtons>
    <CardActionIcons>
      <CardActionIcon icon="favorite" />
      <CardActionIcon icon="share" />
    </CardActionIcons>
  </CardActions>
</Card>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="rich-media-card">
              <VariantHeader>
                <VariantHeadline>Rich Media</VariantHeadline>
                <VariantDescription>
                  This area is used for showing rich media in cards, and
                  optionally as a container.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Card className="demo-item demo-item--card demo-item--fullwidth">
                    <CardPrimaryAction>
                      <CardMedia className="rich-media" sixteenByNine />
                      <div className="primary-title">
                        <div className="title">Card title</div>
                        <div className="subtitle">Secondary text</div>
                      </div>
                    </CardPrimaryAction>
                    <CardActions>
                      <CardActionButtons>
                        <CardActionButton label="Action 1" />
                        <CardActionButton label="Action 2" />
                      </CardActionButtons>
                      <CardActionIcons>
                        <CardActionIcon icon="favorite" />
                        <CardActionIcon icon="share" />
                      </CardActionIcons>
                    </CardActions>
                  </Card>
                </Demo>
                <Code>
                  {`<Card>
  <CardPrimaryAction>
    <CardMedia className="rich-media" sixteenByNine />
    <div className="primary-title">
      <div className="title">Card title</div>
      <div className="subtitle">Secondary text</div>
    </div>
  </CardPrimaryAction>
  <CardActions>
    <CardActionButtons>
      <CardActionButton label="Action 1" />
      <CardActionButton label="Action 2" />
    </CardActionButtons>
    <CardActionIcons>
      <CardActionIcon icon="favorite" />
      <CardActionIcon icon="share" />
    </CardActionIcons>
  </CardActions>
</Card>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="Rich Media Content">
              <VariantHeader>
                <VariantHeadline>Rich Media Content</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Card className="demo-item demo-item--card demo-item--fullwidth">
                    <CardPrimaryAction>
                      <CardMedia className="rich-media" sixteenByNine>
                        <CardMediaContent className="rich-media__content">
                          <div className="primary-title">
                            <div className="title">Card title</div>
                            <div className="subtitle">Secondary text</div>
                          </div>
                        </CardMediaContent>
                      </CardMedia>
                    </CardPrimaryAction>
                    <CardActions>
                      <CardActionButtons>
                        <CardActionButton label="Action 1" />
                        <CardActionButton label="Action 2" />
                      </CardActionButtons>
                      <CardActionIcons>
                        <CardActionIcon icon="favorite" />
                        <CardActionIcon icon="share" />
                      </CardActionIcons>
                    </CardActions>
                  </Card>
                </Demo>
                <Code>
                  {`<Card>
  <CardPrimaryAction>
    <CardMedia className="rich-media" sixteenByNine>
      <CardMediaContent className="rich-media__content">
        <div className="primary-title">
          <div className="title">Card title</div>
          <div className="subtitle">Secondary text</div>
        </div>
      </CardMediaContent>
    </CardMedia>
  </CardPrimaryAction>
  <CardActions>
    <CardActionButtons>
      <CardActionButton label="Action 1" />
      <CardActionButton label="Action 2" />
    </CardActionButtons>
    <CardActionIcons>
      <CardActionIcon icon="favorite" />
      <CardActionIcon icon="share" />
    </CardActionIcons>
  </CardActions>
</Card>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="header">
              <VariantHeader>
                <VariantHeadline>Header</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Card className="demo-item demo-item--card demo-item--fullwidth">
                    <CardPrimaryAction>
                      <div className="header">
                        <div className="thumbnail" />
                        <div className="primary-title">
                          <div className="title">Card title</div>
                          <div className="subtitle">Secondary text</div>
                        </div>
                      </div>
                      <CardMedia className="rich-media" sixteenByNine />
                    </CardPrimaryAction>
                    <CardActions>
                      <CardActionButtons>
                        <CardActionButton label="Action 1" />
                        <CardActionButton label="Action 2" />
                      </CardActionButtons>
                      <CardActionIcons>
                        <CardActionIcon icon="favorite" />
                        <CardActionIcon icon="share" />
                      </CardActionIcons>
                    </CardActions>
                  </Card>
                </Demo>
                <Code>
                  {`<Card>
  <CardPrimaryAction>
    <CardMedia className="rich-media" sixteenByNine />
    <div className="header">
      <div className="thumbnail" />
      <div className="primary-title">
        <div className="title">Card title</div>
        <div className="subtitle">Secondary text</div>
      </div>
    </div>
  </CardPrimaryAction>
  <CardActions>
    <CardActionButtons>
      <CardActionButton label="Action 1" />
      <CardActionButton label="Action 2" />
    </CardActionButtons>
    <CardActionIcons>
      <CardActionIcon icon="favorite" />
      <CardActionIcon icon="share" />
    </CardActionIcons>
  </CardActions>
</Card>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="supporting-text">
              <VariantHeader>
                <VariantHeadline>Supporting Text</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Card className="demo-item demo-item--card demo-item--fullwidth">
                    <CardPrimaryAction>
                      <CardMedia className="rich-media" sixteenByNine />
                      <div className="primary-title">
                        <div className="title">Card title</div>
                        <div className="subtitle">Secondary text</div>
                      </div>
                      <div className="supporting-text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </div>
                    </CardPrimaryAction>
                    <CardActions>
                      <CardActionButtons>
                        <CardActionButton label="Action 1" />
                        <CardActionButton label="Action 2" />
                      </CardActionButtons>
                      <CardActionIcons>
                        <CardActionIcon icon="favorite" />
                        <CardActionIcon icon="share" />
                      </CardActionIcons>
                    </CardActions>
                  </Card>
                </Demo>
                <Code>
                  {`<Card>
  <CardPrimaryAction>
    <CardMedia className="rich-media" sixteenByNine />
    <div className="primary-title">
      <div className="title">Card title</div>
      <div className="subtitle">Secondary text</div>
    </div>
    <div className="supporting-text">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    </div>
  </CardPrimaryAction>
  <CardActions>
    <CardActionButtons>
      <CardActionButton label="Action 1" />
      <CardActionButton label="Action 2" />
    </CardActionButtons>
    <CardActionIcons>
      <CardActionIcon icon="favorite" />
      <CardActionIcon icon="share" />
    </CardActionIcons>
  </CardActions>
</Card>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>Card</PropsHeadline>
              <PropsTable
                body={[
                  props.CHILDREN,
                  props.CLASS_NAME,
                  props.OUTLINED,
                  props.TAG_DIV,
                ]}
              />
            </Props>
            <Props>
              <PropsHeadline>CardPrimaryAction</PropsHeadline>
              <PropsTable
                body={[props.CHILDREN, props.CLASS_NAME, props.TAG_DIV]}
              />
            </Props>
            <Props>
              <PropsHeadline>CardMedia</PropsHeadline>
              <PropsTable
                body={[
                  [
                    'backgroundImage',
                    types.STRING,
                    'Path to the image of the root element.',
                  ],
                  props.CHILDREN,
                  props.CLASS_NAME,
                  [
                    'sixteenByNine',
                    types.BOOLEAN,
                    'Scales the height of the image maintaining a 16:9 aspect ratio.',
                  ],
                  [
                    'square',
                    types.BOOLEAN,
                    'Scales the height of the image to be equal to the width of the image.',
                  ],
                  props.TAG_DIV,
                ]}
              />
            </Props>
            <Props>
              <PropsHeadline>CardMediaContent</PropsHeadline>
              <PropsTable
                body={[props.CHILDREN, props.CLASS_NAME, props.TAG_DIV]}
              />
            </Props>
            <Props>
              <PropsHeadline>CardActions</PropsHeadline>
              <PropsTable
                body={[
                  props.CHILDREN,
                  props.CLASS_NAME,
                  [
                    'fullBleed',
                    types.BOOLEAN,
                    'Enables full bleed card actions row by removing all padding.',
                  ],
                  props.TAG_DIV,
                ]}
              />
            </Props>
            <Props>
              <PropsHeadline>CardActionButtons</PropsHeadline>
              <PropsTable
                body={[props.CHILDREN, props.CLASS_NAME, props.TAG_DIV]}
              />
            </Props>
            <Props>
              <PropsHeadline>CardActionButton</PropsHeadline>
              <PropsTable
                body={[props.CHILDREN, props.CLASS_NAME, props.TAG_DIV]}
              />
            </Props>
            <Props>
              <PropsHeadline>CardActionIcons</PropsHeadline>
              <PropsTable
                body={[props.CHILDREN, props.CLASS_NAME, props.TAG_DIV]}
              />
            </Props>
            <Props>
              <PropsHeadline>CardActionIcon</PropsHeadline>
              <PropsTable
                body={[props.CHILDREN, props.CLASS_NAME, props.TAG_DIV]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}
