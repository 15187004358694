import React from 'react';
import classNames from 'classnames';
import './props.scss';

export function Props({ children, className, ...otherProps }) {
  return (
    <div className={classNames('props', className)} {...otherProps}>
      {children}
    </div>
  );
}

export function PropsHeadline({ children, className, ...otherProps }) {
  return (
    <div className={classNames('props__headline', className)} {...otherProps}>
      {children}
    </div>
  );
}

const header = ['Name', 'Type', 'Description'];
export function PropsTable({ body }) {
  return (
    <table className="props__table">
      <thead>
        <tr>
          {header.map((item) => (
            <th key={item}>{item}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {body.map((row, rowIndex) => {
          return (
            <tr key={`${body[rowIndex][0]}`}>
              {row.map((col, colIndex) => {
                return (
                  <td key={`${body[rowIndex][0]}-${header[colIndex]}`}>
                    {col}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
