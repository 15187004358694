import React from 'react';
import classNames from 'classnames';
import './demo.scss';

export function Demo({ button, card, children, className, ...otherProps }) {
  return (
    <div className={classNames('demo', className)} {...otherProps}>
      {children}
    </div>
  );
}

export function DemoSection({ button, children, className, ...otherProps }) {
  return (
    <div
      className={classNames('demo__section', className, {
        'demo__section--button': button,
      })}
      {...otherProps}
    >
      {children}
    </div>
  );
}
