import React, { useState } from 'react';
import { Button } from '@arterial/button';
import { Card } from '@arterial/card';
import Code from '../components/code';
import {
  Dialog,
  DialogActions,
  DialogButton,
  DialogContent,
  DialogTitle,
  AlertDialog,
  ConfirmationDialog,
  SimpleDialog,
} from '@arterial/dialog';
import { Demo } from '../components/demo';
import { List, ListItem, ListItemGraphic, ListItemText } from '@arterial/list';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsTable, PropsHeadline } from '../components/props';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
  VariantDescription,
} from '../components/variant';
import { props, types } from '../constants';

export default function Dialogs() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Dialogs</PageHeadline>
          <PageDescription>
            Dialogs inform users about a task and can contain critical
            information, require decisions, or involve multiple tasks.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/dialog</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[
                    { name: 'dialog', value: '@material/dialog/index.scss' },
                  ]}
                  mixins={[{ name: 'dialog', values: ['core-styles'] }]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>import "@material/dialog/dist/mdc.dialog.css";</Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { Dialog, DialogActions, DialogButton, ... } from '@arterial/dialog';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="alert-dialog">
              <VariantHeader>
                <VariantHeadline>Alert Dialog</VariantHeadline>
                <VariantDescription>
                  Alert dialogs interrupt users with urgent information,
                  details, or actions.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <AlertDialogDemo />
                  </div>
                </Demo>
                <Code>
                  {`function AlertDialogDemo() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button label="Open Alert" onClick={() => setOpen(true)} unelevated />
      <AlertDialog
        title="Alert"
        content="Discard draft?"
        confirmingButtonLabel="Discard"
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="simple-dialog">
              <VariantHeader>
                <VariantHeadline>Simple Dialog</VariantHeadline>
                <VariantDescription>
                  Simple dialogs can display items that are immediately
                  actionable when selected. They don’t have text buttons.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <SimpleDialogDemo />
                  </div>
                </Demo>
                <Code>
                  {`function SimpleDialogDemo() {
  const [open, setOpen] = useState(false);
  function handleClose() {
    setOpen(false);
  }
  return (
    <>
      <Button label="Open Dialog" onClick={() => setOpen(true)} unelevated />
      <SimpleDialog
        title="Select an account"
        content={
          <List>
            <ListItem onClick={handleClose}>
              <ListItemGraphic graphic="person" />
              <ListItemText>user1@example.com</ListItemText>
            </ListItem>
            <ListItem onClick={handleClose}>
              <ListItemGraphic graphic="person" />
              <ListItemText>user2@example.com</ListItemText>
            </ListItem>
            <ListItem onClick={handleClose}>
              <ListItemGraphic graphic="add" />
              <ListItemText>Add account</ListItemText>
            </ListItem>
          </List>
        }
        onClose={handleClose}
        open={open}
      />
    </>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="confirmation-dialog">
              <VariantHeader>
                <VariantHeadline>Confirmation Dialog</VariantHeadline>
                <VariantDescription>
                  Confirmation dialogs give users the ability to provide final
                  confirmation of a choice before committing to it, so they have
                  a chance to change their minds if necessary.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <ConfirmationDialogDemo />
                  </div>
                </Demo>
                <Code>
                  {`function ConfirmationDialogDemo() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button label="Open Dialog" onClick={() => setOpen(true)} unelevated />
      <ConfirmationDialog
        title="Reset settings?"
        content="This will reset your device to its default factory settings."
        confirmingButtonLabel="Accept"
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle id="other-variants">Other Variants</PageSubtitle>
            <Variant id="scrollable">
              <VariantHeader>
                <VariantHeadline>Scrollable</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Scrollable />
                  </div>
                </Demo>
                <Code>
                  {`function Scrollable() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        label="Open Scrollable"
        onClick={() => setOpen(true)}
        unelevated
      />
      <Dialog onClose={() => setOpen(false)} open={open} scrollable>
        <DialogTitle>The Wonderful Wizard of Oz</DialogTitle>
        <DialogContent>...</DialogContent>
        <DialogActions>
          <DialogButton label="Decline" onSelect={() => setOpen(false)} />
          <DialogButton label="Accept" onSelect={() => setOpen(false)} />
        </DialogActions>
      </Dialog>
    </>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="stacked-actions">
              <VariantHeader>
                <VariantHeadline>Stacked Actions</VariantHeadline>
                <VariantDescription>
                  Stacked buttons accommodate longer button text. Confirming
                  actions appear above dismissive actions.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Stacked />
                  </div>
                </Demo>
                <Code>
                  {`function Stacked() {
  const [open, setOpen] = useState(false);
  function handleClose(action) {
    if (action === 'ok') {
      // Turn on speed boost
    }
    setOpen(false);
  }
  return (
    <>
      <Button label="Open Stacked" onClick={() => setOpen(true)} unelevated />
      <Dialog open={open} onClose={handleClose} stacked>
        <DialogTitle>Use location service?</DialogTitle>
        <DialogContent>...</DialogContent>
        <DialogActions>
          <DialogButton
            action="ok"
            label="Turn on speed boost"
            onSelect={handleClose}
          />
          <DialogButton label="No thanks" onSelect={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>Dialog</PropsHeadline>
              <PropsTable
                body={[
                  props.CHILDREN,
                  props.CLASS_NAME,
                  props.ON_CLOSE,
                  props.OPEN,
                  [
                    'scrollable',
                    types.BOOLEAN,
                    'Indicates whether the dialog content is scrollable.',
                  ],
                  [
                    'stacked',
                    types.BOOLEAN,
                    'Indicates whether the dialog actions are stacked.',
                  ],
                  props.TAG_DIV,
                ]}
              />
            </Props>
            <Props>
              <PropsHeadline>DialogTitle</PropsHeadline>
              <PropsTable
                body={[
                  props.CHILDREN,
                  props.CLASS_NAME,
                  props.ID,
                  props.TAG_DIV,
                ]}
              />
            </Props>
            <Props>
              <PropsHeadline>DialogContent</PropsHeadline>
              <PropsTable
                body={[
                  props.CHILDREN,
                  props.CLASS_NAME,
                  props.ID,
                  props.TAG_DIV,
                ]}
              />
            </Props>
            <Props>
              <PropsHeadline>DialogActions</PropsHeadline>
              <PropsTable
                body={[props.CHILDREN, props.CLASS_NAME, props.TAG_DIV]}
              />
            </Props>
            <Props>
              <PropsHeadline>DialogButton</PropsHeadline>
              <PropsTable
                body={[
                  [
                    'action',
                    types.STRING,
                    'Action to be passed as an argument for onClose event handler.',
                  ],
                  props.CLASS_NAME,
                  props.ON_SELECT,
                ]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}

function AlertDialogDemo() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button label="Open Alert" onClick={() => setOpen(true)} unelevated />
      <AlertDialog
        title="Alert"
        content="Discard draft?"
        confirmingButtonLabel="Discard"
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
}

function SimpleDialogDemo() {
  const [open, setOpen] = useState(false);
  function handleClose() {
    setOpen(false);
  }
  return (
    <>
      <Button label="Open Simple" onClick={() => setOpen(true)} unelevated />
      <SimpleDialog
        title="Select an account"
        content={
          <List>
            <ListItem onClick={handleClose}>
              <ListItemGraphic graphic="person" />
              <ListItemText>user1@example.com</ListItemText>
            </ListItem>
            <ListItem onClick={handleClose}>
              <ListItemGraphic graphic="person" />
              <ListItemText>user2@example.com</ListItemText>
            </ListItem>
            <ListItem onClick={handleClose}>
              <ListItemGraphic graphic="add" />
              <ListItemText>Add account</ListItemText>
            </ListItem>
          </List>
        }
        onClose={handleClose}
        open={open}
      />
    </>
  );
}

function ConfirmationDialogDemo() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        label="Open Confirmation"
        onClick={() => setOpen(true)}
        unelevated
      />
      <ConfirmationDialog
        title="Reset settings?"
        content="This will reset your device to its default factory settings."
        confirmingButtonLabel="Accept"
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
}

function Scrollable() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        label="Open Scrollable"
        onClick={() => setOpen(true)}
        unelevated
      />
      <Dialog onClose={() => setOpen(false)} open={open} scrollable>
        <DialogTitle>The Wonderful Wizard of Oz</DialogTitle>
        <DialogContent>
          <p>
            <a
              href="https://www.gutenberg.org/ebooks/55"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read the full book
            </a>
          </p>
          <p>
            Dorothy lived in the midst of the great Kansas prairies, with Uncle
            Henry, who was a farmer, and Aunt Em, who was the farmer's wife.
            Their house was small, for the lumber to build it had to be carried
            by wagon many miles. There were four walls, a floor and a roof,
            which made one room; and this room contained a rusty looking
            cookstove, a cupboard for the dishes, a table, three or four chairs,
            and the beds. Uncle Henry and Aunt Em had a big bed in one corner,
            and Dorothy a little bed in another corner. There was no garret at
            all, and no cellar--except a small hole dug in the ground, called a
            cyclone cellar, where the family could go in case one of those great
            whirlwinds arose, mighty enough to crush any building in its path.
            It was reached by a trap door in the middle of the floor, from which
            a ladder led down into the small, dark hole.
          </p>
          <p>
            When Dorothy stood in the doorway and looked around, she could see
            nothing but the great gray prairie on every side. Not a tree nor a
            house broke the broad sweep of flat country that reached to the edge
            of the sky in all directions. The sun had baked the plowed land into
            a gray mass, with little cracks running through it. Even the grass
            was not green, for the sun had burned the tops of the long blades
            until they were the same gray color to be seen everywhere. Once the
            house had been painted, but the sun blistered the paint and the
            rains washed it away, and now the house was as dull and gray as
            everything else.
          </p>
          <p>
            When Aunt Em came there to live she was a young, pretty wife. The
            sun and wind had changed her, too. They had taken the sparkle from
            her eyes and left them a sober gray; they had taken the red from her
            cheeks and lips, and they were gray also. She was thin and gaunt,
            and never smiled now. When Dorothy, who was an orphan, first came to
            her, Aunt Em had been so startled by the child's laughter that she
            would scream and press her hand upon her heart whenever Dorothy's
            merry voice reached her ears; and she still looked at the little
            girl with wonder that she could find anything to laugh at.
          </p>
          <p>
            Uncle Henry never laughed. He worked hard from morning till night
            and did not know what joy was. He was gray also, from his long beard
            to his rough boots, and he looked stern and solemn, and rarely
            spoke.
          </p>
          <p>
            It was Toto that made Dorothy laugh, and saved her from growing as
            gray as her other surroundings. Toto was not gray; he was a little
            black dog, with long silky hair and small black eyes that twinkled
            merrily on either side of his funny, wee nose. Toto played all day
            long, and Dorothy played with him, and loved him dearly.
          </p>
          <p>
            Today, however, they were not playing. Uncle Henry sat upon the
            doorstep and looked anxiously at the sky, which was even grayer than
            usual. Dorothy stood in the door with Toto in her arms, and looked
            at the sky too. Aunt Em was washing the dishes.
          </p>
          <p>
            From the far north they heard a low wail of the wind, and Uncle
            Henry and Dorothy could see where the long grass bowed in waves
            before the coming storm. There now came a sharp whistling in the air
            from the south, and as they turned their eyes that way they saw
            ripples in the grass coming from that direction also.
          </p>
        </DialogContent>
        <DialogActions>
          <DialogButton label="Decline" onSelect={() => setOpen(false)} />
          <DialogButton
            action="accept"
            label="Accept"
            onSelect={() => setOpen(false)}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

function Stacked() {
  const [open, setOpen] = useState(false);
  function handleClose(action) {
    if (action === 'ok') {
      // Turn on speed boost
    }
    setOpen(false);
  }
  return (
    <>
      <Button label="Open Stacked" onClick={() => setOpen(true)} unelevated />
      <Dialog open={open} onClose={handleClose} stacked>
        <DialogTitle>Use location service?</DialogTitle>
        <DialogContent>
          Let us help apps determine
          <br /> location. This means sending
          <br /> anonymous location data to us,
          <br /> even when no apps are
          <br /> running.
        </DialogContent>
        <DialogActions>
          <DialogButton
            action="ok"
            label="Turn on speed boost"
            onSelect={handleClose}
          />
          <DialogButton label="No thanks" onSelect={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
}
