import React, { useState } from 'react';
import { Button } from '@arterial/button';
import { Card } from '@arterial/card';
import { CircularProgress } from '@arterial/circular-progress';
import Code from '../components/code';
import { Demo } from '../components/demo';
import { Icon } from '@arterial/icon';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsTable, PropsHeadline } from '../components/props';
import { Select, HelperText } from '@arterial/select';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
  VariantDescription,
} from '../components/variant';
import { props, types } from '../constants';

export default function Selects() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Selects</PageHeadline>
          <PageDescription>
            Select menus display the currently selected menu item above the
            menu.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/select</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[
                    {
                      name: 'floating-label',
                      value: '@material/floating-label/index.scss',
                    },
                    {
                      name: 'line-ripple',
                      value: '@material/line-ripple/index.scss',
                    },
                    {
                      name: 'notched-outline',
                      value: '@material/notched-outline/index.scss',
                    },
                    {
                      name: 'select-helper-text',
                      value: '@material/select/helper-text/index.scss',
                    },
                    {
                      name: 'select-icon',
                      value: '@material/select/icon/index.scss',
                    },
                    { name: 'select', value: '@material/select/index.scss' },
                  ]}
                  mixins={[
                    { name: 'floating-label', values: ['core-styles'] },
                    { name: 'line-ripple', values: ['core-styles'] },
                    { name: 'notched-outline', values: ['core-styles'] },
                    {
                      name: 'select-helper-text',
                      values: ['helper-text-core-styles'],
                    },
                    { name: 'select-icon', values: ['icon-core-styles'] },
                    { name: 'select', values: ['core-styles'] },
                  ]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>import "@material/select/dist/mdc.select.css";</Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { Select, HelperText } from '@arterial/select';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="filled-select">
              <VariantHeader>
                <VariantHeadline>Filled Select</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Filled />
                  </div>
                </Demo>
                <Code>
                  {`const OPTIONS = [
  { text: 'Apple', value: 'apple' },
  { text: 'Banana', value: 'banana' },
  { text: 'Orange', value: 'orange' },
];
function Filled() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="Filled"
      label="Filled"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="outlined-select">
              <VariantHeader>
                <VariantHeadline>Outlined Select</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Outlined />
                  </div>
                </Demo>
                <Code>
                  {`const OPTIONS = [
  { text: 'Apple', value: 'apple' },
  { text: 'Banana', value: 'banana' },
  { text: 'Orange', value: 'orange' },
];
function Outlined() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="Outlined"
      label="Outlined"
      onSelect={handleSelect}
      options={OPTIONS}
      outlined
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle id="other-variants">Other Variants</PageSubtitle>
            <Variant id="leading-icon">
              <VariantHeader>
                <VariantHeadline>Leading Icon</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <LeadingIcon />
                  </div>
                </Demo>
                <Code>
                  {`const OPTIONS = [
  { text: 'Apple', value: 'apple' },
  { text: 'Banana', value: 'banana' },
  { text: 'Orange', value: 'orange' },
];
function LeadingIcon() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      icon="restaurant_menu"
      id="filled-leading-icon"
      label="Filled"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="invalid">
              <VariantHeader>
                <VariantHeadline>Invalid</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Invalid />
                  </div>
                </Demo>
                <Code>
                  {`const OPTIONS = [
  { text: 'Apple', value: 'apple' },
  { text: 'Banana', value: 'banana' },
  { text: 'Orange', value: 'orange' },
];
function Invalid() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-invalid"
      invalid
      label="Filled"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="label-floating">
              <VariantHeader>
                <VariantHeadline>Label Floating</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <LabelFloating />
                  </div>
                </Demo>
                <Code>
                  {`const OPTIONS = [
  { text: 'Apple', value: 'apple' },
  { text: 'Banana', value: 'banana' },
  { text: 'Orange', value: 'orange' },
];
function LabelFloating() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-label-floating"
      label="Filled"
      labelFloating
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="menu-width">
              <VariantHeader>
                <VariantHeadline>Menu Width</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <MenuWidth />
                  </div>
                </Demo>
                <Code>
                  {`const OPTIONS = [
  { text: 'Apple', value: 'apple' },
  { text: 'Banana', value: 'banana' },
  { text: 'Orange', value: 'orange' },
];
function MenuWidth() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-menu-width"
      label="Filled"
      menuWidth="100%"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="no-label">
              <VariantHeader>
                <VariantHeadline>No Label</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <NoLabel />
                  </div>
                </Demo>
                <Code>
                  {`const OPTIONS = [
  { text: 'Apple', value: 'apple' },
  { text: 'Banana', value: 'banana' },
  { text: 'Orange', value: 'orange' },
];
function NoLabel() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-no-label"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="placeholder">
              <VariantHeader>
                <VariantHeadline>Placeholder</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Placeholder />
                  </div>
                </Demo>
                <Code>
                  {`const OPTIONS = [
  { text: 'Apple', value: 'apple' },
  { text: 'Banana', value: 'banana' },
  { text: 'Orange', value: 'orange' },
];
function Placeholder() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-placeholder"
      label="Filled"
      onSelect={handleSelect}
      options={OPTIONS}
      placeholder="Placeholder"
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="required">
              <VariantHeader>
                <VariantHeadline>Required</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Required />
                  </div>
                </Demo>
                <Code>
                  {`const OPTIONS = [
  { text: 'Apple', value: 'apple' },
  { text: 'Banana', value: 'banana' },
  { text: 'Orange', value: 'orange' },
];
function Required() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-required"
      label="Filled"
      onSelect={handleSelect}
      options={OPTIONS}
      required
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="loader">
              <VariantHeader>
                <VariantHeadline>Loader</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Loader />
                  </div>
                </Demo>
                <Code>
                  {`const OPTIONS = [
  { text: 'Apple', value: 'apple' },
  { text: 'Banana', value: 'banana' },
  { text: 'Orange', value: 'orange' },
];
function Loader() {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <>
      <Button
        label="Start Loader"
        onClick={() => {
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
          }, 5000);
        }}
        style={{ marginBottom: '8px' }}
      />
      <Select
        disabled={loading}
        id="filled-loader"
        label="Filled"
        labelFloating
        onSelect={handleSelect}
        options={OPTIONS}
        placeholder={loading ? 'Loading...' : null}
        trailingIcon={loading ? <CircularProgress small /> : null}
        value={value}
      />
    </>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="disabled">
              <VariantHeader>
                <VariantHeadline>Disabled</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Disabled />
                  </div>
                </Demo>
                <Code>
                  {`const OPTIONS = [
  { text: 'Apple', value: 'apple' },
  { text: 'Banana', value: 'banana' },
  { text: 'Orange', value: 'orange' },
];
function Disabled() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      disabled
      id="filled-disabled"
      label="Filled"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="helper-text-object">
              <VariantHeader>
                <VariantHeadline>Helper Text Object</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <HelperTextObject />
                  </div>
                </Demo>
                <Code>
                  {`const OPTIONS = [
  { text: 'Apple', value: 'apple' },
  { text: 'Banana', value: 'banana' },
  { text: 'Orange', value: 'orange' },
];
function HelperTextObject() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      helperText={{
        persistent: true,
        validationMsg: true,
        text: 'Helper text as object.',
      }}
      id="filled-helper-text-object"
      label="Filled"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="helper-text-component">
              <VariantHeader>
                <VariantHeadline>Helper Text Component</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <HelperTextComponent />
                  </div>
                </Demo>
                <Code>
                  {`const OPTIONS = [
  { text: 'Apple', value: 'apple' },
  { text: 'Banana', value: 'banana' },
  { text: 'Orange', value: 'orange' },
];
function HelperTextComponent() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-helper-text-component"
      label="Filled"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="pre-selected-option">
              <VariantHeader>
                <VariantHeadline>Pre-selected Option</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <PreSelected />
                  </div>
                </Demo>
                <Code>
                  {`const OPTIONS = [
  { text: 'Apple', value: 'apple' },
  { text: 'Banana', value: 'banana' },
  { text: 'Orange', value: 'orange' },
];
function PreSelected() {
  const [value, setValue] = useState('banana');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-pre-selected"
      label="Filled"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="disabled-options">
              <VariantHeader>
                <VariantHeadline>Disabled Options</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <DisabledOptions />
                  </div>
                </Demo>
                <Code>
                  {`const DISABLED_OPTIONS = [
  { text: 'Apple', value: 'apple', disabled: true },
  { text: 'Banana', value: 'banana' },
  { text: 'Orange', value: 'orange' },
];
function DisabledOptions() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-disabled-options"
      label="Filled"
      onSelect={handleSelect}
      options={DISABLED_OPTIONS}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="enhanced-options">
              <VariantHeader>
                <VariantHeadline>Enhanced Options</VariantHeadline>
                <VariantDescription>
                  The option object in the options array must have{' '}
                  <code className="inline-code">text</code> and{' '}
                  <code className="inline-code">value</code> props. The option
                  object also accepts{' '}
                  <code className="inline-code">disabled</code>,{' '}
                  <code className="inline-code">graphic</code>,{' '}
                  <code className="inline-code">node</code>, and{' '}
                  <code className="inline-code">meta</code> props. The{' '}
                  <code className="inline-code">graphic</code> and{' '}
                  <code className="inline-code">meta</code> props will appear as
                  they do on a list item component. The{' '}
                  <code className="inline-code">node</code> prop is for any
                  element/elements to be displayed to right of list item text.
                  <br />
                  You can use <code className="inline-code">
                    selectedText
                  </code>{' '}
                  to convert an <code className="inline-code">node</code> that
                  displays an icon into text when the option is selected. For
                  example if you have a lock icon in your option, then you can
                  use <code className="inline-code">selectedText</code> to make
                  it appear as '(Private)'.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <EnhancedOptions />
                  </div>
                </Demo>
                <Code>
                  {`const ENHANCED_OPTIONS = [
  { text: 'Apple', value: 'apple' },
  { text: 'Banana', value: 'banana' },
  {
    text: 'Orange',
    value: 'orange',
    node: (
      <Icon
        icon="favorite"
        style={{ color: 'rgba(0,0,0,.38)', marginLeft: '8px' }}
      />
    ),
    selectedText: 'Orange (Favorite)',
  },
];
function EnhancedOptions() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-enhanced-options"
      label="Filled"
      onSelect={handleSelect}
      options={ENHANCED_OPTIONS}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>Select</PropsHeadline>
              <PropsTable
                body={[
                  props.CHILDREN,
                  props.CLASS_NAME,
                  props.DISABLED,
                  [
                    'helperText',
                    types.NODE,
                    'Gives context about a select, such as how the selection will be used.',
                  ],
                  props.ICON,
                  props.ID,
                  [
                    'invalid',
                    types.BOOLEAN,
                    'Indicates the select is invalid.',
                  ],
                  props.LABEL,
                  [
                    'labelFloating',
                    types.BOOLEAN,
                    'Indicates whether the elements label is floating.',
                  ],
                  [
                    'menuWidth',
                    types.STRING,
                    'Sets the menu width of the select.',
                  ],
                  props.ON_SELECT,
                  [
                    'options',
                    `${types.OBJECT}*`,
                    'Items to be displayed as a menu list.',
                  ],
                  props.OUTLINED,
                  [
                    'placeholder',
                    types.STRING,
                    'Text to be displayed in the select when it has no value set.',
                  ],
                  [
                    'required',
                    types.BOOLEAN,
                    'Indicates whether the select is required.',
                  ],
                  props.STYLE,
                  props.TRAILING_ICON,
                  props.VALUE,
                ]}
              />
              <blockquote>
                <span style={{ fontWeight: 500 }}>NOTE:</span> Options shape is
                &#123; disabled: <code>{types.BOOLEAN}</code>, graphic:{' '}
                <code>{types.NODE}</code>, meta: <code>{types.NODE}</code>,
                node: <code>{types.NODE}</code>, secondaryText:{' '}
                <code>{types.STRING}</code>, selectedText:{' '}
                <code>{types.STRING}</code>, text: <code>{types.STRING}</code>,
                value: <code>{types.STRING}</code> &#125;
              </blockquote>
            </Props>
            <Props>
              <PropsHeadline>HelperText</PropsHeadline>
              <PropsTable
                body={[
                  props.CLASS_NAME,
                  props.ID,
                  [
                    'persistent',
                    types.BOOLEAN,
                    'Makes the helper text permanently visible.',
                  ],
                  [
                    'validationMsg',
                    types.BOOLEAN,
                    'Indicates the helper text is a validation message.',
                  ],
                  ['text', types.STRING, 'Text to be displayed.'],
                ]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}

const OPTIONS = [
  { text: 'Apple', value: 'apple' },
  { text: 'Banana', value: 'banana' },
  { text: 'Orange', value: 'orange' },
];
function Filled() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled"
      label="Filled"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}

function Outlined() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="outlined"
      label="Outlined"
      onSelect={handleSelect}
      options={OPTIONS}
      outlined
      value={value}
    />
  );
}

function LeadingIcon() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      icon="restaurant_menu"
      id="filled-leading-icon"
      label="Filled"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}

function Invalid() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-invalid"
      invalid
      label="Filled"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}

function LabelFloating() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-label-floating"
      label="Filled"
      labelFloating
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}

function MenuWidth() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-menu-width"
      label="Filled"
      menuWidth="100%"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}

function NoLabel() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-no-label"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}

function Placeholder() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-placeholder"
      label="Filled"
      labelFloating
      onSelect={handleSelect}
      options={OPTIONS}
      placeholder="Placeholder"
      value={value}
    />
  );
}

function Required() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-required"
      label="Filled"
      onSelect={handleSelect}
      options={OPTIONS}
      required
      value={value}
    />
  );
}

function Loader() {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <>
      <Button
        label="Start Loader"
        onClick={() => {
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
          }, 5000);
        }}
        style={{ marginBottom: '8px' }}
      />
      <Select
        disabled={loading}
        id="filled-loader"
        label="Filled"
        labelFloating
        onSelect={handleSelect}
        options={OPTIONS}
        placeholder={loading ? 'Loading...' : null}
        trailingIcon={loading ? <CircularProgress small /> : null}
        value={value}
      />
    </>
  );
}

function Disabled() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      disabled
      id="filled-disabled"
      label="Filled"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}

function HelperTextObject() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      helperText={{
        persistent: true,
        validationMsg: true,
        text: 'Helper text as object.',
      }}
      id="filled-helper-text-object"
      label="Filled"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}

function HelperTextComponent() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-helper-text-component"
      label="Filled"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}

function PreSelected() {
  const [value, setValue] = useState('banana');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-pre-selected"
      label="Filled"
      onSelect={handleSelect}
      options={OPTIONS}
      value={value}
    />
  );
}

const DISABLED_OPTIONS = [
  { text: 'Apple', value: 'apple', disabled: true },
  { text: 'Banana', value: 'banana' },
  { text: 'Orange', value: 'orange' },
];
function DisabledOptions() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-disabled-options"
      label="Filled"
      onSelect={handleSelect}
      options={DISABLED_OPTIONS}
      value={value}
    />
  );
}

const ENHANCED_OPTIONS = [
  { text: 'Apple', value: 'apple' },
  { text: 'Banana', value: 'banana' },
  {
    text: 'Orange',
    value: 'orange',
    node: (
      <Icon
        icon="favorite"
        style={{ color: 'rgba(0,0,0,.38)', marginLeft: '8px' }}
      />
    ),
    selectedText: 'Orange (Favorite)',
  },
];
function EnhancedOptions() {
  const [value, setValue] = useState('');
  function handleSelect(option) {
    setValue(option.value);
  }
  return (
    <Select
      id="filled-enhanced-options"
      label="Filled"
      onSelect={handleSelect}
      options={ENHANCED_OPTIONS}
      value={value}
    />
  );
}
