import React from 'react';
import { Card } from '@arterial/card';
import Code from '../components/code';
import { Demo } from '../components/demo';
import { Fab } from '@arterial/fab';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsTable, PropsHeadline } from '../components/props';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
  VariantDescription,
} from '../components/variant';
import { props, types } from '../constants';

export default function Drawers() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Fabs</PageHeadline>
          <PageDescription>
            A floating action button (FAB) represents the primary action of a
            screen.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/fab</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[{ name: 'fab', value: '@material/fab/index.scss' }]}
                  mixins={[{ name: 'fab', values: ['core-styles'] }]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>import "@material/fab/dist/mdc.fab.css";</Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { Fab } from '@arterial/fab';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="regular-fab">
              <VariantHeader>
                <VariantHeadline>Regular FAB</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Fab icon="favorite" />
                  </div>
                </Demo>
                <Code>{`<Fab icon="favorite" />`}</Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="mini-fab">
              <VariantHeader>
                <VariantHeadline>Mini FAB</VariantHeadline>
                <VariantDescription>
                  A mini FAB should be used on smaller screens. Mini FABs can
                  also be used to create visual continuity with other screen
                  elements.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Fab icon="favorite" mini />
                  </div>
                </Demo>
                <Code>{'<Fab icon="favorite" mini />'}</Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="extended-fab">
              <VariantHeader>
                <VariantHeadline>Extended FAB</VariantHeadline>
                <VariantDescription>
                  The extended FAB is wider, and it includes a text label.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Fab extended icon="add" label="Create" />
                  </div>
                </Demo>
                <Code>{`<Fab extended icon="add" label="Create" />`}</Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle id="other-variants">Other Variants</PageSubtitle>
            <Variant id="trailing-icon">
              <VariantHeader>
                <VariantHeadline>Trailing Icon</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Fab extended label="Create" trailingIcon="add" />
                  </div>
                </Demo>
                <Code>
                  {`<Fab extended label="Create" trailingIcon="add" />`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="text">
              <VariantHeader>
                <VariantHeadline>Text</VariantHeadline>
                <VariantDescription>
                  Unlike standard FABs, extended FABs don't require an icon.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Fab extended label="Create" />
                  </div>
                </Demo>
                <Code>{`<Fab extended label="Create" />`}</Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>Fab</PropsHeadline>
              <PropsTable
                body={[
                  props.CLASS_NAME,
                  ['exited', types.BOOLEAN, 'Animates FAB out of view.'],
                  ['extended', types.BOOLEAN, 'Enables a extended variant.'],
                  props.ICON,
                  props.LABEL,
                  props.OUTLINED,
                  ['mini', types.BOOLEAN, 'Enables a mini variant.'],
                  props.RIPPLE,
                  props.TAG_BUTTON,
                  props.TRAILING_ICON,
                ]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}
