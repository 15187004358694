import React, { useState } from 'react';
import { Button } from '@arterial/button';
import { Card } from '@arterial/card';
import { CircularProgress } from '@arterial/circular-progress';
import Code from '../components/code';
import { Demo } from '../components/demo';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsTable, PropsHeadline } from '../components/props';
import { TextField, HelperText } from '@arterial/textfield';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
  VariantDescription,
} from '../components/variant';
import { props, types } from '../constants';
import { Icon } from '@arterial/icon';

export default function Selects() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Text Fields</PageHeadline>
          <PageDescription>
            Text fields let users enter and edit text.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/textfield</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[
                    {
                      name: 'floating-label',
                      value: '@material/floating-label/index.scss',
                    },
                    {
                      name: 'line-ripple',
                      value: '@material/line-ripple/index.scss',
                    },
                    {
                      name: 'notched-outline',
                      value: '@material/notched-outline/index.scss',
                    },
                    {
                      name: 'textfield-helper-text',
                      value: '@material/textfield/helper-text/index.scss',
                    },
                    {
                      name: 'textfield-character-count',
                      value: '@material/textfield/character-count/index.scss',
                    },
                    {
                      name: 'textfield-icon',
                      value: '@material/textfield/icon/index.scss',
                    },
                    {
                      name: 'textfield',
                      value: '@material/textfield/index.scss',
                    },
                  ]}
                  mixins={[
                    { name: 'floating-label', values: ['core-styles'] },
                    { name: 'line-ripple', values: ['core-styles'] },
                    { name: 'notched-outline', values: ['core-styles'] },
                    {
                      name: 'textfield-helper-text',
                      values: ['helper-text-core-styles'],
                    },
                    {
                      name: 'textfield-character-count',
                      values: ['character-count-core-styles'],
                    },
                    { name: 'textfield-icon', values: ['icon-core-styles'] },
                    { name: 'textfield', values: ['core-styles'] },
                  ]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>
                  import "@material/textfield/dist/mdc.textfield.css";
                </Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { TextField, HelperText } from '@arterial/textfield';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="filled-textfield">
              <VariantHeader>
                <VariantHeadline>Filled Text Field</VariantHeadline>
                <VariantDescription>
                  Filled text fields have more visual emphasis than outlined
                  text fields, making them stand out when surrounded by other
                  content and components.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Filled />
                  </div>
                </Demo>
                <Code>
                  {`function Filled() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      id="filled"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="outlined-textfield">
              <VariantHeader>
                <VariantHeadline>Outlined Text Field</VariantHeadline>
                <VariantDescription>
                  Outlined text fields have less visual emphasis than filled
                  text fields. When they appear in places like forms, where many
                  text fields are placed together, their reduced emphasis helps
                  simplify the layout.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Outlined />
                  </div>
                </Demo>
                <Code>
                  {`function Outlined() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      id="outlined"
      label="Outlined"
      onChange={(e) => setValue(e.target.value)}
      outlined
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle id="other-variants">Other Variants</PageSubtitle>
            <Variant id="helper-text-object">
              <VariantHeader>
                <VariantHeadline>Helper Text Object</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <HelperTextObject />
                  </div>
                </Demo>
                <Code>
                  {`function HelperTextObject() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      helperText={{
        persistent: true,
        validationMsg: true,
        text: 'Helper text as object.',
      }}
      id="filled-helper-text-object"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="helper-text-component">
              <VariantHeader>
                <VariantHeadline>Helper Text Component</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <HelperTextComponent />
                  </div>
                </Demo>
                <Code>
                  {`function HelperTextComponent() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-helper-text-component"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="leading-icon">
              <VariantHeader>
                <VariantHeadline>Leading Icon</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <LeadingIcon />
                  </div>
                </Demo>
                <Code>
                  {`function LeadingIcon() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      icon="calendar_today"
      id="filled-leading-icon"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="trailing-icon">
              <VariantHeader>
                <VariantHeadline>Trailing Icon</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <TrailingIcon />
                  </div>
                </Demo>
                <Code>
                  {`function TrailingIcon() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-trailing-icon"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      trailingIcon="search"
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="trailing-icon-action">
              <VariantHeader>
                <VariantHeadline>Trailing Icon Action</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <TrailingIconAction />
                  </div>
                </Demo>
                <Code>
                  {`function TrailingIconAction() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-trailing-icon"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      onTrailingIconAction={() => setValue('')}
      trailingIcon="delete"
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="invalid">
              <VariantHeader>
                <VariantHeadline>Invalid</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Invalid />
                  </div>
                </Demo>
                <Code>
                  {`function Invalid() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-invalid"
      invalid
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="label-floating">
              <VariantHeader>
                <VariantHeadline>Label Floating</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <LabelFloating />
                  </div>
                </Demo>
                <Code>
                  {`function LabelFloating() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-label-floating"
      label="Filled"
      labelFloating
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="full-width">
              <VariantHeader>
                <VariantHeadline>Full Width</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--fullwidth">
                    <FullWidth />
                  </div>
                </Demo>
                <Code>
                  {`function FullWidth() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      fullwidth
      id="filled-fullwidth"
      onChange={(e) => setValue(e.target.value)}
      placeholder="Filled"
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="no-label">
              <VariantHeader>
                <VariantHeadline>No Label</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <NoLabel />
                  </div>
                </Demo>
                <Code>
                  {`function NoLabel() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-no-label"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="disabled">
              <VariantHeader>
                <VariantHeadline>Disabled</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Disabled />
                  </div>
                </Demo>
                <Code>
                  {`function Disabled() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      disabled
      id="filled-disabled"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="placeholder">
              <VariantHeader>
                <VariantHeadline>Placeholder</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Placeholder />
                  </div>
                </Demo>
                <Code>
                  {`function Placeholder() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-placeholder"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      placeholder="Placeholder"
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="required">
              <VariantHeader>
                <VariantHeadline>Required</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Required />
                  </div>
                </Demo>
                <Code>
                  {`function Required() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-required"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      required
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="loader">
              <VariantHeader>
                <VariantHeadline>Loader</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Loader />
                  </div>
                </Demo>
                <Code>
                  {`function Loader() {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Button
        label="Start Loader"
        onClick={() => {
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
          }, 5000);
        }}
        style={{ marginBottom: '8px' }}
      />
      <Textfield
        disabled={loading}
        helperText={
          <HelperText
            persistent
            validationMsg
            text="Helper text as component."
          />
        }
        id="filled-loader"
        label="Filled"
        labelFloating
        onChange={(e) => setValue(e.target.value)}
        placeholder={loading ? 'Loading...' : null}
        trailingIcon={loading ? <CircularProgress small /> : null}
        value={value}
      />
    </>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="pre-filled">
              <VariantHeader>
                <VariantHeadline>Pre-filled</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <PreFilled />
                  </div>
                </Demo>
                <Code>
                  {`function PreFilled() {
  const [value, setValue] = useState('pre-filled');
  return (
    <Textfield
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-pre-filled"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="prefix">
              <VariantHeader>
                <VariantHeadline>Prefix</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Prefix />
                  </div>
                </Demo>
                <Code>
                  {`function Prefix() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-prefix"
      label="Filled"
      labelFloating
      onChange={(e) => setValue(e.target.value)}
      prefix="$"
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="suffix">
              <VariantHeader>
                <VariantHeadline>Suffix</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Suffix />
                  </div>
                </Demo>
                <Code>
                  {`function Suffix() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-suffix"
      label="Filled"
      labelFloating
      onChange={(e) => setValue(e.target.value)}
      suffix=".00"
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="character-count">
              <VariantHeader>
                <VariantHeadline>Character Count</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <CharacterCount />
                  </div>
                </Demo>
                <Code>
                  {`function CharacterCount() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-suffix"
      label="Filled"
      maxLength={5}
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="text-area">
              <VariantHeader>
                <VariantHeadline>Text Area</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <TextArea />
                  </div>
                </Demo>
                <Code>
                  {`function TextArea() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="textarea"
      onChange={(e) => setValue(e.target.value)}
      textarea
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="text-area-character-count">
              <VariantHeader>
                <VariantHeadline>Text Area Character Count</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <TextAreaCharacterCount />
                  </div>
                </Demo>
                <Code>
                  {`function TextAreaCharacterCount() {
  const [value, setValue] = useState('');
  return (
    <Textfield
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="textarea"
      maxLength={5}
      onChange={(e) => setValue(e.target.value)}
      textarea
      value={value}
    />
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>TextField</PropsHeadline>
              <PropsTable
                body={[
                  props.CHILDREN,
                  props.CLASS_NAME,
                  props.DISABLED,
                  [
                    'helperText',
                    types.NODE,
                    'Gives context about a select, such as how the selection will be used.',
                  ],
                  props.ICON,
                  props.ID,
                  [
                    'invalid',
                    types.BOOLEAN,
                    'Indicates the select is invalid.',
                  ],
                  [
                    'fullwidth',
                    types.STRING,
                    'Sets the text field to full width.',
                  ],
                  props.LABEL,
                  [
                    'labelFloating',
                    types.BOOLEAN,
                    'Indicates whether the elements label is floating.',
                  ],
                  [
                    'maxLength',
                    `${types.STRING} | ${types.NUMBER}`,
                    'Maximum length in characters of the value. Enables character counter.',
                  ],
                  ['noLabel', types.BOOLEAN, 'Enables no label variant.'],
                  props.ON_CHANGE,
                  ['onFocus', types.FUNCTION, 'Focus event handler.'],
                  [
                    'onIconAction',
                    types.FUNCTION,
                    'Icon action event handler.',
                  ],
                  [
                    'onTrailingIconAction',
                    types.FUNCTION,
                    'Trailing icon action event handler.',
                  ],
                  props.OUTLINED,
                  [
                    'placeholder',
                    types.STRING,
                    'Text to be displayed in the select when it has no value.',
                  ],
                  [
                    'prefix',
                    types.STRING,
                    'Text to be displayed before value.',
                  ],
                  props.STYLE,
                  ['suffix', types.STRING, 'Text to be displayed after value.'],
                  ['textarea', types.BOOLEAN, 'Enables text area variant.'],
                  props.TRAILING_ICON,
                  [
                    'type',
                    types.STRING,
                    'Determines type of text field. Defaults to text.',
                  ],
                  props.VALUE,
                ]}
              />
            </Props>
            <Props>
              <PropsHeadline>HelperText</PropsHeadline>
              <PropsTable
                body={[
                  props.CLASS_NAME,
                  props.ID,
                  [
                    'persistent',
                    types.BOOLEAN,
                    'Makes the helper text permanently visible.',
                  ],
                  [
                    'validationMsg',
                    types.BOOLEAN,
                    'Indicates the helper text is a validation message.',
                  ],
                  ['text', types.STRING, 'Text to be displayed.'],
                ]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}

function Filled() {
  const [value, setValue] = useState('');
  return (
    <TextField
      id="filled"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}

function Outlined() {
  const [value, setValue] = useState('');
  return (
    <TextField
      id="outlined"
      label="Outlined"
      onChange={(e) => setValue(e.target.value)}
      outlined
      value={value}
    />
  );
}

function HelperTextObject() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={{
        persistent: true,
        validationMsg: true,
        text: 'Helper text as object.',
      }}
      id="filled-helper-text-object"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}

function HelperTextComponent() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-helper-text-component"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}

function LeadingIcon() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      icon="calendar_today"
      id="filled-leading-icon"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}

function TrailingIcon() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-trailing-icon"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      trailingIcon="search"
      value={value}
    />
  );
}

function TrailingIconAction() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-trailing-icon"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      onTrailingIconAction={() => setValue('')}
      trailingIcon="delete"
      value={value}
    />
  );
}

function Invalid() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-invalid"
      invalid
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}

function LabelFloating() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-label-floating"
      label="Filled"
      labelFloating
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}

function FullWidth() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      fullwidth
      id="filled-fullwidth"
      onChange={(e) => setValue(e.target.value)}
      placeholder="Filled"
      value={value}
    />
  );
}

function NoLabel() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-no-label"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}

function Disabled() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      disabled
      id="filled-disabled"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}

function Placeholder() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-placeholder"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      placeholder="Placeholder"
      value={value}
    />
  );
}

function Required() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-required"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      required
      value={value}
    />
  );
}

function Loader() {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Button
        label="Start Loader"
        onClick={() => {
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
          }, 5000);
        }}
        style={{ marginBottom: '8px' }}
      />
      <div>
        <TextField
          className="mdc-text-field--with-trailing-icon"
          disabled={loading}
          helperText={
            <HelperText
              persistent
              validationMsg
              text="Helper text as component."
            />
          }
          id="filled-loader"
          label="Filled"
          labelFloating
          onChange={(e) => setValue(e.target.value)}
          placeholder={loading ? 'Loading...' : null}
          trailingIcon={
            loading ? (
              <CircularProgress small />
            ) : (
              <Icon icon="clear" style={{ visibility: 'hidden' }} />
            )
          }
          value={value}
        />
      </div>
    </>
  );
}

function PreFilled() {
  const [value, setValue] = useState('pre-filled');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-pre-filled"
      label="Filled"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}

function Prefix() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-prefix"
      label="Filled"
      labelFloating
      onChange={(e) => setValue(e.target.value)}
      prefix="$"
      value={value}
    />
  );
}

function Suffix() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-suffix"
      label="Filled"
      labelFloating
      onChange={(e) => setValue(e.target.value)}
      suffix=".00"
      value={value}
    />
  );
}

function CharacterCount() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="filled-suffix"
      label="Filled"
      maxLength={5}
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}

function TextArea() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="textarea"
      onChange={(e) => setValue(e.target.value)}
      textarea
      value={value}
    />
  );
}

function TextAreaCharacterCount() {
  const [value, setValue] = useState('');
  return (
    <TextField
      helperText={
        <HelperText persistent validationMsg text="Helper text as component." />
      }
      id="textarea"
      maxLength={5}
      onChange={(e) => setValue(e.target.value)}
      textarea
      value={value}
    />
  );
}
