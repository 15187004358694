import React, { useState } from 'react';
import { Button } from '@arterial/button';
import { Card } from '@arterial/card';
import Code from '../components/code';
import { Demo } from '../components/demo';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsTable, PropsHeadline } from '../components/props';
import { Snackbar } from '@arterial/snackbar';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
} from '../components/variant';
import { props, types } from '../constants';

export default function Snackbars() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Snackbars</PageHeadline>
          <PageDescription>
            Snackbars provide brief messages about app processes at the bottom
            of the screen.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/snackbar</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[
                    {
                      name: 'snackbar',
                      value: '@material/snackbar/index.scss',
                    },
                  ]}
                  mixins={[{ name: 'snackbar', values: ['core-styles'] }]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>import "@material/snackbar/dist/mdc.snackbar.css";</Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { Snackbar } from '@arterial/snackbar';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="regular-snackbar">
              <VariantHeader>
                <VariantHeadline>Regular Snackbar</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Regular />
                  </div>
                </Demo>
                <Code>
                  {`function Regular() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button label="Open" onClick={() => setOpen(true)} unelevated />
      <Snackbar
        label="Can't send photo. Retry in 5 seconds."
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle id="other-variants">Other Variants</PageSubtitle>
            <Variant id="dismiss">
              <VariantHeader>
                <VariantHeadline>Dismiss</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Dismiss />
                  </div>
                </Demo>
                <Code>
                  {`function Dismiss() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button label="Open" onClick={() => setOpen(true)} unelevated />
      <Snackbar
        dismiss
        label="Can't send photo. Retry in 5 seconds."
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="action">
              <VariantHeader>
                <VariantHeadline>Action</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Action />
                  </div>
                </Demo>
                <Code>
                  {`function Action() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button label="Open" onClick={() => setOpen(true)} unelevated />
      <Snackbar
        action="Retry"
        label="Can't send photo. Retry in 5 seconds."
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="action-dismiss">
              <VariantHeader>
                <VariantHeadline>Action and Dismiss</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <ActionDismiss />
                  </div>
                </Demo>
                <Code>
                  {`function ActionDismiss() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button label="Open" onClick={() => setOpen(true)} unelevated />
      <Snackbar
        action="Retry"
        dismiss
        label="Can't send photo. Retry in 5 seconds."
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="leading">
              <VariantHeader>
                <VariantHeadline>Leading</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Leading />
                  </div>
                </Demo>
                <Code>
                  {`function Leading() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button label="Open" onClick={() => setOpen(true)} unelevated />
      <Snackbar
        action="Retry"
        dismiss
        label="Can't send photo. Retry in 5 seconds."
        leading
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="stacked">
              <VariantHeader>
                <VariantHeadline>Stacked</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item">
                    <Stacked />
                  </div>
                </Demo>
                <Code>
                  {`function Stacked() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button label="Open" onClick={() => setOpen(true)} unelevated />
      <Snackbar
        action="Retry"
        dismiss
        label="Can't send photo. Retry in 5 seconds."
        onClose={() => setOpen(false)}
        open={open}
        stacked
      />
    </>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>Snackbar</PropsHeadline>
              <PropsTable
                body={[
                  [
                    'action',
                    types.STRING,
                    'Text to be displayed within action button of root element.',
                  ],
                  props.CLASS_NAME,
                  [
                    'dismiss',
                    types.BOOLEAN,
                    'Enables dismiss icon to be displayed within root element.',
                  ],
                  props.LABEL,
                  ['leading', types.BOOLEAN, 'Enables a leading variant.'],
                  props.ON_CLOSE,
                  props.OPEN,
                  ['stacked', types.BOOLEAN, 'Enables a stacked variant.'],
                ]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}

function Regular() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button label="Open" onClick={() => setOpen(true)} unelevated />
      <Snackbar
        label="Can't send photo. Retry in 5 seconds."
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
}

function Dismiss() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button label="Open" onClick={() => setOpen(true)} unelevated />
      <Snackbar
        dismiss
        label="Can't send photo. Retry in 5 seconds."
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
}

function Action() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button label="Open" onClick={() => setOpen(true)} unelevated />
      <Snackbar
        action="Retry"
        label="Can't send photo. Retry in 5 seconds."
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
}

function ActionDismiss() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button label="Open" onClick={() => setOpen(true)} unelevated />
      <Snackbar
        action="Retry"
        dismiss
        label="Can't send photo. Retry in 5 seconds."
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
}

function Leading() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button label="Open" onClick={() => setOpen(true)} unelevated />
      <Snackbar
        action="Retry"
        dismiss
        label="Can't send photo. Retry in 5 seconds."
        leading
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
}

function Stacked() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button label="Open" onClick={() => setOpen(true)} unelevated />
      <Snackbar
        action="Retry"
        dismiss
        label="Can't send photo. Retry in 5 seconds."
        onClose={() => setOpen(false)}
        open={open}
        stacked
      />
    </>
  );
}
