import React, { useState } from 'react';
import { Card } from '@arterial/card';
import Code from '../components/code';
import { Demo } from '../components/demo';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsTable, PropsHeadline } from '../components/props';
import { Radio } from '@arterial/radio';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
} from '../components/variant';
import { props } from '../constants';

export default function Radios() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Radios</PageHeadline>
          <PageDescription>
            Radio buttons allow the user to select one option from a set. Use
            radio buttons when the user needs to see all available options.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/radio</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[
                    { name: 'radio', value: '@material/radio/index.scss' },
                  ]}
                  mixins={[{ name: 'radio', values: ['core-styles'] }]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>import "@material/radio/dist/mdc.radio.css";</Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { Radio } from '@arterial/radio';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="basic-radio">
              <VariantHeader>
                <VariantHeadline>Basic Radio</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Basic />
                </Demo>
                <Code>
                  {`function Basic() {
  const [value, setValue] = useState('yes');
  return (
    <>
      <Radio
        checked={value === 'yes'}
        id="radio-yes"
        name="radios"
        value="yes"
        onChange={(e) => setValue(e.target.value)}
      />
      <Radio
        checked={value === 'no'}
        id="radio-no"
        name="radios"
        value="no"
        onChange={(e) => setValue(e.target.value)}
      />
    </>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Other Variants</PageSubtitle>
            <Variant id="label">
              <VariantHeader>
                <VariantHeadline>Label</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Label />
                </Demo>
                <Code>
                  {`function Label() {
  const [value, setValue] = useState('yes');
  return (
    <>
      <Radio
        checked={value === 'yes'}
        id="radio-label-yes"
        label="Yes"
        name="radios-label"
        value="yes"
        onChange={(e) => setValue(e.target.value)}
      />
      <Radio
        checked={value === 'no'}
        id="radio-label-no"
        label="No"
        name="radios-label"
        value="no"
        onChange={(e) => setValue(e.target.value)}
      />
    </>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="align-end">
              <VariantHeader>
                <VariantHeadline>Align End</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <AlignEnd />
                </Demo>
                <Code>
                  {`function AlignEnd() {
  const [value, setValue] = useState('yes');
  return (
    <>
      <Radio
        alignEnd
        checked={value === 'yes'}
        id="radio-align-end-yes"
        label="Yes"
        name="radios-align-end"
        value="yes"
        onChange={(e) => setValue(e.target.value)}
      />
      <Radio
        alignEnd
        checked={value === 'no'}
        id="radio-align-end-no"
        label="No"
        name="radios-align-end"
        value="no"
        onChange={(e) => setValue(e.target.value)}
      />
    </>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="disabled">
              <VariantHeader>
                <VariantHeadline>Disabled</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <Disabled />
                </Demo>
                <Code>
                  {`function Disabled() {
  const [value, setValue] = useState('yes');
  return (
    <>
      <Radio
        checked={value === 'yes'}
        disabled
        id="radio-disabled-yes"
        label="Yes"
        name="radios-disabled"
        value="yes"
        onChange={(e) => setValue(e.target.value)}
      />
      <Radio
        checked={value === 'no'}
        disabled
        id="radio-disabled-no"
        label="No"
        name="radios-disabled"
        value="no"
        onChange={(e) => setValue(e.target.value)}
      />
    </>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>Radio</PropsHeadline>
              <PropsTable
                body={[
                  props.ALIGN_END,
                  props.CHECKED,
                  props.CLASS_NAME,
                  props.DISABLED,
                  props.ID,
                  props.LABEL_NEXT,
                  props.ON_CHANGE,
                  props.RIPPLE,
                  props.STYLE,
                  props.VALUE,
                ]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}

function Basic() {
  const [value, setValue] = useState('yes');
  return (
    <>
      <div className="demo-item">
        <Radio
          checked={value === 'yes'}
          id="radio-yes"
          name="radios"
          value="yes"
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <div className="demo-item">
        <Radio
          checked={value === 'no'}
          id="radio-no"
          name="radios"
          value="no"
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </>
  );
}

function Label() {
  const [value, setValue] = useState('yes');
  return (
    <>
      <div className="demo-item">
        <Radio
          checked={value === 'yes'}
          id="radio-label-yes"
          label="Yes"
          name="radios-label"
          value="yes"
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <div className="demo-item">
        <Radio
          checked={value === 'no'}
          id="radio-label-no"
          label="No"
          name="radios-label"
          value="no"
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </>
  );
}

function AlignEnd() {
  const [value, setValue] = useState('yes');
  return (
    <>
      <div className="demo-item">
        <Radio
          alignEnd
          checked={value === 'yes'}
          id="radio-align-end-yes"
          label="Yes"
          name="radios-align-end"
          value="yes"
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <div className="demo-item">
        <Radio
          alignEnd
          checked={value === 'no'}
          id="radio-align-end-no"
          label="No"
          name="radios-align-end"
          value="no"
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </>
  );
}

function Disabled() {
  const [value, setValue] = useState('yes');
  return (
    <>
      <div className="demo-item">
        <Radio
          checked={value === 'yes'}
          disabled
          id="radio-disabled-yes"
          label="Yes"
          name="radios-disabled"
          value="yes"
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <div className="demo-item">
        <Radio
          checked={value === 'no'}
          disabled
          id="radio-disabled-no"
          label="No"
          name="radios-disabled"
          value="no"
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </>
  );
}
