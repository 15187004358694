import React, { useState } from 'react';
import Avatar from '../assets/avatar.png';
import { Card } from '@arterial/card';
import { Checkbox } from '@arterial/checkbox';
import Code from '../components/code';
import { Demo } from '../components/demo';
import { Icon } from '@arterial/icon';
import { IconButton } from '@arterial/icon-button';
import {
  List,
  ListDivider,
  ListGroup,
  ListGroupSubheader,
  ListItem,
  ListItemGraphic,
  ListItemMeta,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemText,
} from '@arterial/list';
import {
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageHeadline,
  PageSection,
  PageSubtitle,
} from '../components/page';
import { Props, PropsTable, PropsHeadline } from '../components/props';
import { Radio } from '@arterial/radio';
import { Usage, UsageSubtitle, UsageTitle } from '../components/usage';
import {
  Variant,
  VariantContent,
  VariantHeader,
  VariantHeadline,
  VariantDescription,
} from '../components/variant';
import { props, types } from '../constants';

export default function Lists() {
  return (
    <>
      <Page>
        <PageHeader>
          <PageHeadline>Lists</PageHeadline>
          <PageDescription>
            Lists are continuous, vertical indexes of text or images.
          </PageDescription>
        </PageHeader>
        <PageContent>
          <PageSection>
            <Card outlined>
              <Usage>
                <UsageTitle>Installation</UsageTitle>
                <Code bash>npm install @arterial/list</Code>
                <UsageTitle>Styles</UsageTitle>
                <UsageSubtitle>Sass</UsageSubtitle>
                <Code
                  sass
                  files={[{ name: 'list', value: '@material/list/index.scss' }]}
                  mixins={[{ name: 'list', values: ['core-styles'] }]}
                />
                <UsageSubtitle>CSS</UsageSubtitle>
                <Code>import "@material/list/dist/mdc.list.css";</Code>
                <UsageTitle>JSX</UsageTitle>
                <Code>{`import { List, ListItem, ListItemText, ... } from '@arterial/list';`}</Code>
              </Usage>
            </Card>
          </PageSection>
          <PageSection>
            <Variant id="single-line-list">
              <VariantHeader>
                <VariantHeadline>Single-line List</VariantHeadline>
                <VariantDescription>
                  Single-line list items contain a maximum of one line of text.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--list demo-item--fullwidth">
                    <List>
                      <ListItem>
                        <ListItemText>Single-line item</ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText>Single-line item</ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText>Single-line item</ListItemText>
                      </ListItem>
                    </List>
                  </div>
                </Demo>
                <Code>
                  {`<List>
  <ListItem>
    <ListItemText>Single-line item</ListItemText>
  </ListItem>
  <ListItem>
    <ListItemText>Single-line item</ListItemText>
  </ListItem>
  <ListItem>
    <ListItemText>Single-line item</ListItemText>
  </ListItem>
</List>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="two-line-list">
              <VariantHeader>
                <VariantHeadline>Two-line List</VariantHeadline>
                <VariantDescription>
                  Two-line list items contain a maximum of two lines of text.
                </VariantDescription>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--list demo-item--fullwidth">
                    <List twoLine>
                      <ListItem>
                        <ListItemText>
                          <ListItemPrimaryText>
                            Two-line item
                          </ListItemPrimaryText>
                          <ListItemSecondaryText>
                            Secondary text
                          </ListItemSecondaryText>
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText>
                          <ListItemPrimaryText>
                            Two-line item
                          </ListItemPrimaryText>
                          <ListItemSecondaryText>
                            Secondary text
                          </ListItemSecondaryText>
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText>
                          <ListItemPrimaryText>
                            Two-line item
                          </ListItemPrimaryText>
                          <ListItemSecondaryText>
                            Secondary text
                          </ListItemSecondaryText>
                        </ListItemText>
                      </ListItem>
                    </List>
                  </div>
                </Demo>
                <Code>
                  {`<List twoLine>
  <ListItem>
    <ListItemText>
      <ListItemPrimaryText>Two-line item</ListItemPrimaryText>
      <ListItemSecondaryText>Secondary text</ListItemSecondaryText>
    </ListItemText>
  </ListItem>
  <ListItem>
    <ListItemText>
      <ListItemPrimaryText>Two-line item</ListItemPrimaryText>
      <ListItemSecondaryText>Secondary text</ListItemSecondaryText>
    </ListItemText>
  </ListItem>
  <ListItem>
    <ListItemText>
      <ListItemPrimaryText>Two-line item</ListItemPrimaryText>
      <ListItemSecondaryText>Secondary text</ListItemSecondaryText
    </ListItemText>
  </ListItem>
</List>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle id="other-variants">Other Variants</PageSubtitle>
            <Variant id="dense">
              <VariantHeader>
                <VariantHeadline>Dense</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--list demo-item--fullwidth">
                    <List dense>
                      <ListItem>
                        <ListItemText>Dense item</ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText>Dense item</ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText>Dense item</ListItemText>
                      </ListItem>
                    </List>
                  </div>
                </Demo>
                <Code>
                  {`<List dense>
  <ListItem>
    <ListItemText>Dense item</ListItemText>
  </ListItem>
  <ListItem>
    <ListItemText>Dense item</ListItemText>
  </ListItem>
  <ListItem>
    <ListItemText>Dense item</ListItemText>
  </ListItem>
</List>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="non-interactive">
              <VariantHeader>
                <VariantHeadline>Non-interactive</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--list demo-item--fullwidth">
                    <List nonInteractive>
                      <ListItem>
                        <ListItemText>Non-interactive item</ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText>Non-interactive item</ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText>Non-interactive item</ListItemText>
                      </ListItem>
                    </List>
                  </div>
                </Demo>
                <Code>
                  {`<List nonInteractive>
  <ListItem>
    <ListItemText>Non-interactive item</ListItemText>
  </ListItem>
  <ListItem>
    <ListItemText>Non-interactive item</ListItemText>
  </ListItem>
  <ListItem>
    <ListItemText>Non-interactive item</ListItemText>
  </ListItem>
</List>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="avatar">
              <VariantHeader>
                <VariantHeadline>Avatar</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--list demo-item--fullwidth">
                    <List avatarList>
                      <ListItem>
                        <ListItemGraphic
                          graphic={<img src={Avatar} alt="avatar" />}
                        />
                        <ListItemText>Avatar item</ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemGraphic
                          graphic={<img src={Avatar} alt="avatar" />}
                        />
                        <ListItemText>Avatar item</ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemGraphic
                          graphic={<img src={Avatar} alt="avatar" />}
                        />
                        <ListItemText>Avatar item</ListItemText>
                      </ListItem>
                    </List>
                  </div>
                </Demo>
                <Code>
                  {`import Avatar from 'avatar.png';

<List avatarList>
  <ListItem>
    <ListItemGraphic graphic={<img src={Avatar} alt="avatar" />} />
    <ListItemText>Avatar item</ListItemText>
  </ListItem>
  <ListItem>
    <ListItemGraphic graphic={<img src={Avatar} alt="avatar" />} />
    <ListItemText>Avatar item</ListItemText>
  </ListItem>
  <ListItem>
    <ListItemGraphic graphic={<img src={Avatar} alt="avatar" />} />
    <ListItemText>Avatar item</ListItemText>
  </ListItem>
</List>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="activated">
              <VariantHeader>
                <VariantHeadline>Activated</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--list demo-item--fullwidth">
                    <Activated />
                  </div>
                </Demo>
                <Code>
                  {`const ITEMS = ['inbox', 'star', 'send', 'drafts'];
function capitalize(value) {
  return value.charAt(0).toUpperCase() + value.substring(1);
}
function Activated() {
  const [activated, setActivated] = useState('star');
  return (
    <List>
      {ITEMS.map((item) => (
        <ListItem
          activated={activated === item}
          id={item}
          key={item}
          onClick={() => setActivated(item)}
        >
          {capitalize(item)}
        </ListItem>
      ))}
    </List>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="selected">
              <VariantHeader>
                <VariantHeadline>Selected</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--list demo-item--fullwidth">
                    <Selected />
                  </div>
                </Demo>
                <Code>
                  {`const ITEMS = ['inbox', 'star', 'send', 'drafts'];
function capitalize(value) {
  return value.charAt(0).toUpperCase() + value.substring(1);
}
function Selected() {
  const [selected, setSelected] = useState('star');
  return (
    <List>
      {ITEMS.map((item) => (
        <ListItem
          id={item}
          key={item}
          onClick={() => setSelected(item)}
          selected={selected === item}
        >
          {capitalize(item)}
        </ListItem>
      ))}
    </List>
  );
}`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="graphic">
              <VariantHeader>
                <VariantHeadline>Graphic</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--list demo-item--fullwidth">
                    <List tag="div">
                      <ListItem tag="div">
                        <ListItemGraphic graphic="wifi" />
                        <ListItemText>Graphic as icon</ListItemText>
                      </ListItem>
                      <ListItem tag="label">
                        <ListItemGraphic
                          graphic={
                            <Checkbox id="graphic-check" onChange={() => {}} />
                          }
                        />
                        <ListItemText>Graphic with checkbox</ListItemText>
                      </ListItem>
                      <ListItem tag="label">
                        <ListItemGraphic
                          graphic={
                            <Radio id="graphic-radio" onChange={() => {}} />
                          }
                        />
                        <ListItemText>Graphic with radio</ListItemText>
                      </ListItem>
                    </List>
                  </div>
                </Demo>
                <Code>
                  {`import { Checkbox } from '@arterial/checkbox';
import { Radio } from '@arterial/radio';

<List tag="div">
  <ListItem tag="div">
    <ListItemGraphic graphic="wifi" />
    <ListItemText>Graphic as icon</ListItemText>
  </ListItem>
  <ListItem tag="label">
    <ListItemGraphic graphic={<Checkbox id="graphic-check" onChange={() => {}} />} />
    <ListItemText>Graphic with checkbox</ListItemText>
  </ListItem>
  <ListItem tag="label">
    <ListItemGraphic graphic={<Radio id="graphic-radio" onChange={() => {}} />} />
    <ListItemText>Graphic with radio</ListItemText>
  </ListItem>
</List>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="metadata">
              <VariantHeader>
                <VariantHeadline>Metadata</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--list demo-item--fullwidth">
                    <List tag="div">
                      <ListItem tag="div">
                        <ListItemText>Meta as text</ListItemText>
                        <ListItemMeta meta="info" />
                      </ListItem>
                      <ListItem tag="div">
                        <ListItemText>Meta with icon component</ListItemText>
                        <ListItemMeta meta={<Icon icon="info" />} />
                      </ListItem>
                      <ListItem tag="div">
                        <ListItemText>
                          Meta with two icon components
                        </ListItemText>
                        <ListItemMeta
                          meta={
                            <>
                              <Icon icon="info" />
                              <Icon icon="info" />
                            </>
                          }
                        />
                      </ListItem>
                      <ListItem tag="div">
                        <ListItemText>Meta with icon button</ListItemText>
                        <ListItemMeta
                          meta={
                            <IconButton
                              icon="more_vert"
                              style={{ marginRight: '-12px' }}
                            />
                          }
                        />
                      </ListItem>
                      <ListItem tag="label">
                        <ListItemText>Meta with checkbox</ListItemText>
                        <ListItemMeta
                          meta={
                            <Checkbox
                              id="meta-checkbox"
                              onChange={() => {}}
                              style={{ marginRight: '-8px' }}
                            />
                          }
                        />
                      </ListItem>
                      <ListItem tag="label">
                        <ListItemText>Meta with radio</ListItemText>
                        <ListItemMeta
                          meta={<Radio id="meta-radio" onChange={() => {}} />}
                          style={{ marginRight: '-8px' }}
                        />
                      </ListItem>
                    </List>
                  </div>
                </Demo>
                <Code>
                  {`import { Checkbox } from '@arterial/checkbox';
import { Radio } from '@arterial/radio';

<List tag="div">
  <ListItem tag="div">
    <ListItemText>Meta as text</ListItemText>
    <ListItemMeta meta="info" />
  </ListItem>
  <ListItem tag="div">
    <ListItemText>Meta with icon component</ListItemText>
    <ListItemMeta meta={<Icon icon="info" />} />
  </ListItem>
  <ListItem tag="div">
    <ListItemText>Meta with two icon components</ListItemText>
    <ListItemMeta
      meta={
        <>
          <Icon icon="info" />
          <Icon icon="info" />
        </>
      }
    />
  </ListItem>
  <ListItem tag="div">
    <ListItemText>Meta with icon button</ListItemText>
    <ListItemMeta
      meta={<IconButton icon="more_vert" style={{ marginRight: '-12px' }} />}
    />
  </ListItem>
  <ListItem tag="label">
    <ListItemText>Meta with checkbox</ListItemText>
    <ListItemMeta
      meta={
        <Checkbox
          id="meta-checkbox"
          onChange={() => {}}
          style={{ marginRight: '-8px' }}
        />
      }
    />
  </ListItem>
  <ListItem tag="label">
    <ListItemText>Meta with radio</ListItemText>
    <ListItemMeta
      meta={<Radio id="meta-radio" onChange={() => {}} />}
      style={{ marginRight: '-8px' }}
    />
  </ListItem>
</List>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="graphic-metadata">
              <VariantHeader>
                <VariantHeadline>Graphic and Metadata</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--list demo-item--fullwidth">
                    <List twoLine>
                      <ListItem>
                        <ListItemGraphic graphic="folder" />
                        <ListItemText>
                          <ListItemPrimaryText>
                            Two-line item
                          </ListItemPrimaryText>
                          <ListItemSecondaryText>
                            Secondary text
                          </ListItemSecondaryText>
                        </ListItemText>
                        <ListItemMeta meta="info" />
                      </ListItem>
                      <ListItem>
                        <ListItemGraphic graphic="folder" />
                        <ListItemText>
                          <ListItemPrimaryText>
                            Two-line item
                          </ListItemPrimaryText>
                          <ListItemSecondaryText>
                            Secondary text
                          </ListItemSecondaryText>
                        </ListItemText>
                        <ListItemMeta meta="info" />
                      </ListItem>
                      <ListDivider />
                      <ListItem>
                        <ListItemGraphic graphic="folder" />
                        <ListItemText>
                          <ListItemPrimaryText>
                            Two-line item
                          </ListItemPrimaryText>
                          <ListItemSecondaryText>
                            Secondary text
                          </ListItemSecondaryText>
                        </ListItemText>
                        <ListItemMeta meta="info" />
                      </ListItem>
                    </List>
                  </div>
                </Demo>
                <Code>
                  {`<List twoLine>
  <ListItem>
    <ListItemGraphic graphic="folder" />
    <ListItemText>
      <ListItemPrimaryText>Two-line item</ListItemPrimaryText>
      <ListItemSecondaryText>Secondary text</ListItemSecondaryText>
    </ListItemText>
    <ListItemMeta meta="info" />
  </ListItem>
  <ListItem>
    <ListItemGraphic graphic="folder" />
    <ListItemText>
      <ListItemPrimaryText>Two-line item</ListItemPrimaryText>
      <ListItemSecondaryText>Secondary text</ListItemSecondaryText>
    </ListItemText>
    <ListItemMeta meta="info" />
  </ListItem>
  <ListDivider />
  <ListItem>
    <ListItemGraphic graphic="folder" />
    <ListItemText>
      <ListItemPrimaryText>Two-line item</ListItemPrimaryText>
      <ListItemSecondaryText>Secondary text</ListItemSecondaryText>
    </ListItemText>
    <ListItemMeta meta="info" />
  </ListItem>
</List>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <Variant id="grouped">
              <VariantHeader>
                <VariantHeadline>Grouped</VariantHeadline>
              </VariantHeader>
              <VariantContent>
                <Demo>
                  <div className="demo-item demo-item--list demo-item--fullwidth">
                    <ListGroup>
                      <ListGroupSubheader>List 1</ListGroupSubheader>
                      <List>
                        <ListItem>
                          <ListItemText>Line item</ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText>Line item</ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText>Line item</ListItemText>
                        </ListItem>
                      </List>
                      <ListGroupSubheader>List 2</ListGroupSubheader>
                      <List>
                        <ListItem>
                          <ListItemText>Line item</ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText>Line item</ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText>Line item</ListItemText>
                        </ListItem>
                      </List>
                    </ListGroup>
                  </div>
                </Demo>
                <Code>
                  {`<ListGroup>
  <ListGroupSubheader>List 1</ListGroupSubheader>
  <List>
    <ListItem>
      <ListItemText>Line item</ListItemText>
    </ListItem>
    <ListItem>
      <ListItemText>Line item</ListItemText>
    </ListItem>
    <ListItem>
      <ListItemText>Line item</ListItemText>
    </ListItem>
  </List>
  <ListGroupSubheader>List 2</ListGroupSubheader>
  <List>
    <ListItem>
      <ListItemText>Line item</ListItemText>
    </ListItem>
    <ListItem>
      <ListItemText>Line item</ListItemText>
    </ListItem>
    <ListItem>
      <ListItemText>Line item</ListItemText>
    </ListItem>
  </List>
</ListGroup>`}
                </Code>
              </VariantContent>
            </Variant>
          </PageSection>
          <PageSection>
            <PageSubtitle>Props</PageSubtitle>
            <Props>
              <PropsHeadline>List</PropsHeadline>
              <PropsTable
                body={[
                  [
                    'avatarList',
                    types.BOOLEAN,
                    'Enables an avatar list variant.',
                  ],
                  props.CHILDREN,
                  props.CLASS_NAME,
                  ['dense', types.BOOLEAN, 'Enables a dense variant.'],
                  [
                    'nonInteractive',
                    types.BOOLEAN,
                    'Enables a non-interactive variant.',
                  ],
                  ['twoLine', types.BOOLEAN, 'Enables a two-line variant.'],
                  [
                    props.TAG_DIV[0],
                    props.TAG_DIV[1],
                    'HTML tag to be applied to the root element. Defaults to ul.',
                  ],
                ]}
              />
            </Props>
            <Props>
              <PropsHeadline>ListItem</PropsHeadline>
              <PropsTable
                body={[
                  [
                    'activated',
                    types.BOOLEAN,
                    'Indicates whether the element is activated.',
                  ],
                  props.CHILDREN,
                  props.CLASS_NAME,
                  props.DISABLED,
                  props.SELECTED,
                  props.TAG_LI,
                ]}
              />
            </Props>
            <Props>
              <PropsHeadline>ListItemText</PropsHeadline>
              <PropsTable
                body={[props.CHILDREN, props.CLASS_NAME, props.TAG_SPAN]}
              />
            </Props>
            <Props>
              <PropsHeadline>ListItemPrimaryText</PropsHeadline>
              <PropsTable
                body={[props.CHILDREN, props.CLASS_NAME, props.TAG_SPAN]}
              />
            </Props>
            <Props>
              <PropsHeadline>ListItemSecondaryText</PropsHeadline>
              <PropsTable
                body={[props.CHILDREN, props.CLASS_NAME, props.TAG_SPAN]}
              />
            </Props>
            <Props>
              <PropsHeadline>ListItemGraphic</PropsHeadline>
              <PropsTable
                body={[
                  props.CLASS_NAME,
                  ['graphic', props.CHILDREN[1], props.CHILDREN[2]],
                  props.STYLE,
                  props.TAG_SPAN,
                ]}
              />
            </Props>
            <Props>
              <PropsHeadline>ListItemMeta</PropsHeadline>
              <PropsTable
                body={[
                  props.CLASS_NAME,
                  ['meta', props.CHILDREN[1], props.CHILDREN[2]],
                  props.STYLE,
                  props.TAG_SPAN,
                ]}
              />
            </Props>
            <Props>
              <PropsHeadline>ListGroup</PropsHeadline>
              <PropsTable
                body={[props.CLASS_NAME, props.CHILDREN, props.TAG_DIV]}
              />
            </Props>
            <Props>
              <PropsHeadline>ListGroupSubheader</PropsHeadline>
              <PropsTable
                body={[
                  props.CLASS_NAME,
                  props.CHILDREN,
                  [
                    props.TAG_DIV[0],
                    props.TAG_DIV[1],
                    'HTML tag to be applied to the root element. Defaults to h3.',
                  ],
                ]}
              />
            </Props>
            <Props>
              <PropsHeadline>ListDivider</PropsHeadline>
              <PropsTable
                body={[props.CLASS_NAME, props.CHILDREN, props.TAG_LI]}
              />
            </Props>
          </PageSection>
        </PageContent>
      </Page>
    </>
  );
}

const ITEMS = ['inbox', 'star', 'send', 'drafts'];
function capitalize(value) {
  return value.charAt(0).toUpperCase() + value.substring(1);
}
function Activated() {
  const [activated, setActivated] = useState('star');
  return (
    <List>
      {ITEMS.map((item) => (
        <ListItem
          activated={activated === item}
          id={item}
          key={item}
          onClick={() => setActivated(item)}
        >
          {capitalize(item)}
        </ListItem>
      ))}
    </List>
  );
}

function Selected() {
  const [selected, setSelected] = useState('star');
  return (
    <List>
      {ITEMS.map((item) => (
        <ListItem
          id={item}
          key={item}
          onClick={() => setSelected(item)}
          selected={selected === item}
        >
          {capitalize(item)}
        </ListItem>
      ))}
    </List>
  );
}
