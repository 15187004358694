import React from 'react';
import classNames from 'classnames';
import './hero.scss';

export function Hero({ children, className, ...otherProps }) {
  return (
    <div className={classNames('hero', className)} {...otherProps}>
      {children}
    </div>
  );
}

export function HeroContent({ children, className, ...otherProps }) {
  return (
    <div className={classNames('hero__content', className)} {...otherProps}>
      {children}
    </div>
  );
}
